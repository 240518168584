import { EWALLET_CONNECTION } from "../AppConfiguration";

export let URL_POST_LOGIN = EWALLET_CONNECTION + "/api/Customers/Login";
export let URL_GET_CUSTOMER_BY_QRCODE = EWALLET_CONNECTION + "/api/Customers/CustomerByQrCode";
export let URL_GET_PROFILE_IMAGE =
  EWALLET_CONNECTION + "/api/Customers/ProfileImage";

export let URL_GET_TOPUP_AMOUNTS =
  EWALLET_CONNECTION + "/api/TopupRequests/Amounts";
export let URL_GET_PAYMENT_METHODS =
  EWALLET_CONNECTION + "/api/PaymentMethods?option=";
export let URL_POST_NEW_TOPUP_REQUEST =
  EWALLET_CONNECTION + "/api/topuprequests";
export let URL_GET_CUSTOMER_BY_MOBILE_NUMBER =
  EWALLET_CONNECTION + "/api/customers";
export let URL_POST_APPROVE_TOPUP_REQUEST =
  EWALLET_CONNECTION + "/api/TopupRequests/Approve";
export let URL_POST_SEARCH_TOPUP_REQUEST =
  EWALLET_CONNECTION + "/api/TopupRequests/Search";
export let URL_POST_CANCEL_TOPUP_REQUEST =
  EWALLET_CONNECTION + "/api/TopupRequests/Cancel";
export let URL_GET_TOPUP_STATISTICS =
  EWALLET_CONNECTION + "/api/TopupRequests/Statistics";
export let URL_POST_TOPUP_REQUESTS_RETRIEVE =
  EWALLET_CONNECTION + "/api/TopupRequests/Retrieve";
  export let URL_POST_TOPUP_REQUESTS_RETRIEVEV2 =
  EWALLET_CONNECTION + "/api/TopupRequests/RetrieveV2";
export let URL_POST_TOPUP_REQUESTS_RETRIEVE_COUNT =
  EWALLET_CONNECTION + "/api/TopupRequests/Retrieve/Count";

export let URL_POST_NEW_JOURNAL_ENTRY =
  EWALLET_CONNECTION + "/api/JournalEntry";
export let URL_GET_ALL_JOURNAL_ENTRY = EWALLET_CONNECTION + "/api/JournalEntry";

export let URL_GET_ALL_JOURNAL_ENTRY_COUNT =
  EWALLET_CONNECTION + "/api/JournalEntry/Count";

export let URL_GET_ALL_PAYMENT_METHODS =
  EWALLET_CONNECTION + "/api/PaymentMethods/All";

export let URL_PUT_UPDATE_PAYMENT_METHOD =
  EWALLET_CONNECTION + "/api/PaymentMethods";

export let URL_GET_IS_DISABLE_WEBSITE =
  EWALLET_CONNECTION + "/api/AppConfiguration/isDisableWebsite";

  export let URL_GET_PAYMENT_RESPONSE =
  EWALLET_CONNECTION + "/api/TopupRequests/GetPaymentResponseByReferenceNumberAndAmount";

  export let URL_POST_REPOST_TOPUP =
  EWALLET_CONNECTION + "/api/TopupRequests/RepostTopup?TopupRequestId=";

  export let URL_GET_JOURNAL_ENTRY_BY_DATEFROM_DATETO = EWALLET_CONNECTION + "/api/JournalEntry/GetByDateFromDateTo";
  export let URL_GET_TOPUP_REQUESTS_BY_DATEFROM_DATETO = EWALLET_CONNECTION + "/api/TopupRequests/GetByDateFromDateTo";
  export let URL_GET_CUSTOMER_USERS =
  EWALLET_CONNECTION + "/api/Customers/Users";
  export let URL_PUT_CUSTOMER_USERS =
  EWALLET_CONNECTION + "/api/Customers/TagUser";
  export let URL_GET_TOPUP_REQUESTS_SUMMARY_REPORT_BY_DATEFROM_DATETO = EWALLET_CONNECTION + "/api/TopupRequests/TopupSummaryReport";

  export let URL_GET_ALL_DEPARTMENTS = EWALLET_CONNECTION + "/api/Departments/All";
  export let URL_GET_ALL_DEPARTMENTS_COUNT = EWALLET_CONNECTION + "/api/Departments/Count";
  export let URL_POST_DEPARTMENT = EWALLET_CONNECTION + "/api/Departments";
  export let URL_POST_UPDATE_DEPARTMENT = EWALLET_CONNECTION + "/api/Departments/Update";
  export let URL_GET_ACTIVE_DEPARTMENTS = EWALLET_CONNECTION + "/api/Departments/Active";

  //export let URL_GET_ALL_EMPLOYEES = EWALLET_CONNECTION + "/api/Employees/All";
  export let URL_GET_EMPLOYEES_BY_DEPARTMENT_ID = EWALLET_CONNECTION + "/api/Employees/GetEmployeesByDepartmentId";
  export let URL_GET_EMPLOYEES_BY_STATUS_AND_DEPARTMENT_COUNT = EWALLET_CONNECTION + "/api/Employees/Count";
  export let URL_GET_EMPLOYEES_BY_SEARCH = EWALLET_CONNECTION + "/api/Employees/GetEmployeesBySearch";
  export let URL_POST_EMPLOYEE = EWALLET_CONNECTION + "/api/Employees";
  export let URL_POST_UPDATE_EMPLOYEE = EWALLET_CONNECTION + "/api/Employees/Update";
  export let URL_GET_VERIFY_EWALLET_ACCOUNT = EWALLET_CONNECTION + "/api/Employees/Verify";
  export let URL_GET_CREATE_NEW_TRANSMITTAL = EWALLET_CONNECTION + "/api/Transmittals/CreateNewTransmittal";
  export let URL_POST_TRANSMITTAL = EWALLET_CONNECTION + "/api/Transmittals";
  export let URL_GET_TRANSMITTAL_BY_STATUS = EWALLET_CONNECTION + "/api/Transmittals/GetTransmittalByStatus";
  export let URL_GET_TRANSMITTAL_BY_STATUS_COUNT = EWALLET_CONNECTION + "/api/Transmittals/Count";
  export let URL_POST_APPROVE_TRANSMITTALS = EWALLET_CONNECTION + "/api/Transmittals/ApproveTransmittals";
  export let URL_POST_REJECT_TRANSMITTALS = EWALLET_CONNECTION + "/api/Transmittals/RejectTransmittals";
  export let URL_GET_TRANSMITTAL_DETAILS_BY_HEADER_ID = EWALLET_CONNECTION + "/api/Transmittals/GetTransmittalDetailsByHeaderId";
