import React, { useEffect, useState } from "react";
import UserIcon from "../../../assets/user.svg";
import { getBackOfficeUser } from "../../../utils/Common";
import Icons from "../../../assets/Assets";
import { removeBackOfficeUser } from "../../../utils/Common";
import { useNavigate } from "react-router-dom";
import { DOWNLOAD_DATA } from "../../../api/APIManager";
import { URL_GET_PROFILE_IMAGE } from "../../../api/Endpoints";
import Portal from "../../../components/Portal/Portal";

const BackOfficeContextMenu = React.forwardRef((props, ref) => {
  
  const currentBOUser = getBackOfficeUser();

  const navigate = useNavigate();

  const handleLogout = () => {
    removeBackOfficeUser();
    navigate("/BackOffice/Login");
  };


  
  return (
    <Portal>
      <div
        ref={ref}
        style={props.styles.popper}
        {...props.attributes.popper}
        className="absolute border rounded-lg shadow-md bg-white z-10" //
        // style={{ top: "3.5rem", marginRight: "5px" }}
      >
        <div className="bg-primary pt-2 rounded-t-lg" style={{height:'5px'}}></div>
        {/*     <hr
              style={{marginTop: 2,
                  backgroundColor: '#f43030',
                  height: 3}}/> */}

        <div className="flex p-2 items-center">
          <img
            className="rounded-full"
            src={props.userIcon}
            style={{ height: "40px", width: "40px" }}
          />
          <div className="ml-1">
                <div className="font-bold">{currentBOUser.FullName} </div>
                <div className="">{currentBOUser.MobileNumber}</div>
          </div>
         
        </div>
        <ul className="mt-2 hover:bg-red-200">
          <li className="p-2 cursor-pointer flex justify-center" onClick={handleLogout}>
            <Icons.LogoutIcon /> <span className="ml-2 btn-secondary">Logout</span>
          </li>
        </ul>
      </div>
    </Portal>
  );
});

export default BackOfficeContextMenu;
