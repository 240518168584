import React, { useEffect, useState } from "react";
import SideBar from "./SideBar";
import { Outlet } from "react-router-dom";
import UserIcon from "../../../assets/user.svg";
import GearIcon from "../../../assets/gear2.svg";
import { getBackOfficeUser } from "../../../utils/Common";
import { useNavigate } from "react-router-dom";
import BackOfficeContextMenu from "./BackOfficeContextMenu";
import { DOWNLOAD_DATA } from "../../../api/APIManager";
import { URL_GET_PROFILE_IMAGE } from "../../../api/Endpoints";
import { usePopper } from "react-popper";
import AlertNotificationContainer from "../../../components/Common/AlertNotification/AlertNotificationContainer";

const BackOfficeContainer = () => {
  const navigate = useNavigate();
  const currentBOUser = getBackOfficeUser();
  const [userMenu, showUserMenu] = useState(false);

  const [userIcon, setUserIcon] = useState(UserIcon);

  useEffect(() => {
    if (!currentBOUser) {
      navigate("/BackOffice/Login");
    } else {
      getProfileImage();
    }
  }, []);

  const getProfileImage = async () => {
    try {
      const result = await DOWNLOAD_DATA(
        `${URL_GET_PROFILE_IMAGE}/${currentBOUser.Id}`
      );

      if (result) setUserIcon(result);
    } catch (ex) {
      console.log(ex);
    }
  };

  const [referenceElement, setReferenceElement] = useState();
  const [popperElement, setPopperElement] = useState();

  const { styles, attributes } = usePopper(referenceElement, popperElement, {
    placement: "bottom-start",
  });
  
  const handleClosePopper = () => {
    showUserMenu(!userMenu);
  };

  const handleUserManager = () => {
      navigate("/BackOffice/User-Manager");
  };

  return (
    <div className="flex">
      <SideBar />

      <div
        className="pl-3 bg-white w-full"
        style={{ marginLeft: "50px", minHeight: "100vh" }}
      >
        <div
          className="absolute"
          style={{
            left: "50%",
            transform: "translateX(-50%)",
            marginTop: "50px",
          }}
        >
          <AlertNotificationContainer />
        </div>
        <header className="py-5 flex justify-end">
          {/* <img src={GearIcon} className="mx-5 cursor-pointer" onClick={handleUserManager} /> */}
          <img
            src={userIcon}
            style={{ height: "60px", width: "60px" }}
            className="mx-5 cursor-pointer rounded-full"
            onClick={() => showUserMenu(!userMenu)}
            ref={setReferenceElement}
          />
            {userMenu && (<div className="w-full top-0 left-0 h-screen bg-transfarent fixed" onClick={handleClosePopper}>
              </div>
               )}
          {userMenu && (
            <BackOfficeContextMenu
              userIcon={userIcon}
              styles={styles}
              attributes={attributes}
              ref={setPopperElement}
            />
          )}
        </header>
        <main className="mr-5">
          <Outlet />
        </main>
        <footer></footer>
      </div>
    </div>
  );
};

export default BackOfficeContainer;
