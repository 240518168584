import React, { useState, useEffect } from "react";
import PaymentMethodStatusIndicator from "./PaymentMethodStatusIndicator";
import PopupModal from "../../../components/Common/PopupModal/PopupModal";
import EditPaymentMethod from "./EditPaymentMethod";
import { GET_REQUEST } from "../../../api/APIManager";
import { URL_GET_ALL_PAYMENT_METHODS } from "../../../api/Endpoints";
import { formatDate_journal, formatNumber } from "../../../utils/Common";
import { useNotificationUpdate } from "../../../utils/NotificationContext";
import DisplayTopupOptions from "./TopupOptions/DisplayTopupOptions";
import { FiEdit } from "react-icons/fi";
import "./PaymentMethodsManager.css";

const PaymentMethodManager = () => {
  const updateNotif = useNotificationUpdate();

  const [paymentMethods, setPaymentMethods] = useState(null);
  const [_paymentMethod, editPaymentMethod] = useState(null);

  const getPaymentMethods = async () => {
    try {
      const response = await GET_REQUEST(URL_GET_ALL_PAYMENT_METHODS);
      setPaymentMethods(response);
    } catch (err) {
      console.log(err);
    }
  };

  const handleSuccessfulEdit = () => {
    getPaymentMethods();
    editPaymentMethod(null);
    updateNotif("Payment Method Successfully Updated");
  };

  const handleCancelEdit = () => {
    editPaymentMethod(null);
  };

  useEffect(() => {
    getPaymentMethods();
  }, []);

  const handleEditPaymentMethod = (paymentMethod) => {
    editPaymentMethod(paymentMethod);
  };

  return (
    <div>
      {_paymentMethod && (
        <PopupModal
          content={
            <EditPaymentMethod
              paymentMethod={_paymentMethod}
              onCancel={handleCancelEdit}
              onSuccess={handleSuccessfulEdit}
            />
          }
        />
      )}
      <h1 className="font-bold text-lg">Payment Methods</h1>
      <table
        id="tblPaymentMethods"
        className="w-full rounded-md overflow-hidden border-collapse bg-grey-custom mt-4"
      >
        <thead>
          <tr className="border">
            <th className="text-left">Code</th>
            <th className="text-left">Name</th>
            <th className="text-left">Status</th>
            <th className="text-right">Minimum Amount</th>
            <th className="text-right">Validity (Days)</th>
            <th className="text-right">Priority (Higher)</th>
            <th className="text-center">Available on</th>
            <th className="text-center">Remarks</th>
            <th className="text-left">Date Created</th>
            <th className="text-left">Date Updated</th>
            <th></th>
          </tr>
        </thead>
        <tbody>
          {paymentMethods?.map((paymentMethod) => {
            return (
              <tr key={paymentMethod.code}>
                <td>{paymentMethod.code}</td>
                <td>{paymentMethod.name}</td>
                <td>
                  <PaymentMethodStatusIndicator status={paymentMethod.status} />
                </td>
                <td className="text-right">
                  ₱{formatNumber(paymentMethod.minimumAmount)}
                </td>
                <td className="text-right">{paymentMethod.daysValidity}</td>
                <td className="text-right">{paymentMethod.priority}</td>
                <td className="text-center" width="20%">
                  <DisplayTopupOptions
                    rawTopupOptions={paymentMethod.topupOptions}
                  />
                </td>
                <td className="text-center" width="25%">
                  {paymentMethod.remarks}
                </td>
                <td>{formatDate_journal(paymentMethod.dateCreated)}</td>
                <td>{formatDate_journal(paymentMethod.dateUpdated)}</td>
                <td className="">
                  <button
                    className="m-auto border px-5 py-2 rounded-md flex items-center font-bold"
                    onClick={() => {
                      handleEditPaymentMethod(paymentMethod);
                    }}
                  >
                    <FiEdit className="mr-2" />
                    Edit
                  </button>
                </td>
              </tr>
            );
          })}
        </tbody>
      </table>
    </div>
  );
};

export default PaymentMethodManager;
