import React, { useEffect, useState } from "react";
import AppTitle from "../../../components/Header/AppTitle";
import Icons from "../../../assets/Assets";
import { NavLink } from "react-router-dom";
import "./SideBar.css";
import MenuIcon from "../../../assets/menu.svg";
import { AiOutlineClose } from "react-icons/ai";
import { MdOutlineCategory, MdOutlineSettings, MdPayments } from "react-icons/md";
import { BsBuilding } from "react-icons/bs"
import { FaUsers } from "react-icons/fa";
import { TbTransferIn, TbWallet } from "react-icons/tb";
import { getBackOfficeUser } from "../../../utils/Common";

const SideBar = () => {
  const [collapseSidebar, setCollapseSidebar] = useState(true);
  const [userType, setUserType] = useState(0);


  const currentBOUser = getBackOfficeUser();

  useEffect(() => {
    if (currentBOUser)  handleAccessRight();
  }, []);

const handleAccessRight = () => {
  const _tags = JSON.parse(currentBOUser.Tags);
  for (let i = 0; i < _tags.length; i++) {
    if ( _tags[i] === "TOPUP_ADMIN" ) {
      setUserType(0);
      break;
    }
    if ( _tags[i] === "TRANSMITTAL_ADMIN" ) {
      setUserType(1);
      break;
    }
    if (  _tags[i] === "TRANSMITTAL_ENCODER") {
      setUserType(2);
    break; 
    }
  }
};
  const DashboardMenu = [
    {
      icon: <Icons.DashboardIcon height="30" width="30" />,
      title: "Dashboard",
      path: "/BackOffice/Dashboard",
    },
    {
      icon: <Icons.TopupRequestIcon height="30" width="30" />,
      title: "Topup Requests",
      path: "/BackOffice/Topup-Requests",
      hidden: (userType !== 0),
    },
    {
      icon: <Icons.JournalEntryIcon height="30" width="30" />,
      title: "Journal Entry Loading",
      path: "/BackOffice/Journal-Entry-Loading",
      hidden: (userType !== 0),
    },
    {
      icon: <MdPayments size={30} />,
      title: "Payment Methods",
      path: "/BackOffice/Payment-Methods",
      hidden: (userType !== 0),
    },
    {
      icon: <MdOutlineSettings size={30} />,
      title: "User Manager",
      path: "/BackOffice/User-Manager",
      hidden: (userType !== 0),
    },
    {
      icon: <BsBuilding size={30} />,
      title: "Department",
      path: "/BackOffice/Department",
    },
    {
      icon: <FaUsers size={30} />,
      title: "Employee",
      path: "/BackOffice/Employee",
    },
    {
      icon: <TbTransferIn size={30} />,
      title: "Transmittal Entry",
      path: "/BackOffice/Transmittal-Entry",
    },
    {
      icon: <TbWallet size={30} />,
      title: "Transmittal List",
      path: "/BackOffice/Transmittal-List",
    },
    
  ];

  let additionalCSS = "";

  if (collapseSidebar) {
    additionalCSS = " collapse";
  }

  return (
    <div className={"drop-shadow sidebar fixed" + additionalCSS}>
      {/* ------------------------- Start of Sidebar is uncollapsed ------------------------- */}
      {!collapseSidebar && (
        <div className="flex items-center py-5 px-3">
          <span className="pr-5">
            <AppTitle />
          </span>
          <span
            className="text-xl"
            onClick={() => setCollapseSidebar(!collapseSidebar)}
          >
            <AiOutlineClose className="cursor-pointer" />
          </span>
        </div>
      )}
      {/* 
      <div
        className={`flex items-center py-5 px-3 ${
          collapseSidebar && "hide-delay"
        } `}
      >
        <span className="pr-5">
          <AppTitle />
        </span>
        <span
          className="text-xl"
          onClick={() => setCollapseSidebar(!collapseSidebar)}
        >
          <AiOutlineClose className="cursor-pointer" />
        </span>
      </div> */}

      {/* ------------------------- End of Sidebar is uncollapsed ------------------------- */}

      {/* ------------------------- Start of Sidebar is collapsed ------------------------- */}
      {collapseSidebar && (
        <div className="flex justify-center items-center">
          <span
            className="text-xl p-4"
            onClick={() => setCollapseSidebar(!collapseSidebar)}
          >
            <img src={MenuIcon} className="cursor-pointer" />
          </span>
        </div>
      )}
      {/* ------------------------- End of Sidebar is collapsed ------------------------- */}

      <div className="grow">
        {DashboardMenu.map((menu) => {
          return (
            <NavLink
              key={menu.title}
              to={menu.path}
              hidden={menu.hidden}
              className={(navData) =>
                "cursor-pointer menuItem" +
                (navData.isActive ? " selected" : "")
              }
            >
              <div className="flex items-center mx-2 my-4 ml-0">
                {/* <div
                  className="bg-red-500"
                  style={{ height: "40px", width: "5px" }}
                ></div> */}
                <span className="pr-3 ml-2">{menu.icon}</span>
                <label className="cursor-pointer">{menu.title}</label>
                {/* {!collapseSidebar && (
                  
                )} */}
              </div>
            </NavLink>
          );
        })}
      </div>

      {/* <div id="LogoutContainer">
        <button
          className="m-2 flex items-center w-full sideBarButton"
          onClick={handleLogout}
        >
          <Icons.LogoutIcon height="30" width="30" />
          {!collapseSidebar && <span className="ml-4">Logout</span>}
        </button>
      </div> */}
    </div>
  );
};

export default SideBar;
