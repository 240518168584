import React, { useEffect, useState, useRef } from "react";
import {
  formatDateTimeOnly_journal,
  formatDateWithTime_journal,
  formatDate_journal,
  formatNumber,
} from "../../../utils/Common";
import "./TopupRequestManager.css";
import { IoAlertCircleOutline } from "react-icons/io5";
import { POST_REQUEST } from "../../../api/APIManager";
import {
  URL_POST_TOPUP_REQUESTS_RETRIEVE,
  URL_POST_TOPUP_REQUESTS_RETRIEVEV2,
  URL_POST_TOPUP_REQUESTS_RETRIEVE_COUNT,
} from "../../../api/Endpoints";
import { BsChevronLeft, BsChevronRight } from "react-icons/bs";
import TopupRequestDetailView from "./TopupRequestDetailView";
import StatusIndicator from "./TopupRequestStatusIndicator";
import PrintTopupRequest from "./PrintTopupRequest";
import {
  AiFillPrinter,
  AiOutlineReload,
  AiOutlineSearch,
} from "react-icons/ai";
import ReportViewer from "../../../components/ReportViewer/ReportViewer";
import AlertModalContainer from "../../../classes/CommonAgg/AlertModalContainer";
import ConfirmationModal from "../../../components/ConfirmationModal/OKConfirmationModal";
import { useNotificationUpdate } from "../../../utils/NotificationContext";
import PopupModal from "../../../components/Common/PopupModal/PopupModal";
import SelectDates from "../../../components/SelectDates/SelectDates";
import SelectDates2 from "../../../components/SelectDates/SelectDates2";
import PrintTopupSummary from "./PrintTopupSummary";
import { useParams } from "react-router-dom";

const TopupRequestManager = () => {
  const { date, paymentMethodCode, status } = useParams();
  //console.log(date + paymentMethodCode + status);

  useEffect(async () => {
    if (
      date !== undefined &&
      paymentMethodCode !== undefined &&
      status !== undefined
    ) {
      console.log(date + paymentMethodCode + status);

      try {
        const payload = {
          status: parseInt(status),
          paymentMethodCode: paymentMethodCode,
          date: date,
        };
        const _requests = await POST_REQUEST(
          URL_POST_TOPUP_REQUESTS_RETRIEVEV2,
          payload
        );
        setTopupRequests(_requests);
        //setPayload(payload);
      } catch (e) {
        console.log(e);
      }
    }
    else{
      setIsReload(true);
    }
  }, []);

  const [topupRequests, setTopupRequests] = useState(null);
  const [totalTopupRequestsCount, setTotalTopupRequestsCount] = useState(0);
  const [pageSize, setPageSize] = useState(10);
  const [pageNumber, setPageNumber] = useState(1);
  const [showDetails, setShowDetails] = useState(null);
  const [selectedStatuses, setSelectedStatuses] = useState([]);
  const [selectedPaymentMethods, setSelectedPaymentMethods] = useState([]);
  const [printReport, showPrintReport] = useState(false);
  const [printSummaryReport, showSummaryPrintReport] = useState(false);
  const [currentPayload, setPayload] = useState(null);

  const [search, setSearch] = useState("");
  const [searchBy, setSearchBy] = useState(0);
  const isSeach = useRef(false);
  const updateNotification = useNotificationUpdate();
  const [sortMode, setSortMode] = useState("None");
  const [isReload, setIsReload] = useState(false);
  const [direction, setDirection] = React.useState("None");
  const arrows = { None: "↕︎", Asc: "↓", Desc: "↑" };
  const arrow = arrows[direction];

  const handleSortClick = (event) => {
    const sortDir = direction === "Desc" ? "Asc" : "Desc";
    setDirection(sortDir);
    setSortMode(sortDir);
  };

  const handleCloseDetail = () => {
    setTimeout(() => {
      setShowDetails(null);
    }, 400);
  };

  useEffect(() => {
    // if (isReload) 
    refresh();
  }, [
    pageNumber,
    pageSize,
    selectedStatuses,
    selectedPaymentMethods,
    isReload,
    sortMode,
  ]);

  const refresh = () => {
    isSeach.current = false;
    setIsReload(false);
    getTopupRequestCount();
    getTopupRequests();
  };

  const handleSearch = () => {
    isSeach.current = true;
    getTopupRequestCount();
    getTopupRequests();
  };

  const reload = () => {
    setSearchBy(0);
    setSearch("");
    setPageNumber(1);
    setIsReload(true);
  };

  const getTopupRequestCount = async () => {
    try {
      const payload = {
        statuses: selectedStatuses,
        paymentMethodCodes: selectedPaymentMethods,
        pageSize: pageSize,
        pageNumber: pageNumber,
        searchBy: searchBy,
        searchName: search,
      };
      const count = await POST_REQUEST(
        URL_POST_TOPUP_REQUESTS_RETRIEVE_COUNT,
        payload
      );
      setTotalTopupRequestsCount(count);

      if (isSeach.current && count === 0) setConfirmationModal(true);
    } catch (e) {
      console.log(e);
    }
  };

  const getTopupRequests = async () => {
    try {
      const payload = {
        statuses: selectedStatuses,
        paymentMethodCodes: selectedPaymentMethods,
        pageSize: pageSize,
        pageNumber: pageNumber,
        searchBy: searchBy,
        searchName: search,
        sortBy: "DateCreated",
        sortMode: sortMode,
      };
      const _requests = await POST_REQUEST(
        URL_POST_TOPUP_REQUESTS_RETRIEVE,
        payload
      );
      setTopupRequests(_requests);
      setPayload(payload);
    } catch (e) {
      console.log(e);
    }
  };

  const nextPage = () => {
    if (pageNumber * pageSize < totalTopupRequestsCount)
      setPageNumber(pageNumber + 1);
  };
  const prevPage = () => {
    if (pageNumber > 1) {
      setPageNumber(pageNumber - 1);
    }
  };

  const handleStatusChange = (status) => {
    let _statuses = [];
    const _status = parseInt(status);

    if (status >= 0) {
      _statuses.push(_status);
    }

    setSelectedStatuses(_statuses);
  };

  const handlePaymentMethodChange = (PMCode) => {
    let PMCodes = [];

    if (PMCode != "0") {
      PMCodes.push(PMCode);
    }

    setSelectedPaymentMethods(PMCodes);
  };

  const pContent = useRef();

  const [showReportOption, setReportOption] = useState(false);
  const [showSummaryReportOption, setSummaryReportOption] = useState(false);
  const [startDate, setStartDate] = useState(new Date());
  const [endDate, setEndDate] = useState(new Date());
  const [isCheck, setIscheck] = useState(false);

  const handleGenerateReport = (isCheck, startDate, endDate) => {
    setIscheck(isCheck);
    setStartDate(startDate);
    setEndDate(endDate);

    showPrintReport(true);
  };

  const handleGenerateSummaryReport = (startDate, endDate) => {
    setStartDate(startDate);
    setEndDate(endDate);

    showSummaryPrintReport(true);
  };

  // const handleGenerateReport = () => {
  //   let pWindow = window.open();
  //   pWindow.document.write("<html><body>");
  //   pWindow.document.write(pContent.current.innerHTML);
  //   pWindow.document.write("</body></html>");

  //   pWindow.document.close();
  //   setTimeout(() => {
  //     pWindow.print();
  //     pWindow.close();
  //   }, 1000);
  // };

  const [showConfirmationModal, setConfirmationModal] = useState(false);
  let confirmationModalContent = [];

  confirmationModalContent.push("No exact matches found!");

  return (
    <div>
      {/* <h2>Top-up Requests</h2> */}
      {printReport && (
        <PrintTopupRequest
          showPrintReport={showPrintReport}
          topupRequestPayload={currentPayload}
          isCheck={isCheck}
          startDate={startDate}
          endDate={endDate}
        />
      )}
      {printSummaryReport && (
        <PrintTopupSummary
          showPrintReport={showSummaryPrintReport}
          startDate={startDate}
          endDate={endDate}
        />
      )}

      <div className="flex justify-between">
        <h1 className="font-bold text-lg">Top-up Request List</h1>
        <div className="flex">
          <button
            className="btn border font-bold flex items-center mr-4"
            onClick={() => setSummaryReportOption(true)}
          >
            <AiFillPrinter className="mr-2" size="15" />
            Generate Summary Report
          </button>

          <button
            className="btn border font-bold flex items-center mr-4"
            onClick={() => setReportOption(true)}
          >
            <AiFillPrinter className="mr-2" size="15" />
            Generate Report
          </button>
        </div>
      </div>

      <div className="border-t mt-3 pt-4">
        <div className="p-3 m-0 border rounded-md border-b-0 flex items-center">
          <label>Status</label>
          <select
            className="border rounded-md px-2 py-1 ml-2"
            style={{ minWidth: "100px" }}
            onChange={(e) => {
              handleStatusChange(e.target.value);
            }}
          >
            <option value={-1}>All</option>
            <option value={0}>Pending</option>
            <option value={2}>Processing</option>
            <option value={5}>On Hold</option>
            <option value={1}>Done</option>
          </select>
          <label className="ml-5">Payment Method</label>
          <select
            className="border rounded-md px-2 py-1 ml-2"
            style={{ width: "120px" }}
            onChange={(e) => {
              handlePaymentMethodChange(e.target.value);
            }}
          >
            <option value={0}>All</option>
            <option value={103}>Pay on Rider</option>
            <option value={102}>Pay on Site</option>
            <option value={101}>Credit/Debit Card</option>
            <option value={104}>Bank Transfer</option>
          </select>
          {/* <button
            className="border p-2 px-5 ml-3 rounded-md flex items-center"
            onClick={() => setReportOption(true)}
          >
            <AiFillPrinter size="16" className="mr-2" />
            Generate Report
          </button> */}

          <div>
            <input
              className="ml-5"
              type="radio"
              value="1"
              name="searchOption"
              onChange={(e) => setSearchBy(e.target.value)}
              checked={searchBy === "1"}
            />{" "}
            Transaction Number
            <input
              className="ml-2"
              type="radio"
              value="2"
              name="searchOption"
              onChange={(e) => setSearchBy(e.target.value)}
              checked={searchBy === "2"}
            />{" "}
            Ewallet Mobile Number
            <input
              className="ml-2"
              type="radio"
              value="3"
              name="searchOption"
              onChange={(e) => setSearchBy(e.target.value)}
              checked={searchBy === "3"}
            />{" "}
            Load Amount
          </div>

          <input
            className="input input-text ml-2"
            onChange={(e) => setSearch(e.target.value)}
            disabled={searchBy === 0}
            value={search}
          />

          <button
            className="border p-2 px-5 ml-3 rounded-md flex items-center"
            onClick={handleSearch}
          >
            <AiOutlineSearch size="16" className="mr-2" />
            Search
          </button>

          <button
            className="border p-2 px-5 ml-3 rounded-md flex items-center"
            onClick={reload}
          >
            <AiOutlineReload size="16" className="mr-2" />
            Refresh
          </button>
        </div>
        <table
          id="tblTopupRequests"
          className="w-full rounded-md overflow-hidden border-collapse bg-grey-custom"
        >
          <thead>
            <tr className="border">
              <th className="text-left">Transaction No.</th>
              <th className="text-left">Payment Method</th>
              <th className="text-left">
                <button className="font-bold" onClick={handleSortClick}>
                  Request Date {arrow}
                </button>
              </th>
              <th className="text-left">Date Posted</th>
              <th className="text-right">Load Amount</th>
              <th className="text-center">Status</th>
              <th></th>
            </tr>
          </thead>
          <tbody>
            {topupRequests?.map((topup) => {
              return (
                <tr key={topup.id}>
                  <td>{topup.referenceNumber}</td>
                  <td>{topup.paymentMethod.name}</td>
                  <td>
                    {formatDate_journal(topup.dateCreated)}
                    <span style={{ color: "red" }}>
                      {formatDateTimeOnly_journal(topup.dateCreated)}
                    </span>
                  </td>
                  <td>
                    {formatDate_journal(topup.datePosted)}
                    <span style={{ color: "red" }}>
                      {formatDateTimeOnly_journal(topup.datePosted)}
                    </span>
                  </td>
                  <td className="text-right">
                    ₱{formatNumber(topup.topupRequestAmount)}
                  </td>
                  <td className="text-center">
                    <StatusIndicator
                      status={topup.status}
                      statusText={topup.statusText}
                    />
                  </td>
                  <td>
                    <button
                      className="flex items-center border p-2 m-2 rounded-md"
                      onClick={() => setShowDetails(topup)}
                    >
                      <IoAlertCircleOutline className="mr-2" size="16" /> View
                      Details
                    </button>
                  </td>
                </tr>
              );
            })}
          </tbody>
          <tfoot>
            <tr>
              <td colSpan={4}>
                Rows per page
                <select
                  className="px-2 ml-2"
                  onChange={(e) => {
                    setPageSize(e.target.value);
                  }}
                >
                  <option>10</option>
                  <option>15</option>
                  <option>20</option>
                </select>
              </td>
              {/* <td colSpan={1}>
              Sort Date By
                <select
                  className="px-2 ml-2"
                  onChange={(e) => {
                    setSortMode(e.target.value);
                  }}
                >
                  <option>None</option>
                  <option>Desc</option>
                  <option>Asc</option>
                </select>
              </td> */}
              <td colSpan="3">
                <div className="flex items-center justify-end">
                  <span className="mr-5">
                    {pageSize * (pageNumber - 1) + 1} -{" "}
                    {pageSize * pageNumber < totalTopupRequestsCount
                      ? pageSize * pageNumber
                      : totalTopupRequestsCount}{" "}
                    of {totalTopupRequestsCount} items
                  </span>
                  <BsChevronLeft
                    size="15"
                    className="mr-2 cursor-pointer"
                    onClick={() => prevPage()}
                  />
                  <BsChevronRight
                    size="15"
                    className="cursor-pointer"
                    onClick={() => nextPage()}
                  />
                </div>
              </td>
            </tr>
          </tfoot>
        </table>
      </div>
      {showDetails && (
        <TopupRequestDetailView
          topupRequest={showDetails}
          onClose={handleCloseDetail}
          onRefreshMain={refresh}
        />
      )}

      {showConfirmationModal && (
        <ConfirmationModal
          title=""
          content={confirmationModalContent}
          onConfirm={setConfirmationModal}
          callToActionText="OK"
        />
      )}
      {showReportOption && (
        <PopupModal
          content={
            <SelectDates
              onBack={() => setReportOption(false)}
              onConfirm={handleGenerateReport}
            />
          }
        />
      )}
      {showSummaryReportOption && (
        <PopupModal
          content={
            <SelectDates2
              onBack={() => setSummaryReportOption(false)}
              onConfirm={handleGenerateSummaryReport}
            />
          }
        />
      )}
    </div>
  );
};

export default TopupRequestManager;
