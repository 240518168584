import { useNavigate } from "react-router-dom";
import {useCustomer} from './CustomerContext'
import React, {useEffect} from 'react'

const Authenticate = () =>{

    const customer = useCustomer()
    const navigate = useNavigate()

    useEffect(() => {
        if(!customer){
            navigate('/')
        }

    },[])

    // if(customer===null)
    //     return <Navigate to='/'/>

    return <></>
}
export default Authenticate
