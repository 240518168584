import { useRoutes } from "react-router-dom";
import BackOfficeLogin from "./features/BackOffice/Login/BackOfficeLogin";
import BackOfficeContainer from "./features/BackOffice/Container/BackOfficeContainer";
import BackOfficeIndex from "./features/BackOffice/Index";
import PublicContainer from "./features/Public/PublicContainer";
import Home from "./pages/Home/Home";
import TopupCustomer from "./pages/TopupCustomer/TopupCustomer";
import CancelTopup from "./pages/CancelTopup/CancelTopup";
import CardPaymentSuccess from "./pages/CardPaymentLandingPages/CardPaymentSuccess";
import CardPaymentFailure from "./pages/CardPaymentLandingPages/CardPaymentFailure";
import CardPaymerntCancel from "./pages/CardPaymentLandingPages/CardPaymentCancel";
import JournalEntryLoading from "./features/BackOffice/JournalEntryLoading/JournalEntryLoading";
import TopupRequestManager from "./features/BackOffice/TopupRequestManager/TopupRequestManager";
import ReportViewer from "./components/ReportViewer/ReportViewer";
import PaymentMethodManager from "./features/BackOffice/PaymentMethods/PaymentMethodManager";
import TopupCustomerByQRCode from "./pages/TopupCustomer/TopupCustomerByQRCode";
import UserManager from "./features/BackOffice/UserManager/UserManager";
import Department from "./features/BackOffice/Department/Department";
import Employee from "./features/BackOffice/Employee/Employee";
import TransmittalEntry from "./features/BackOffice/Transmittal/TransmittalEntry";
import TransmittalList from "./features/BackOffice/Transmittal/TransmittalList";

export default function RoutePaths() {
  let element = useRoutes([
    {
      path: "/BackOffice/Login",
      element: <BackOfficeLogin />,
    },
    // {
    //   path: "/Report",
    //   element: <ReportViewer />,
    // },

    {
      path: "/BackOffice",
      element: <BackOfficeContainer />,
      children: [
        {
          path: "Journal-Entry-Loading",
          element: <JournalEntryLoading />,
        },
        {
          path: "Topup-Requests",
          element: <TopupRequestManager />,
        },
        {
          path: "Topup-Requests/:date/:paymentMethodCode/:status",
          element: <TopupRequestManager />,
        },
        {
          path: "Payment-Methods",
          element: <PaymentMethodManager />,
        },
        {
          path: "User-Manager",
          element: <UserManager />,
        },
        {
          path: "Department",
          element: <Department />,
        },
        {
          path: "Employee",
          element: <Employee />,
        },
        {
          path: "Transmittal-Entry",
          element: <TransmittalEntry />,
        },
        {
          path: "Transmittal-List",
          element: <TransmittalList />,
        },
        {
          path: "",
          element: <BackOfficeIndex />,
        },
        {
          path: "*",
          element: <BackOfficeIndex />,
        },
      ],
    },
    {
      path: "/",
      element: <PublicContainer />,
      children: [
        {
          path: "",
          element: <Home />,
        },
        {
          path: "*",
          element: <Home />,
        },
        {
          path: "/:qrCode",
          element: <Home />,
        },
        {
          path: "topup-customer",
          element: <TopupCustomer />,
        },
        // {
        //   path: "topup-customer/LoginByQrCode/:qrCode",
        //   element: <TopupCustomerByQRCode />,
        // },
        {
          path: "cancel-topup",
          element: <CancelTopup />,
        },
        {
          path: "card/success",
          element: <CardPaymentSuccess />,
        },
        {
          path: "card/failure",
          element: <CardPaymentFailure />,
        },
        {
          path: "card/cancel",
          element: <CardPaymerntCancel />,
        },
      ],
    },
  ]);

  return element;
}
