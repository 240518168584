import React, { useEffect, useState } from "react";
import "./ComponentModal.css";

const ComponentModal = ({ children }) => {

  const [height, setHeight] = useState(0);
  useEffect(() => {
    const element = document.getElementById("root");
    setHeight(element.scrollHeight);
}, []);

  return (
    <div className="modalBackground"
    style={{ height: height }}
    >
      <div className="modalContainer">{children}</div>
    </div>
  );
};

export default ComponentModal;
