import React from "react";
import { useNavigate } from "react-router-dom";
import { removeCustomerToTopup, removeUserSession } from "../../utils/Common";
import UserIcon from "../../assets/user.svg";
import { useCustomer, useCustomerUpdate } from "../../utils/CustomerContext";
import { LOAD_OPTION_LOAD_SELF } from "../../utils/Constants";
import islogo from "../../assets/islogo.svg";
import AppTitle from "./AppTitle";

const Header2 = ({ title }) => {
  let navigate = useNavigate();

  const CustomerToTopup = useCustomer();
  const UpdateCustomer = useCustomerUpdate();
  // const [CustomerToTopup, setCustomerTotopup] = useState(null)

  const handleLogout = () => {
    removeCustomerToTopup();
    removeUserSession();
    UpdateCustomer(null);
    navigate("/");
  };

  return (
    <header className="container mx-auto">
      <div className="flex flex-col sm:flex-row items-center float-left">
        <div
          className="grow cursor-pointer"
          style={{ minWidth: "170px" }}
          onClick={() => navigate("/")}
        >
          <img src={islogo} width="150px" className="ml-2" />
          <AppTitle />
          <p
            className="font-extrabold"
            style={{ lineHeight: "30px", fontSize: "16px" }}
          >
            {title}
          </p>
        </div>
        <div className="">
          {CustomerToTopup && (
            <>
              <div className="text-left flex ml-4">
                {CustomerToTopup.TopUpOption === LOAD_OPTION_LOAD_SELF ? (
                  <img src={UserIcon} width="32px" height="32px" />
                ) : (
                  <span>Top-up for:</span>
                )}

                <div className="ml-1">
                  <span className="font-bold">{`${
                    CustomerToTopup.FirstName
                  } ${CustomerToTopup.LastName.substr(0, 1)}.`}</span>
                  <p className="">{CustomerToTopup.MobileNumber}</p>
                  {/* <p><a href="#" className='text-primary font-bold underline' onClick={handleLogout}>Log out</a></p> */}
                </div>
              </div>
            </>
          )}
        </div>
      </div>
    </header>
  );
};

export default Header2;
