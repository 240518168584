import React, { useState } from "react";
import TopupMethodHeader from "../LoadingOption/TopupMethodHeader";
import LeftArrow from "../../assets/leftShortArrow.svg";
import TopupCancel from "../../assets/topupCancel.svg";
import { useForm } from "react-hook-form";
import ErrorRound from "../../assets/errorRound.svg";
import { POST_REQUEST } from "../../api/APIManager";
import { URL_POST_SEARCH_TOPUP_REQUEST } from "../../api/Endpoints";
import ErrorContainer from "../Common/ErrorContainer/ErrorContainer";
import LoadingWindow from "../Common/LoadingWindow/LoadingWindow";

const SearchTopupRequest = ({ onSelectTopupRequest }) => {
  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm();
  const [errorMessage, setError] = useState(null);
  const [loadingMessage, setLoadingMessage] = useState(null);

  const getTopUpRequestToCancel = async (searchTopupRequestDTO) => {
    try {
      setError(null);
      setLoadingMessage("Searching Request...<br> Please Wait");
      const response = await POST_REQUEST(
        URL_POST_SEARCH_TOPUP_REQUEST,
        searchTopupRequestDTO
      );
      onSelectTopupRequest(response);
    } catch (ex) {
      setError(ex);
    } finally {
      setLoadingMessage(null);
    }
  };

  return (
    <div className="">
      <div className="">
        <div className="py-3">
          <a href="/">
            {" "}
            <button className="flex font-bold text-sm" onClick={() => {}}>
              <img src={LeftArrow} width="20px" />
              <span>Back</span>
            </button>
          </a>
        </div>
        <TopupMethodHeader
          icon={TopupCancel}
          title="Cancel Top-up"
          description="Cancel requested top-up transaction"
        />
        <form onSubmit={handleSubmit(getTopUpRequestToCancel)}>
          <div>
            <label className="font-bold">Reference Number</label>
            <input
              type="text"
              {...register("ReferenceNumber", { required: true })}
              className="input-text w-full"
            />
            {errors.ReferenceNumber &&
              errors.ReferenceNumber.type === "required" && (
                <div className="text-error text-sm font-bold flex">
                  <img src={ErrorRound} />
                  <span className="ml-2">Reference Number is required</span>
                </div>
              )}
          </div>
          <div className="mt-3">
            <label className="font-bold">eWallet Number/Top-up Amount</label>
            <input
              type="text"
              {...register("SupportingReference", { required: true })}
              className="input-text w-full"
            />
            {errors.SupportingReference &&
              errors.SupportingReference.type === "required" && (
                <div className="text-error text-sm font-bold flex">
                  <img src={ErrorRound} />
                  <span className="ml-2">
                    eWallet Number / Top-up Amount is required
                  </span>
                </div>
              )}
          </div>

          {errorMessage && <ErrorContainer ErrorMessage={errorMessage} />}

          <div className="text-right mt-5">
            <input
              type="submit"
              className="btn btn-primary cursor-pointer"
              value="Continue"
            />
          </div>
        </form>
      </div>
      {loadingMessage && <LoadingWindow message={loadingMessage} />}
    </div>
  );
};

export default SearchTopupRequest;
