import React from "react";

import ChevronRight from "../../assets/chevronRight.svg";

const TopupMethodButton = ({ onClick, icon, title, description, disabled }) => {
  let customClass = "cursor-default";
  let bgColor = "bg-primary";

  if (onClick) {
    customClass = "cursor-pointer hover:bg-red-50";
  }

  if (disabled) {
    bgColor = "bg-gray-400";
  }

  return (
    <div
      onClick={onClick}
      className={`flex border ${customClass} rounded-xl bg-grey-custom p-3 items-center my-2 `}
    >
      <div className={`${bgColor} rounded-full p-3`}>
        <img src={icon} width="20px" height="20px" />
      </div>
      <div className="ml-2 grow ">
        <p className="font-black" style={{ fontSize: "14px" }}>
          {title}
        </p>
        <p className="text-sm" style={{ fontSize: "12px" }}>
          {description}
        </p>
      </div>
      {onClick && (
        <div className="pl-5">
          <img src={ChevronRight} width="25px" height="20px" />
        </div>
      )}
    </div>
  );
};

export default TopupMethodButton;
