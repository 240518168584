import React from "react";

const TopupOptionLabel = ({ content, customClass }) => {
  let display = "";

  switch (content) {
    case 1: {
      display = "Load Self";
      break;
    }

    case 2: {
      display = "Load on Behalf";
      break;
    }
  }

  return (
    <span
      className={
        "opacity-0" +
        customClass +
        " p-2 bg-red-100 font-bold text-red-500 border-red-300 border rounded-md mx-1"
      }
    >
      {display}
    </span>
  );
};

export default TopupOptionLabel;
