import React, { useEffect, useState } from "react";
import {
  formatDate,
  getBackOfficeUser,
  removeBackOfficeUser,
} from "../../utils/Common";
import { useNavigate } from "react-router-dom";
import StatisticsBox from "./Dashboard/StatisticsBox";
import { RiCalendar2Fill } from "react-icons/ri";
import MonthYearPicker from "./Dashboard/MonthYearPicker";
import { APP_TITLE } from "../../AppConfiguration";

const Index = () => {
  document.title = APP_TITLE + " - Dashboard";
  const navigate = useNavigate();
  const currentBOUser = getBackOfficeUser();
  const [selectedGeneralStatisticPMethod, setGeneralStatisticPMethod] =
    useState("0");
  const [showDatePicker, setShowDatePicker] = useState(false);
  const [selectedDate, setDate] = useState(formatDate(Date.now()));
  const [isOverDatePicker, setOverDatePicker] = useState(0);
  const [userType, setUserType] = useState(0);

  useEffect(() => {
    if (currentBOUser)  handleAccessRight();
  }, []);

const handleAccessRight = () => {
  const _tags = JSON.parse(currentBOUser.Tags);
  for (let i = 0; i < _tags.length; i++) {
    if ( _tags[i] === "TOPUP_ADMIN" ) {
      setUserType(0);
      break;
    }
    if ( _tags[i] === "TRANSMITTAL_ADMIN" ) {
      setUserType(1);
      break;
    }
    if (  _tags[i] === "TRANSMITTAL_ENCODER") {
      setUserType(2);
    break; 
    }
  }
};



  const handleLogout = () => {
    removeBackOfficeUser();
    navigate("/backoffice/login");
  };

  // console.log(selectedGeneralStatisticPMethod);

  const handleDateSelected = (_selectedDate) => {
    setDate(_selectedDate);
    setShowDatePicker(false);
  };

  return (
    <div>
      {/* <p>Dashboard</p> */}
      <p className="text-gray text-lg font-bold">
        Good Day! {currentBOUser?.FirstName} {currentBOUser?.LastName}
      </p>

      <div className="w-full h-full mt-5 pt-5 border-t pr-10 flex flex-col">
      {userType === 0 && ( 
      <div className="flex justify-between">
          <div>
            <h1 className="text-lg font-bold">Transaction Statistic Dashboard</h1>
            <h3>
              Your current requests and transactions based on payment methods
            </h3>
          </div>
          <div className="border flex items-center rounded-md relative">
            <div
              className="flex p-2 cursor-pointer"
              onClick={() => setShowDatePicker(!showDatePicker)}
            >
              <RiCalendar2Fill className="mr-2" size={20} />
              <span>{formatDate(new Date(selectedDate))}</span>
            </div>
            {showDatePicker && (
              <div className="absolute right-0" style={{ marginTop: "150px" }}>
                <MonthYearPicker
                  onDateSelected={handleDateSelected}
                  defaultDate={selectedDate}
                  onCancel={() => setShowDatePicker(false)}
                />
              </div>
            )}
          </div>
        </div>  )}
        {userType === 0 && ( 
        <div className="flex gap-5 mt-3">
          <div
            id="BankTransferStats"
            className="flex flex-col border rounded-md grow"
          >
            <div className="border-b p-2">
              <h2 className="font-bold text-md p-2">
                Statistics for Bank Transfer
              </h2>
            </div>
            <StatisticsBox paymentCode="104" dateRange={selectedDate} />
          </div>
          <div
            id="GeneralStats"
            className="flex flex-col border rounded-md grow"
          >
            <div className="border-b p-2 flex justify-between">
              <span className="font-bold text-md p-2">General Statistics</span>
              <select
                className="font-bold border px-2 rounded-md"
                onChange={(e) => setGeneralStatisticPMethod(e.target.value)}
              >
                <option value="0">All Payment Methods</option>
                <option value="103">Pay on Rider</option>
                <option value="102">Pay on Site</option>
                <option value="101">Debit/Credit Card</option>
              </select>
            </div>
            <StatisticsBox
              paymentCode={selectedGeneralStatisticPMethod}
              dateRange={selectedDate}
            />
          </div>
        </div> )}
      </div>
    </div>
  );
};

export default Index;
