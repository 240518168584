import React, { useState } from "react";
import SelectAmount from "../../components/SelectAmount/SelectAmount2";
import SelectPayment from "../../components/SelectPayment/SelectPayment2";
import ConfirmationModal from "../../components/ConfirmationModal/ConfirmationModal";
import Authenticate from "../../utils/Authenticate";
import { formatNumber } from "../../utils/Common";
import AlertModal from "../../components/AlertModal/AlertModal";
import { useNavigate } from "react-router-dom";
import axios from "axios";
import {
  URL_POST_APPROVE_TOPUP_REQUEST,
  URL_POST_NEW_TOPUP_REQUEST,
} from "../../api/Endpoints";
import NewTopupRequest from "../../classes/TopupRequestAgg/NewTopupRequest";
import TopupMethodHeader from "../../components/LoadingOption/TopupMethodHeader";
import Self from "../../assets/self.svg";
import Behalf from "../../assets/behalf.svg";
import { LOAD_OPTION_LOAD_SELF } from "../../utils/Constants";
import { useCustomer } from "../../utils/CustomerContext";
import { POST_REQUEST } from "../../api/APIManager";
import ApproveTopupRequestDTO from "../../classes/TopupRequestAgg/ApproveTopupRequestDTO";
import AlertModalContainer from "../../classes/CommonAgg/AlertModalContainer";
import ErrorContainer from "../../components/Common/ErrorContainer/ErrorContainer";
import { initialize, createCheckout } from "../../api/PaymayaAPI";
import CheckoutDTO from "../../classes/PaymayaAgg/CheckoutDTO";
import BuyerDTO from "../../classes/PaymayaAgg/BuyerDTO";
import { APP_TITLE } from "../../AppConfiguration";

const TopupCustomer = () => {
  let navigate = useNavigate();

  const [alertModal, setAlertModal] = useState(null);
  const [selectedAmount, setSelectedAmount] = useState(0);
  const [selectedMop, setSelectedPayment] = useState(null);
  const [openConfirmationModal, setConfirmationModal] = useState(false);
  const [activeTopupRequest, setActiveTopupRequest] = useState(null);
  const [errorMessage, setError] = useState(null);

  // const [openAlertModal, setAlertModal] = useState(false)
  const ActiveUser = useCustomer();

  const handleAmountSelection = (loadAmount) => {
    setSelectedAmount(loadAmount);
  };

  const ApproveTopupRequest = async (topupRequestId) => {
    try {
      const approveTopup = new ApproveTopupRequestDTO(
        topupRequestId,
        "Paid via Card"
      );

      let response = await POST_REQUEST(
        URL_POST_APPROVE_TOPUP_REQUEST,
        approveTopup
      );

      const _alertModal = new AlertModalContainer(
        true,
        "Top-up Successful",
        `Top-up Successful! Please Check your eWallet Account.`
      );
      setAlertModal(_alertModal);
    } catch (Exception) {
      setError(Exception);
      console.log(Exception);
    } finally {
      setActiveTopupRequest(null);
    }
  };

  const initiatePaymaya = () => {
    initialize();
    const buyer = new BuyerDTO(
      ActiveUser.FirstName,
      ActiveUser.LastName,
      ActiveUser.MobileNumber,
      ActiveUser.Email ?? "user-no-email@iloilosupermart.com"
    );
    const checkoutObject = new CheckoutDTO(
      activeTopupRequest.referenceNumber,
      activeTopupRequest.topupRequestAmount,
      buyer
    );
    createCheckout(checkoutObject);
  };

  const handleConfirmPayment = async () => {
    try {
      
      let _number = selectedAmount.toString().replaceAll(",", "");
      setError(null);
      setConfirmationModal(false);
      const _newTopupRequest = new NewTopupRequest(
        ActiveUser.TopUpOption,
        ActiveUser.Id,
        ActiveUser.FullName,
        _number,
        selectedMop.code,
        ActiveUser.MobileNumber,
        null
      );

      let response = await POST_REQUEST(
        URL_POST_NEW_TOPUP_REQUEST,
        _newTopupRequest
      );

      let alertModalText = `You will be topped up ₱ ${formatNumber(
        selectedAmount
      )} via ${selectedMop?.name}. Your Reference Number is ${
        response.referenceNumber
      }`;

      if (selectedMop?.code === "101") {
        alertModalText = `Your Reference Number is ${response.referenceNumber}. You will be redirected to payment page. Please click OK to continue.`;
      }

      //setAlertModal(true)
      const _alertModal = new AlertModalContainer(
        true,
        "Payment Selected",
        alertModalText
      );
      setAlertModal(_alertModal);

      switch (selectedMop?.code) {
        //Credi/Debit Card
        case "101": {
          setActiveTopupRequest(response);
          //ApproveTopupRequest(response.id)
          console.log("User Will be directed to Paymaya Portal");
          break;
        }
        //Pay on Site
        case "102": {
          console.log("User Will be directed to Thank you Page");
          break;
        }
        //Pay with Rider
        case "103": {
          console.log("User Will be directed to Pay With Rider Page");
          break;
        }
        //Bank Transfer
        case "104": {
          console.log(
            "User Will be directed to Bank Transfer Instruction Page"
          );
          break;
        }

        default: {
          console.log("Payment is currently not available");
          break;
        }
      }
    } catch (error) {
      console.log(error);
      setError(error);
    }
  };

  const handleClickConfirmAlert = () => {
    //navigate('/')
    setAlertModal(null);
    setSelectedAmount(0);
    setSelectedPayment(null);

    if (activeTopupRequest?.paymentMethodCode === "101") {
      //ApproveTopupRequest(activeTopupRequest.id)
      initiatePaymaya();
    }
  };

  let modalContent = [];

  if (ActiveUser && selectedMop) {
    modalContent.push(
      "You are about to top-up <strong> ₱" +
        formatNumber(selectedAmount) +
        " </strong> to " +
        ActiveUser.MobileNumber +
        " via " +
        selectedMop.name +
        "."
    );
    modalContent.push("Continue?");
  }

  let LoadOptionHeader = null;

  //console.log(ActiveUser)

  if (ActiveUser && ActiveUser.TopUpOption === LOAD_OPTION_LOAD_SELF) {
    document.title = APP_TITLE + "-Top-up My Account";
    LoadOptionHeader = (
      <TopupMethodHeader
        icon={Self}
        title="Top-up my account"
        description="Top-up your personal/own eWallet account"
      />
    );
  } else {
    document.title = APP_TITLE + "-Top-up Other Account";
    LoadOptionHeader = (
      <TopupMethodHeader
        icon={Behalf}
        title="Top-up other account"
        description="Top-up for other eWallet account"
      />
    );
  }

  let isCallToActionDisable = true;

  if (parseInt(selectedAmount) > 0 && selectedMop) isCallToActionDisable = false;

  return (
    <div className="">
      <Authenticate />

      {LoadOptionHeader}
      <SelectAmount
        onAmountSelected={handleAmountSelection}
        currentValue={selectedAmount}
      />
      <SelectPayment
        amountToLoad={selectedAmount}
        loadOption={ActiveUser?.TopUpOption}
        onPaymentSelected={setSelectedPayment}
        currentValue={selectedMop}
      />

      <div className="text-right mt-2">
        <a href="/">
          <button className="btn btn-secondary">Go Back</button>
        </a>
        {/* <button className="btn btn-secondary" onClick={handleClickConfirmAlert}>Go Back</button> */}

        <button
          disabled={isCallToActionDisable}
          className="btn btn-primary"
          onClick={() => setConfirmationModal(true)}
        >
          Top-up eWallet
        </button>
      </div>

      {openConfirmationModal && (
        <ConfirmationModal
          closeModal={setConfirmationModal}
          title="Confirm eWallet Top-up"
          content={modalContent}
          onConfirm={handleConfirmPayment.bind(this)}
          callToActionText="Confirm"
        />
      )}
      {/* { openAlertModal && <AlertModal closeModal={handleClickConfirmAlert} title="Payment Selected" content={"You will be topped up PHP " + formatNumber(selectedAmount) + " via " + selectedMop.name } />} */}
      {alertModal && alertModal.isShow && (
        <AlertModal
          closeModal={handleClickConfirmAlert}
          title={alertModal?.title}
          content={alertModal?.content}
        />
      )}

      {errorMessage && <ErrorContainer ErrorMessage={errorMessage} />}
    </div>
  );
};

export default TopupCustomer;
