import React, { useEffect, useState } from "react";

const EditTopupOptionsControl = ({ topUpOptions, onOptionsChanged }) => {
  const topupOptions = JSON.parse(topUpOptions);

  const [loadSelf, setLoadSelf] = useState(topupOptions.includes(1));
  const [loadBehalf, setLoadBehalf] = useState(topupOptions.includes(2));

  const initialSetupTopupOptions = () => {
    topupOptions.forEach((option) => {
      switch (option) {
        case 1: {
          setLoadSelf(true);
          break;
        }
        case 2: {
          setLoadBehalf(true);
          break;
        }
      }
    });
  };

  const readSelectedTopupOptions = () => {
    let values = [];

    if (loadSelf) values.push(1);
    else {
      const _values = values.filter((e) => e !== 1);
      values = _values;
    }

    if (loadBehalf) values.push(2);
    else {
      const _values = values.filter((e) => e !== 2);
      values = _values;
    }

    onOptionsChanged(JSON.stringify(values));
  };

  useEffect(() => {
    readSelectedTopupOptions();
  }, [loadSelf, loadBehalf]);

  useEffect(() => {
    initialSetupTopupOptions();
  }, []);

  return (
    <div>
      <div>
        <input
          name="chbLoadSelf"
          type="checkbox"
          value="1"
          defaultChecked={loadSelf}
          onChange={(e) => setLoadSelf(e.currentTarget.checked)}
        />
        <label className="ml-2" htmlFor="chbLoadSelf">
          Load Self
        </label>
      </div>
      <div>
        <input
          name="chbLoadBehalf"
          type="checkbox"
          value="2"
          defaultChecked={loadBehalf}
          onChange={(e) => setLoadBehalf(e.currentTarget.checked)}
        />
        <label className="ml-2" htmlFor="chbLoadBehalf">
          Load on Behalf
        </label>
      </div>
    </div>
  );
};

export default EditTopupOptionsControl;
