import React from "react";

const TopupRequestStatusIndicator = ({ status, statusText }) => {
  let statusClass = "";
  switch (status) {
    case 0: {
      statusClass = "status-pending";
      break;
    }
    case 1: {
      statusClass = "status-done";
      break;
    }
    case 2: {
      statusClass = "status-processing";
      break;
    }
    case 3: {
      statusClass = "status-expired";
      break;
    }
    case 5: {
      statusClass = "status-onhold";
      break;
    }
  }

  return <span className={`status ${statusClass}`}>{statusText}</span>;
};

export default TopupRequestStatusIndicator;
