import React, { useEffect, useState } from 'react'
import LoadingWindow from '../../../components/Common/LoadingWindow/LoadingWindow'
import { GET_REQUEST } from '../../../api/APIManager';
import { URL_GET_EMPLOYEES_BY_DEPARTMENT_ID } from '../../../api/Endpoints';

const ViewEmployees = ({ onClose, department }) => {

  const [employees, setEmployees] = useState(null);
  const [loadingMessage, setLoadingMessage] = useState(null);


  useEffect(() => {
    getEmployees();
}, []);

const getEmployees = async () => {
  try {
    setLoadingMessage("Loading View Employees");
    const payload = { departmentId: department.id };
    var entries = await GET_REQUEST(URL_GET_EMPLOYEES_BY_DEPARTMENT_ID, payload);
    setEmployees(entries);
  } catch (e) {
    console.log(e);
  } finally {
    setLoadingMessage(null);
  }
};

  return (
    <div
    className="bg-white p-5 rounded-md shadow-md"
    style={{ width: "400px" }}
  >
    <h1 className="font-bold text-lg">List of Employees</h1>

    <div className="border-t mt-3 pt-4"
    style={{ maxHeight:"75vh",overflowY:"scroll" }}>
        <table
          id="tblPaymentMethods"
          className="w-full rounded-md overflow-hidden border-collapse bg-grey-custom mt-4"
        >
          <thead>
            <tr className="border">
            <th className="text-left">Employee Name</th>
            <th className="text-left">Status</th>
              <th></th>
            </tr>
          </thead>
          <tbody>
          {employees?.map((entry) => {
              return (
                <tr key={entry.id}>
                  <td>{entry.employeeName}</td>
                  <td>{(entry.status) ? "Active" : "Inactive"}</td>
                </tr>
              );
            })}
          </tbody>
        </table>

        <div className="font-bold mt-2">Total Employees: {department.numberOfEmployees}</div>
      </div>
      <div className="flex justify-end mt-4">
        <button className="btn btn-primary  text-center border p-2 m-2 rounded-md" onClick={onClose}>
          close
        </button>
      </div>
    {loadingMessage && <LoadingWindow message={loadingMessage} />}
  </div>
  )
}

export default ViewEmployees