import React, { useEffect, useState } from "react";
import ReactDatePicker from "react-datepicker";
import { AiOutlineReload, AiOutlineSearch } from "react-icons/ai";
import { BsFolder, BsPlusLg } from "react-icons/bs";
import { GET_REQUEST, POST_REQUEST } from "../../../api/APIManager";
import {
  URL_GET_ACTIVE_DEPARTMENTS,
  URL_GET_CREATE_NEW_TRANSMITTAL,
  URL_POST_TRANSMITTAL,
} from "../../../api/Endpoints";
import {
  formatDate_journal,
  formatNumber,
  formatShortDate,
  getBackOfficeUser,
} from "../../../utils/Common";
import LoadingWindow from "../../../components/Common/LoadingWindow/LoadingWindow";
import { useNotificationUpdate } from "../../../utils/NotificationContext";
import OKConfirmationModal from "../../../components/ConfirmationModal/OKConfirmationModal";
import ConfirmationModal from "../../../components/ConfirmationModal/ConfirmationModal";

const TransmittalEntry = () => {
  const [transmittalDate, setTransmittalDate] = useState(new Date());
  const [startDate, setStartDate] = useState(new Date());
  const [endDate, setEndDate] = useState(new Date());
  const [departments, setDepartments] = useState(null);
  const [departmentId, setDepartmentId] = useState(0);
  const [amountValue, setAmount] = useState("0");
  const [remarks, setRemarks] = useState("");
  const [transmittalDetailDTOs, setTransmittalDetailDTOs] = useState(null);
  const [unfilteredTransmittalDetailDTOs, setUnfilteredTransmittalDetailDTOs] = useState(null)
  const [loadingMessage, setLoadingMessage] = useState(null);
  const updateNotif = useNotificationUpdate();
  const boUser = getBackOfficeUser();

  const [showSaveConfirmationModal, setSaveConfirmationModal] = useState(false);
  let saveConfirmationModalContent = [];
  saveConfirmationModalContent.push(
    "Would you like to proceed with saving the transmittal transaction?"
  );

  const [showConfirmationModal, setConfirmationModal] = useState(false);
  let confirmationModalContent = [];
  confirmationModalContent.push(
    "No entries found please create new transmittal!"
  );

  useEffect(() => {
    getDepartments();
  }, []);

  const getDepartments = async () => {
    try {
      var departments = await GET_REQUEST(URL_GET_ACTIVE_DEPARTMENTS);
      setDepartmentId(departments[0].id);
      setDepartments(departments);
    } catch (e) {
      console.log(e);
    }
  };

  const handleOnAmountLostFocus = () => {
    var numbers = amountValue.split(".");
    var amt = numbers[0] + (numbers[1] ? "." + numbers[1] : "");

    setAmount(formatNumber(amt));
  };

  const handleOnchangeAmount = (e) => {
    const re = /^\d{1,}(\.\d{0,4})?$/;
    let number = e.target.value.toString().replaceAll(",", "");
    if (number === "" || re.test(number)) {
      setAmount(e.target.value);
    }
  };

  const handleOnchangeEmpAmount = (e, employeeId) => {
    const re = /^\d{1,}(\.\d{0,4})?$/;
    let number = e.target.value.toString().replaceAll(",", "");
    if (number === "" || re.test(number)) {
      var res = transmittalDetailDTOs?.map((t) => {
        if (t.employeeId == employeeId) {
          t.amount = e.target.value;
        }
        return t;
      });
      setTransmittalDetailDTOs(res);
    }
  };

  const handleOnEmpAmountLostFocus = (e, employeeId) => {
    var numbers = e.target.value.split(".");
    var amt = numbers[0] + (numbers[1] ? "." + numbers[1] : "");

    var res = transmittalDetailDTOs?.map((t) => {
      if (t.employeeId == employeeId) {
        t.amount = formatNumber(amt);
      }
      return t;
    });
    setTransmittalDetailDTOs(res);
  };

  const handleApplyAmount = () => {
    var res = transmittalDetailDTOs?.map((t) => {
      t.amount = amountValue;
      return t;
    });
    setTransmittalDetailDTOs(res);
  };

  const handleCreateNewTransmittal = async () => {
    try {
      const payload = {
        departmentId: departmentId,
      };
      var entries = await GET_REQUEST(URL_GET_CREATE_NEW_TRANSMITTAL, payload);
      var res = entries.map((t) => {
        t.amount = "0.00";
        return t;
      });
      setTransmittalDetailDTOs(res);
      setUnfilteredTransmittalDetailDTOs(res);
    } catch (e) {
      console.log(e);
    }
  };

  const validateSave = () => {
    if (transmittalDetailDTOs === null || transmittalDetailDTOs.length === 0) {
      setConfirmationModal(true);
    } else {
      setSaveConfirmationModal(true);
    }
  };

  const handleSaveTransmittal = async () => {
    try {

      var cloneTransmittalDetails = JSON.parse(
        JSON.stringify(unfilteredTransmittalDetailDTOs)
      );

      //console.log(cloneTransmittalDetails);

      cloneTransmittalDetails?.map((t) => {
        t.amount = t.amount.replaceAll(",", "");
        return t;
      });

      let transmittalHeaderDTO = {
        transmittalDate: transmittalDate.toISOString(),
        dateStart: startDate.toISOString(),
        dateEnd: endDate.toISOString(),
        remarks: remarks,
        departmentId: departmentId,
        initiatedById: boUser ? boUser.Id : 0,
        initiatedByName: boUser?.FirstName + " " + boUser?.LastName,
        transmittalDetails: cloneTransmittalDetails,
      };
      //console.log(transmittalHeaderDTO);
      setLoadingMessage("Processing Transmittal Entry");
      await POST_REQUEST(URL_POST_TRANSMITTAL, transmittalHeaderDTO);
      updateNotif("The transmittal transaction has been saved successfully.");

      setTransmittalDetailDTOs(null);
      setUnfilteredTransmittalDetailDTOs(null);
      setAmount("0");
      setRemarks("");
    } catch (e) {
      console.log(e);
    } finally {
      setLoadingMessage(null);
      setSaveConfirmationModal(false);
    }
  };

  const handleOnSearchName = (e) => {
    const searchName = e.target.value;

    const filteredItems = unfilteredTransmittalDetailDTOs.filter((emp) =>
    emp.employeeName.toLowerCase().includes(searchName.toLowerCase())
    );

    setTransmittalDetailDTOs(filteredItems);
  };

  return (
    <div>
      <div className="flex justify-between">
        <h1 className="font-bold text-lg">Transmittal Entry</h1>
      </div>

      <div className="border-t mt-3 pt-4">
        <div className="p-3 m-0 border rounded-md border-b-0 flex items-center">
          <label
            className="font-bold"
            style={{ minWidth: 110, marginLeft: 40 }}
          >
            Transmittal Date:
          </label>
          <ReactDatePicker
            className="date-picker border border-black rounded-md px-1 py-1"
            selected={transmittalDate}
            onChange={(date) => setTransmittalDate(date)}
          />
          <label className="font-bold" style={{ minWidth: 70 }}>
            Date From:
          </label>
          <ReactDatePicker
            className="date-picker border border-black rounded-md px-1 py-1"
            selected={startDate}
            onChange={(date) => setStartDate(date)}
          />
          <label className="font-bold" style={{ minWidth: 70 }}>
            Date To:
          </label>
          <ReactDatePicker
            className="date-picker border border-black rounded-md px-1 py-1"
            selected={endDate}
            onChange={(date) => setEndDate(date)}
          />
          <label className="font-bold">Department:</label>
          <select
            className="border rounded-md px-2 py-1 mx-4"
            onChange={(e) => setDepartmentId(e.target.value)}
            value={departmentId}
          >
            {departments?.map((dep) => {
              return (
                <option key={dep.id} value={dep.id}>
                  {dep.departmentName}
                </option>
              );
            })}
          </select>
          <label className="font-bold ml-5">Remarks:</label>
          <textarea
            className="input mx-2 border border-black rounded p-1"
            style={{ maxHeight: "200px", minHeight: "70px", minWidth: "200px" }}
            value={remarks}
            onChange={(e) => setRemarks(e.target.value)}
          />

          <button
            className="btn btn-glow btn-primary flex items-center ml-2"
            style={{ minWidth: 110, justifyContent: "center" }}
            onClick={handleCreateNewTransmittal}
          >
            Create New
          </button>
          <button
            className="btn btn-glow btn-primary flex items-center ml-2"
            style={{ minWidth: 100, justifyContent: "center" }}
            onClick={validateSave}
          >
            Save
          </button>
        </div>

        <div className="font-bold text-sm mt-2 pl-2 flex items-center justify-between">
          <div className="flex items-center">
            <label className="mx-1 flex items-center">
              Number of Employee:
              <label style={{ color: "#25cc40", marginLeft: 2 }}>
                {unfilteredTransmittalDetailDTOs?.length}
              </label>
            </label>
            <label className="ml-5">Search Name</label>
            <input
              className="input-text mx-2"
              onChange={(e) => handleOnSearchName(e)}
            />
          </div>

          <div>
            <label>
              Total Amount:
              <label style={{ color: "red", marginLeft: 2 }}>
                {unfilteredTransmittalDetailDTOs != null
                  ? formatNumber(
                      unfilteredTransmittalDetailDTOs?.reduce(
                        (total, currentValue) =>
                          (total =
                            total +
                            parseInt(currentValue.amount.replaceAll(",", ""))),
                        0
                      )
                    )
                  : "0"}
              </label>
            </label>

            <label className="ml-5">Set Amount</label>
            <input
              className="input-text mx-2 font-bold"
              style={{ color: "red" }}
              onChange={(e) => handleOnchangeAmount(e)}
              onBlur={handleOnAmountLostFocus}
              value={amountValue}
              onFocus={(e) => e.target.select()}
            />
            <button
              className="btn btn-glow btn-primary mr-3"
              style={{ minWidth: 100 }}
              onClick={handleApplyAmount}
            >
              Apply to All
            </button>
          </div>
        </div>

        <table
          id="tblCommons"
          className="w-full rounded-md overflow-hidden border-collapse bg-grey-custom mt-4"
        >
          <thead>
            <tr className="border">
              <th className="text-left">Employee Name</th>
              <th className="text-left">Position</th>
              <th className="text-left">eWallet Account Info.</th>
              <th className="text-left">Amount</th>
              <th></th>
            </tr>
          </thead>
          <tbody>
            {transmittalDetailDTOs?.map((entry) => {
              return (
                <tr key={entry.employeeId}>
                  <td className="font-bold">{entry.employeeName}</td>
                  <td>{entry.position}</td>
                  <td>{entry.ewalletAccountInfo}</td>
                  <td>
                    <input
                      style={{ color: "red", fontWeight: "bold" }}
                      className="input-text"
                      value={entry.amount}
                      onChange={(e) =>
                        handleOnchangeEmpAmount(e, entry.employeeId)
                      }
                      onFocus={(e) => e.target.select()}
                      onBlur={(e) =>
                        handleOnEmpAmountLostFocus(e, entry.employeeId)
                      }
                    ></input>
                  </td>
                </tr>
              );
            })}
          </tbody>
        </table>
      </div>
      {showConfirmationModal && (
        <OKConfirmationModal
          title=""
          content={confirmationModalContent}
          onConfirm={setConfirmationModal}
          callToActionText="OK"
        />
      )}
      {showSaveConfirmationModal && (
        <ConfirmationModal
          title="Confirm"
          content={saveConfirmationModalContent}
          closeModal={() => {
            setSaveConfirmationModal(false);
            updateNotif(
              "The transmittal transaction has been cancelled successfully."
            );
          }}
          onConfirm={handleSaveTransmittal}
          callToActionText="Confirm"
        />
      )}
      {loadingMessage && <LoadingWindow message={loadingMessage} />}
    </div>
  );
};

export default TransmittalEntry;
