import React, { useEffect, useState } from "react";
import { TbCheckbox, TbCircleCheck, TbReport, TbX } from "react-icons/tb";
import {
  URL_GET_TRANSMITTAL_BY_STATUS,
  URL_GET_TRANSMITTAL_BY_STATUS_COUNT,
  URL_POST_APPROVE_TRANSMITTALS,
  URL_POST_REJECT_TRANSMITTALS,
} from "../../../api/Endpoints";
import { GET_REQUEST, POST_REQUEST } from "../../../api/APIManager";
import { AiFillPrinter } from "react-icons/ai";
import {
  formatDate_journal,
  formatNumber,
  getBackOfficeUser,
} from "../../../utils/Common";
import ConfirmationModal from "../../../components/ConfirmationModal/ConfirmationModal";
import LoadingWindow from "../../../components/Common/LoadingWindow/LoadingWindow";
import { useNotificationUpdate } from "../../../utils/NotificationContext";
import { BsChevronLeft, BsChevronRight } from "react-icons/bs";
import { IoAlertCircleOutline } from "react-icons/io5";
import TransmittalView from "./TransmittalView";
import ComponentModal from "../../../components/Common/ComponentModal/ComponentModal";
import PrintTransmittal from "./PrintTransmittal";

const TransmittalList = () => {
  const [status, setStatus] = useState('0');
  const [transmittalDTOs, setTransmittalDTOs] = useState(null);
  const [loadingMessage, setLoadingMessage] = useState(null);
  const [showApproveConfirmationModal, setApproveConfirmationModal] = useState(false);
  const [showRejectConfirmationModal, setRejectConfirmationModal] = useState(false);
  const boUser = getBackOfficeUser();
  const updateNotif = useNotificationUpdate();
  const columnNames = { 0: "", 1: "Date Approved", 2: "Rejected Date" };
  const columnName = columnNames[status];
  const [disableButton, setDisableButton] = useState(false);
  const [showDetails, setShowDetails] = useState(null);
  const [printReport, showPrintReport] = useState(false);

  const [totalEntryCount, setTotalEntryCount] = useState(0);
  const [pageSize, setPageSize] = useState(10);
  const [pageNumber, setPageNumber] = useState(1);
  const [currentTransmittalDTO, setCurrentTransmittalDTO] = useState(null);
  const [userType, setUserType] = useState(null);

  const currentBOUser = getBackOfficeUser();

  useEffect(() => {
    handleAccessRight();
  }, []);

const handleAccessRight = () => {
  const _tags = JSON.parse(currentBOUser.Tags);
  for (let i = 0; i < _tags.length; i++) {
    if ( _tags[i] === "TOPUP_ADMIN" ) {
      setUserType(0);
      break;
    }
    if ( _tags[i] === "TRANSMITTAL_ADMIN" ) {
      setUserType(1);
      break;
    }
    if (  _tags[i] === "TRANSMITTAL_ENCODER") {
      setUserType(2);
    break; 
    }
  }
};


  useEffect(() => {

    getTotalEntryCount();
    getTransmittals();

    setDisableButton(!(status === '0') || !(userType <= 1));


  }, [userType, status, pageNumber, pageSize]);


  const getTotalEntryCount = async () => {
    try {
      const payload = { status: status };
      var count = await GET_REQUEST(URL_GET_TRANSMITTAL_BY_STATUS_COUNT, payload);
      setTotalEntryCount(count);
    } catch (e) {}
  };

  const getTransmittals = async () => {
    try {
      const payload = { status: status, pageSize: pageSize, page: pageNumber };
      var entries = await GET_REQUEST(URL_GET_TRANSMITTAL_BY_STATUS, payload);

      var res = entries?.map((t) => {
        t.selected = false;
        return t;
      });

      setTransmittalDTOs(res);
    } catch (e) {
      console.log(e);
    } finally {
    }
  };

  let confirmationModalContent = [];

  if (showApproveConfirmationModal)
    confirmationModalContent.push(
      "Are you sure you want to approve this transmittal/s?"
    );

  if (showRejectConfirmationModal)
    confirmationModalContent.push(
      "Are you sure you want to reject this transmittal/s?"
    );

  const handleOnSelect = (isSeleted, transmittalId) => {
    var res = transmittalDTOs?.map((t) => {
      if (t.id == transmittalId) {
        t.selected = isSeleted;
      }
      return t;
    });
    setTransmittalDTOs(res);
  };

  const handleShowApproveConfirmationModal = () => {
    const selectedTransmittal = transmittalDTOs.filter((e) => e.selected);
    if (selectedTransmittal.length > 0) {
      setApproveConfirmationModal(true);
    }
  };

  const handleAproveTransmittal = async () => {
   
    try {
      const selectedTransmittal = transmittalDTOs.filter((e) => e.selected);
      var payload = selectedTransmittal?.map((t) => {
        let y = {
          id: t.id,
          approvedBy: boUser ? boUser.Id : 0,
          approveByName: boUser?.FirstName + " " + boUser?.LastName,
        };

        return y;
      });

      setLoadingMessage("Processing Transmittal");
      await POST_REQUEST(URL_POST_APPROVE_TRANSMITTALS, payload);
      updateNotif("Transmittal Successfully Approved");
      getTransmittals();
    } catch (e) {
      console.log(e);
    } finally {
      setLoadingMessage(null);
      setApproveConfirmationModal(false);
    }
  };

  const handleShowRejectConfirmationModal = () => {
    const selectedTransmittal = transmittalDTOs.filter((e) => e.selected);
    if (selectedTransmittal.length > 0) {
      setRejectConfirmationModal(true);
    }
  };

  const handleRejectTransmittal = async () => {
   
    try {
      const selectedTransmittal = transmittalDTOs.filter((e) => e.selected);
      var payload = selectedTransmittal?.map((t) => {
        let y = {
          id: t.id,
          rejectedBy: boUser ? boUser.Id : 0,
          rejectedByName: boUser?.FirstName + " " + boUser?.LastName,
        };

        return y;
      });

      setLoadingMessage("Processing Transmittal");
      await POST_REQUEST(URL_POST_REJECT_TRANSMITTALS, payload);
      updateNotif("Transmittal Successfully Rejected");
      getTransmittals();
    } catch (e) {
      console.log(e);
    } finally {
      setLoadingMessage(null);
      setRejectConfirmationModal(false);
    }
  };


  const nextPage = () => {
    if (pageNumber * pageSize < totalEntryCount)
      setPageNumber(pageNumber + 1);
  };
  const prevPage = () => {
    if (pageNumber > 1) {
      setPageNumber(pageNumber - 1);
    }
  };


  const handleGenerateReport = (transmittalDTO) => {

    setCurrentTransmittalDTO(transmittalDTO);
    showPrintReport(true);
  };

  return (
    <div>
      {printReport && (
        <PrintTransmittal
          showPrintReport={showPrintReport}
          transmittalHeader={currentTransmittalDTO}
        />
      )}

      <div className="flex justify-between">
        <h1 className="font-bold text-lg">Transmittal List</h1>
      </div>

      <div className="border-t mt-3 pt-4">
        <div className="font-bold p-3 m-0 border rounded-md border-b-0 flex items-center">
          <label style={{ minWidth: 50, marginLeft: 40 }}>Status:</label>
          <select
            className="border rounded-md px-2 py-1 ml-2"
            style={{ minWidth: "100px" }}
            onChange={(e) => setStatus(e.target.value)}
            value={status}
          >
            <option value={0}>Pending</option>
            <option value={1}>Approved</option>
            <option value={2}>Rejected</option>
          </select>

          <button
            className="btn btn-glow btn-primary flex items-center ml-10 font-bold"
            onClick={handleShowRejectConfirmationModal}
            disabled={disableButton}
          >
            <TbX color="white" className="mr-1" size="16" />
            Reject
          </button>
          <button
            className="btn btn-glow btn-primary flex items-center ml-2 font-bold"
            onClick={handleShowApproveConfirmationModal}
            disabled={disableButton}
          >
            <TbCircleCheck color="white" className="mr-1" size="16" />
            Approve
          </button>
        </div>

        <div className="font-bold text-sm mt-2 pl-2 flex justify-between">
          <label className="mx-1 flex items-center">
            Number of Transmittals:
            <label style={{ color: "#25cc40", marginLeft: 2 }}>
              {transmittalDTOs?.length}
            </label>
          </label>
        </div>

        <table
          id="tblCommons"
          className="w-full rounded-md overflow-hidden border-collapse bg-grey-custom mt-4"
        >
          <thead>
            <tr className="border">
              <th className="text-left">Selected</th>
              <th className="text-left">Date Created</th>
              <th className="text-left">{columnName}</th>
              <th className="text-left">Department</th>
              <th className="text-left">Status</th>
              <th className="text-left">Transmittal Date</th>
              <th className="text-left">Remarks</th>
              <th className="text-left">Amount</th>
              <th></th>
            </tr>
          </thead>
          <tbody>
            {transmittalDTOs?.map((entry) => {
              return (
                <tr key={entry.id}>
                  <td>
                    <input
                      className="flex items-center"
                      type="checkbox"
                      //defaultChecked={entry.selected}
                      disabled= {disableButton}
                      value={entry.selected}
                      onChange={(e) =>
                        handleOnSelect(e.currentTarget.checked, entry.id)
                      }
                    ></input>
                  </td>
                  <td>{formatDate_journal(entry.dateCreated)}</td>
                  <td>
                    {status === '1'
                      ? formatDate_journal(entry.approvedDate)
                      : formatDate_journal(entry.rejectedDate)}
                  </td>
                  <td>{entry.departmentName}</td>
                  <td>{entry.statusName}</td>
                  <td>{formatDate_journal(entry.transmittalDate)}</td>
                  <td>{entry.remarks}</td>
                  <td>{formatNumber(entry.total)}</td>
                  <td className="flex">
                    <button
                      className="flex items-center border p-2 rounded-md"
                      onClick={() => setShowDetails(entry)}
                    >
                      <IoAlertCircleOutline className="mr-1" size="16" />
                      View Details
                    </button>

                    <button
                      className="flex items-center border p-2 rounded-md ml-3"
                      onClick={() => handleGenerateReport(entry)}
                    >
                      <AiFillPrinter className="mr-1" size="16" />
                      Generate Report
                    </button>
                  </td>
                </tr>
              );
            })}
          </tbody>
          <tfoot>
            <tr>
              <td colSpan={8}>
                Rows per page
                <select
                  className="px-2"
                  onChange={(e) => {
                    setPageSize(e.target.value);
                  }}
                >
                  <option>10</option>
                  <option>15</option>
                  <option>20</option>
                </select>
              </td>
              <td className="">
                <div className="flex items-center justify-end">
                  <span className="mr-5">
                    {pageSize * (pageNumber - 1) + 1} -{" "}
                    {pageSize * pageNumber < totalEntryCount
                      ? pageSize * pageNumber
                      : totalEntryCount}{" "}
                    of {totalEntryCount} items
                  </span>
                  <BsChevronLeft
                    size="15"
                    className="mr-2 cursor-pointer"
                    onClick={() => prevPage()}
                  />
                  <BsChevronRight
                    size="15"
                    className="cursor-pointer"
                    onClick={() => nextPage()}
                  />
                </div>
              </td>
            </tr>
          </tfoot>
        </table>
      </div>

      {showRejectConfirmationModal && (
        <ConfirmationModal
          title="Confirm"
          content={confirmationModalContent}
          closeModal={setRejectConfirmationModal}
          onConfirm={handleRejectTransmittal}
          callToActionText="Confirm"
        />
      )}
      {showApproveConfirmationModal && (
        <ConfirmationModal
          title="Confirm"
          content={confirmationModalContent}
          closeModal={setApproveConfirmationModal}
          onConfirm={handleAproveTransmittal}
          callToActionText="Confirm"
        />
      )}
      {showDetails && (
        <ComponentModal>
          <TransmittalView
            onClose={() => setShowDetails(null)}
            transmittal={showDetails}
          />
        </ComponentModal>
      )}

      {loadingMessage && <LoadingWindow message={loadingMessage} />}
    </div>
  );
};

export default TransmittalList;
