import React from "react";
import "./ConfirmationModal.css"
import exclamation from '../../assets/exclamation.svg'

const ConfirmationModal = ({title,content,onConfirm,callToActionText}) =>{

    const formatContent = (text) =>{
        return {__html: text}
    }

    return (
        <div className="modalBackground">
            <div className="modalContainer rounded-lg bg-white">
                {/* <div className="bg-primary pt-1 rounded-t-lg" style={{height:'10px'}}></div> */}
                {/* <div className="flex justify-end">
                    <button onClick={() => props.closeModal(false)} className="p-2"> X </button>
                </div> */}

                <div id="modalContent" className="p-10 pb-2 pt-2 mt-2">
                    <div className="title">
                        <h1 className="text-xl font-bold">{title}</h1>
                    </div>
                    <div className="body">
                    <div className="flex justify-center">
                        <img src={exclamation} style={{width:'70px', height: '70px'}}/>
                    </div>
                        {content.map((_content) => {
                            return <p key={_content} className="my-2 font-bold" dangerouslySetInnerHTML={formatContent(_content)}></p>
                        })}
                        
                    </div>
                    <div className="footer mt-5 flex justify-center">
                        <button className="btnCallToAction my-2" style={{minWidth:'70px', minHeight: '20px'}} onClick={() => onConfirm()}>{callToActionText}</button>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default ConfirmationModal
