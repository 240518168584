import React, { useEffect, useState } from "react";
import { useNotificationUpdate } from "../../../utils/NotificationContext";
import { GET_REQUEST } from "../../../api/APIManager";
import { FiEdit } from "react-icons/fi";
import { BsChevronLeft, BsChevronRight, BsPlusLg } from "react-icons/bs";
import {
  URL_GET_ACTIVE_DEPARTMENTS,
  URL_GET_EMPLOYEES_BY_SEARCH,
  URL_GET_EMPLOYEES_BY_STATUS_AND_DEPARTMENT_COUNT,
} from "../../../api/Endpoints";
import ComponentModal from "../../../components/Common/ComponentModal/ComponentModal";
import EmployeeEntry from "./EmployeeEntry";
import { AiOutlineReload, AiOutlineSearch } from "react-icons/ai";

const Employee = () => {
  const [employees, setEmployees] = useState(null);
  const [editEmployee, setEditEmployee] = useState(null);
  const updateNotif = useNotificationUpdate();
  const [showCreateNewEntry, setCreateNewEntry] = useState(false);
  const [departments, setDepartments] = useState(null);
  const [search, setSearch] = useState("");
  const [status, setStatus] = useState(-1);
  const [departmentId, setDepartmentId] = useState(0);
  const [totalEntryCount, setTotalEntryCount] = useState(0);
  const [pageSize, setPageSize] = useState(10);
  const [pageNumber, setPageNumber] = useState(1);


  useEffect(() => {
    getDepartments();
  }, []);

  useEffect(() => {
    handleSearch();
  }, [pageNumber, pageSize]);

 /*  const getEmployees = async () => {
    try {
      var entries = await GET_REQUEST(URL_GET_ALL_EMPLOYEES);
      setEmployees(entries);
    } catch (e) {
      console.log(e);
    }
  }; */

  const getDepartments = async () => {
    try {
      var departments = await GET_REQUEST(URL_GET_ACTIVE_DEPARTMENTS);
      setDepartments(departments);
    } catch (e) {
      console.log(e);
    }
  };

  const refresh = () => {
    setPageNumber(1);
  };

  const handleSearch = async () => {
    try {

      const payloadCount = {
        searchName: search === "" ? "0" : search,
        status: status,
        departmentId: departmentId,
      };
      var count = await GET_REQUEST(URL_GET_EMPLOYEES_BY_STATUS_AND_DEPARTMENT_COUNT, payloadCount);
      setTotalEntryCount(count);

      const payload = {
        searchName: search === "" ? "0" : search,
        pageSize: pageSize, 
        page: pageNumber,
        status: status,
        departmentId: departmentId,
      };
      //console.log(payload);
      const _requests = await GET_REQUEST(URL_GET_EMPLOYEES_BY_SEARCH, payload);
      setEmployees(_requests);
    } catch (e) {
      console.log(e);
    }
  };

  const handleEditEmployee = (employee) => {
    setEditEmployee(employee);
  };

  const handleSuccesfulEntry = () => {
    setCreateNewEntry(false);
    handleSearch();
    setEditEmployee(null);
    updateNotif("Employee Successfully Saved");
  };


  const nextPage = () => {
    if (pageNumber * pageSize < totalEntryCount)
      setPageNumber(pageNumber + 1);
  };
  const prevPage = () => {
    if (pageNumber > 1) {
      setPageNumber(pageNumber - 1);
    }
  };

  return (
    <div>
      <div className="flex justify-between">
        <h1 className="font-bold text-lg">Employee</h1>
        <div className="flex">
          <button
            className="btn btn-glow btn-primary flex items-center"
            onClick={() => setCreateNewEntry(true)}
          >
            <BsPlusLg color="white" className="mr-3" />
            Create New Employee
          </button>
        </div>
      </div>

      <div className="border-t mt-3 pt-4">
        <div className="p-3 m-0 border rounded-md border-b-0 flex items-center">
          <label className="p-2 px-5 flex items-center font-bold">Employee Name:</label>
          <input
            className="input input-text ml-2"
            onChange={(e) => setSearch(e.target.value)}
            value={search}
          />
          <label className="p-2 px-5 flex items-center font-bold">Status:</label>
          <select
            className="border rounded-md px-2 py-1 ml-2"
            style={{ minWidth: "100px" }}
            onChange={(e) => setStatus(e.target.value)}
            value={status}
          >
            <option value={-1}>All</option>
            <option value={1}>Active</option>
            <option value={0}>Inactive</option>
          </select>
          <label className="p-2 px-5 flex items-center font-bold">Department:</label>
          <select
            className="border rounded-md px-2 py-1 ml-2"
            onChange={(e) => setDepartmentId(e.target.value)}
            value={departmentId}
          >
            <option key={0} value={0}>
              All
            </option>
            {departments?.map((dep) => {
              return (
                <option key={dep.id} value={dep.id}>
                  {dep.departmentName}
                </option>
              );
            })}
          </select>
          <button
            className="border p-2 px-5 ml-3 rounded-md flex items-center"
            onClick={handleSearch}
          >
            <AiOutlineSearch size="16" className="mr-2" />
            Search
          </button>
          <button
            className="border p-2 px-5 ml-3 rounded-md flex items-center"
            onClick={refresh}
          >
            <AiOutlineReload size="16" className="mr-2" />
            Refresh
          </button>
        </div>
        <div className="font-bold text-sm mt-2 pl-2">
          Total Employees:
          <label style={{ color: "#25cc40", marginLeft: 2 }}>
           {totalEntryCount} {/* {employees?.length} */}
          </label>
        </div>
        <table
          id="tblCommons"
          className="w-full rounded-md overflow-hidden border-collapse bg-grey-custom mt-4"
        >
          <thead>
            <tr className="border">
              <th className="text-left">Employee Name</th>
              <th className="text-left">eWallet Account Info.</th>
              <th className="text-left">Department</th>
              <th className="text-left">Position</th>
              <th className="text-left">Status</th>
              <th></th>
            </tr>
          </thead>
          <tbody>
            {employees?.map((entry) => {
              return (
                <tr key={entry.id}>
                  <td>{entry.employeeName}</td>
                  <td style={{ color: "#0725b8"}}>{entry.ewalletAccountInfo}</td>
                  <td>{entry.departmentName}</td>
                  <td>{entry.position}</td>
                  <td style={{ color: (entry.status)? "#1f7517" : "red" }}>{entry.status ? "Active" : "Inactive"}</td>
                  <td>
                    <button
                      className="m-auto border px-5 py-2 rounded-md flex items-center font-bold"
                      onClick={() => {
                        handleEditEmployee(entry);
                      }}
                    >
                      <FiEdit className="mr-2" />
                      Edit
                    </button>
                  </td>
                </tr>
              );
            })}
          </tbody>
          <tfoot>
            <tr>
              <td colSpan={5}>
                Rows per page
                <select
                  className="px-2"
                  onChange={(e) => {
                    setPageSize(e.target.value);
                  }}
                >
                  <option>10</option>
                  <option>15</option>
                  <option>20</option>
                </select>
              </td>
              <td className="">
                <div className="flex items-center justify-end">
                  <span className="mr-5">
                    {pageSize * (pageNumber - 1) + 1} -{" "}
                    {pageSize * pageNumber < totalEntryCount
                      ? pageSize * pageNumber
                      : totalEntryCount}{" "}
                    of {totalEntryCount} items
                  </span>
                  <BsChevronLeft
                    size="15"
                    className="mr-2 cursor-pointer"
                    onClick={() => prevPage()}
                  />
                  <BsChevronRight
                    size="15"
                    className="cursor-pointer"
                    onClick={() => nextPage()}
                  />
                </div>
              </td>
            </tr>
          </tfoot>
        </table>
      </div>
      {showCreateNewEntry && (
        <ComponentModal>
          <EmployeeEntry
            onClose={() => setCreateNewEntry(false)}
            onConfirm={handleSuccesfulEntry}
            employee={null}
          />
        </ComponentModal>
      )}
      {editEmployee !== null && (
        <ComponentModal>
          <EmployeeEntry
            onClose={() => setEditEmployee(null)}
            onConfirm={handleSuccesfulEntry}
            employee={editEmployee}
          />
        </ComponentModal>
      )}
    </div>
  );
};

export default Employee;
