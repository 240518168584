import { setCustomerTotopup, setUserSession } from "../../utils/Common";
import { useNavigate } from "react-router-dom";
import { useState, useEffect } from "react";
import { URL_POST_LOGIN } from "../../api/Endpoints";
import ewalletUser from "../../classes/EWalletUser";
import ErrorRound from "../../assets/errorRound.svg";
import Eye from "../../assets/eye.svg";
import EyeOff from "../../assets/eye-off.svg";
import { useForm } from "react-hook-form";
import { POST_REQUEST } from "../../api/APIManager";
import { useCustomerUpdate } from "../../utils/CustomerContext";
import {
  LOAD_OPTION_LOAD_SELF,
  LOGIN_MODE_BACKOFFICE,
} from "../../utils/Constants";
import LoadingWindow from "../Common/LoadingWindow/LoadingWindow";

export default function Login2({ onLogin, loginMode }) {
  let navigate = useNavigate();

  //const UpdateCustomerToTopup = useCustomerUpdate()

  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm();

  //const [isLoading, setLoading] = useState(false)
  const [errorMessage, setError] = useState("");
  const [showPassword, setShowPassword] = useState(false);
  const [loadingMessage, setLoadingMessage] = useState(null);

  let btnSignInText = "Log In";
  let disableControl = false;

  if (loadingMessage) {
    btnSignInText = "Logging in...";
    disableControl = true;
  }

  const handleSignIn2 = async (loginDTO) => {
    setError("");
    //setLoading(true)

    try {
      setLoadingMessage("Logging In... Please Wait");
      const user = await POST_REQUEST(URL_POST_LOGIN, loginDTO);
      const _ewalletUser = new ewalletUser(
        user.id,
        user.firstname,
        user.lastname,
        user.mobileNo,
        LOAD_OPTION_LOAD_SELF,
        user.tags,
        user.email
      );

      if (loginMode && loginMode === LOGIN_MODE_BACKOFFICE) {
        if (_ewalletUser.Tags) {
          let authorized = false;
          const _tags = JSON.parse(_ewalletUser.Tags);
          for (let i = 0; i < _tags.length; i++) {
            if (_tags[i] === "TOPUP_ADMIN" || _tags[i] === "TRANSMITTAL_ADMIN" || _tags[i] === "TRANSMITTAL_ENCODER") { 
              authorized = true;
              onLogin(_ewalletUser);
              break;
            }
          }
          if (!authorized) setError("Unauthorized User");
        } else {
          setError("Unauthorized User");
        }
      } else {
        onLogin(_ewalletUser);
      }

      //UpdateCustomerToTopup(_ewalletUser)
      //setUserSession(JSON.stringify(_ewalletUser))
      //setCustomerTotopup(JSON.stringify(_ewalletUser))
      //navigate('/topup-customer')
    } catch (error) {
      //console.log(error)
      setError(error);
      //setLoading(false)
    } finally {
      setLoadingMessage(null);
    }
  };

  let passwordIcon = EyeOff;
  let passwordInputype = "password";

  if (showPassword) {
    passwordIcon = Eye;
    passwordInputype = "text";
  }

  return (
    <>
      <div className="">
        <form onSubmit={handleSubmit(handleSignIn2)}>
          <div>
            <label htmlFor="txtMobileNumber" className="font-bold text-sm">
              Mobile Number
            </label>
            <input
              disabled={disableControl}
              {...register("username", { required: true })}
              type="text"
              className="input-text w-full mt-1"
              placeholder="09XXXXXXXXX"
              maxLength="13"
            />
            {errors.username && errors.username.type === "required" && (
              <div className="text-error text-sm font-bold flex">
                <img src={ErrorRound} />
                <span className="ml-2">Username is required</span>
              </div>
            )}
          </div>
          <div>
            <label htmlFor="txtPassword" className="font-bold text-sm">
              Password
            </label>
            <div className="relative">
              <input
                {...register("password", { required: true })}
                disabled={disableControl}
                type={passwordInputype}
                className="input-text w-full mt-1"
              />
              <span
                className="absolute flex h-full p-2"
                style={{ top: 0, right: 0 }}
                onClick={() => setShowPassword(!showPassword)}
              >
                <img src={passwordIcon} style={{ width: "20px" }} />
              </span>
            </div>
            {errors.password && errors.password.type === "required" && (
              <div className="text-error text-sm font-bold flex">
                <img src={ErrorRound} />
                <span className="ml-2">Password is required</span>
              </div>
            )}
          </div>

          {errorMessage && (
            <div className="bg-red-100 p-3 rounded-md border border-solid border-red-300 text-center mt-3">
              {errorMessage}
            </div>
          )}

          <div className="text-right mt-5">
            <input
              disabled={disableControl}
              type="submit"
              className="btn btn-primary cursor-pointer"
              value={btnSignInText}
            />
          </div>
        </form>
      </div>
      {loadingMessage && <LoadingWindow message={loadingMessage} />}
    </>
  );
}
