import React, { useState } from "react";
import { useForm } from "react-hook-form";
import { GET_REQUEST, POST_REQUEST } from "../../../api/APIManager";
import {
  URL_GET_CUSTOMER_BY_MOBILE_NUMBER,
  URL_POST_NEW_JOURNAL_ENTRY,
} from "../../../api/Endpoints";
import { AiFillCloseCircle, AiFillCheckCircle } from "react-icons/ai";
import { REGEX_MOBILE_NUMBER } from "../../../utils/Constants";
import {
  formatNumber,
  formatWholeNumber,
  getBackOfficeUser,
} from "../../../utils/Common";
import LoadingWindow from "../../../components/Common/LoadingWindow/LoadingWindow";

const NewJournalEntry = ({ onClose, onConfirm }) => {
  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm();

  const boUser = getBackOfficeUser();

  const handleNewJournalEntry = async (newJournalDTO) => {
    try {
      if (!selectedCustomer) {
        setMobileNumberError("Invalid Mobile Number");
      }
      else if (amountValue <= 0){
      setErrorMessage("Value must be greater than 0");
      }
      else {
        newJournalDTO.contactNumber = selectedCustomer?.mobileNo;
        newJournalDTO.ewalletAccountId = selectedCustomer?.id;
        newJournalDTO.ewalletAccountName = selectedCustomer?.fullName;
        newJournalDTO.initiatedById = boUser ? boUser.Id : 0;
        newJournalDTO.initiatedByName =
          boUser?.FirstName + " " + boUser?.LastName;

        newJournalDTO.topupRequestAmount = amountValue.toString().replaceAll(",", "");
 
        setLoadingMessage("Processing Journal Entry");

        await POST_REQUEST(URL_POST_NEW_JOURNAL_ENTRY, newJournalDTO);
        onConfirm();
      }
    } catch (ex) {
      setErrorMessage(ex);
    } finally {
      setLoadingMessage(null);
    }
  };

  const [selectedCustomer, setCustomer] = useState(null);
  const [mobileNumberError, setMobileNumberError] = useState(null);
  const [topupAmount, setTopupAmount] = useState(0);
  const [errorMessage, setErrorMessage] = useState(null);
  const [loadingMessage, setLoadingMessage] = useState(null);
  const [amountValue, setAmount] = useState("0");

  const findCustomerByMobileNumber = async (mobileNumber) => {
    try {
      setCustomer(null);
      var formatValidation = mobileNumber.trim().match(REGEX_MOBILE_NUMBER);
      if (!formatValidation) {
        throw "Invalid Mobile Number Format";
      }

      setMobileNumberError(null);
      const payload = { MobileNo: mobileNumber };
      var result = await GET_REQUEST(
        URL_GET_CUSTOMER_BY_MOBILE_NUMBER,
        payload
      );

      setCustomer(result);
    } catch (ex) {
      setMobileNumberError(ex);
    }
  };


  const handleAmount = (amount) => {
    return formatNumber(amount);
  };


  const handleOnAmountLostFocus = () => {
    //console.log(isMouseOverAmounts)
    var numbers = amountValue.split(".");
    var amt = numbers[0] + (numbers[1] ? "." + numbers[1] : "");

    setAmount(formatNumber(amt));


  };

  const handleOnchangeAmount = (e) => {

    const re = /^\d{1,}(\.\d{0,4})?$/;///^[0-9,.\b]+$/;

    // if value is not blank, then test the regex
    let number = e.target.value.toString().replaceAll(",", "");
    if (number === '' || re.test(number)) {
      setAmount(e.target.value);
    }

  };

  return (
    <div
      className="bg-white p-5 rounded-md shadow-md"
      style={{ width: "400px" }}
    >
      <h1 className="font-bold text-lg">Create New Entry</h1>
      <form className="mt-3" onSubmit={handleSubmit(handleNewJournalEntry)}>
        {/* <input
          type="text"
          {...register("contactNumber")}
          value={selectedCustomer?.mobileNo}
        /> */}
        {/* <input
          type="hidden"
          {...register("ewalletAccountId", { valueAsNumber: true })}
          value={selectedCustomer ? selectedCustomer.id : "0"}
          onChange={() => {}}
        />
        <input
          type="hidden"
          {...register("ewalletAccountName")}
          value={selectedCustomer ? selectedCustomer.fullName : ""}
          onChange={() => {}}
        /> */}
        <div>
          <label htmlFor="txtMobileNumber" className="font-bold">
            Mobile Number
          </label>
          <div className="relative">
            <input
              id="txtMobileNumber"
              type="text"
              placeholder="09XXXXXXXXX or +639XXXXXXXXX"
              className={`input input-text w-full ${
                mobileNumberError && "border-error"
              } ${selectedCustomer && "border-success"}`}
              onChange={(e) => findCustomerByMobileNumber(e.target.value)}
              maxLength="13"
            />
            {selectedCustomer && (
              <span
                className="absolute right-1"
                style={{ transform: "translateY(30%)" }}
              >
                <AiFillCheckCircle size="20" className="text-success" />
              </span>
            )}
          </div>

          {mobileNumberError && (
            <div className="flex items-center text-error text-custom-sm ml-1">
              <AiFillCloseCircle size="12" />
              <div className="ml-1"> {mobileNumberError}</div>
            </div>
          )}
        </div>
        <div className="mt-4">
          <label htmlFor="txtTopupAmount" className="font-bold">
            Top-up Amount
          </label>
          <input
            id="txtTopupAmount"
            type="text"
            className="input input-text w-full"
            {...register("topupRequestAmount", {
              //required: "Top-up Amount is required",
              //min: { value: 1, message: "Value must be greater than 0" },
              // pattern: {
              //   value: /^[0-9,.]+$/,
              //   message: "Please enter a valid amount",
              // },
            })
          } 
          onChange={(e) => handleOnchangeAmount(e)}
          onBlur={handleOnAmountLostFocus}
          value={amountValue}
          onFocus={(e) => e.target.select()}
          />
          {errors.topupRequestAmount && (
            <span className="text-error">
              {errors.topupRequestAmount.message}
            </span>
          )}
        </div>
        <div className="mt-4">
          <label htmlFor="txtRemarks" className="font-bold">
            Purpose/Remarks
          </label>
          <textarea
            {...register("purpose", {
              required: { value: true, message: "Purpose is required" },
            })}
            id="txtRemarks"
            type="text"
            className="input input-text w-full"
          />
          {errors.purpose && (
            <span className="text-error">{errors.purpose.message}</span>
          )}
        </div>
        {errorMessage && (
          <div className="bg-red-100 p-3 rounded-md border border-solid border-red-300 text-center mt-3">
            {errorMessage}
          </div>
        )}
        <div className="flex justify-end mt-4">
          <button className="btn btn-secondary text-neutral" onClick={onClose}>
            Cancel
          </button>
          <input
            type="submit"
            value="Confirm"
            className="btn-small btn-primary cursor-pointer"
          />
        </div>
      </form>
      {loadingMessage && <LoadingWindow message={loadingMessage} />}
    </div>
  );
};

export default NewJournalEntry;
