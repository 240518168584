import React, { useState } from "react";
import { POST_REQUEST } from "../../api/APIManager";
import { URL_POST_CANCEL_TOPUP_REQUEST } from "../../api/Endpoints";
import CancelTopupRequestDTO from "../../classes/TopupRequestAgg/CancelTopupRequestDTO";
import CancelTopupRequest from "../../components/CancelTopup/CancelTopupRequest";
import SearchTopupRequest from "../../components/CancelTopup/SearchTopupRequest";
import SelectCancelReason from "../../components/CancelTopup/SelectCancelReason";
import ErrorContainer from "../../components/Common/ErrorContainer/ErrorContainer";
import PopupModal from "../../components/Common/PopupModal/PopupModal";
import ConfirmationModal from "../../components/ConfirmationModal/ConfirmationModal";
import Notification from "../../classes/NotificationAgg/Notification";
import { useNotificationUpdate } from "../../utils/NotificationContext";
import LoadingWindow from "../../components/Common/LoadingWindow/LoadingWindow";

const CancelTopup = () => {
  const [selectedTopupRequest, setSelectedTopupRequest] = useState(null);
  const [showCancelTopupPopup, setCancelTopupPopup] = useState(false);
  const [showConfirmationDialog, setConfirmationDialog] = useState(false);
  const [cancellationReason, setCancellationReason] = useState(null);
  const [errorMessage, setErrorMessage] = useState(null);
  const [loadingMessage, setLoadingMessage] = useState(null);

  const updateNotification = useNotificationUpdate();

  const handleBack = () => {
    setSelectedTopupRequest(null);
    setErrorMessage(null);
  };

  const handleSelectCancellationReason = (reason) => {
    setCancellationReason(reason);
    setCancelTopupPopup(false);
    setConfirmationDialog(true);
  };

  const setNotification = (message) => {
    updateNotification(message);
  };

  const handleCancelTopupRequest = async () => {
    try {
      setLoadingMessage("Processing Cancellation... Please Wait");
      setErrorMessage(null);
      setConfirmationDialog(false);
      const cancelRequestDTO = new CancelTopupRequestDTO(
        selectedTopupRequest.id,
        selectedTopupRequest.referenceNumber,
        cancellationReason
      );

      let response = await POST_REQUEST(
        URL_POST_CANCEL_TOPUP_REQUEST,
        cancelRequestDTO
      );
      setSelectedTopupRequest(null);
      setNotification("Top-up cancelled successfully!");
    } catch (ex) {
      setErrorMessage(ex);
    } finally {
      setLoadingMessage(null);
    }
  };

  let window = (
    <SearchTopupRequest onSelectTopupRequest={setSelectedTopupRequest} />
  );

  if (selectedTopupRequest) {
    window = (
      <CancelTopupRequest
        onBack={handleBack}
        selectedTopup={selectedTopupRequest}
        onClickCancelTopup={() => setCancelTopupPopup(true)}
      />
    );
  }

  const confirmationContent = [
    `You are about to cancel top-up for ${selectedTopupRequest?.ewalletAccountName}. Continue?`,
  ];

  return (
    <div>
      {window}
      {showCancelTopupPopup && (
        <PopupModal
          content={
            <SelectCancelReason
              onBack={() => setCancelTopupPopup(false)}
              onConfirm={handleSelectCancellationReason}
            />
          }
        />
      )}

      {errorMessage && <ErrorContainer ErrorMessage={errorMessage} />}
      {showConfirmationDialog && (
        <ConfirmationModal
          title="Cancel eWallet Top-up"
          content={confirmationContent}
          callToActionText="Confirm"
          closeModal={setConfirmationDialog}
          onConfirm={handleCancelTopupRequest}
        />
      )}
      {loadingMessage && <LoadingWindow message={loadingMessage} />}
    </div>
  );
};

export default CancelTopup;
