import axios from "axios";
import React, { useEffect, useState } from "react";
import { URL_GET_PAYMENT_METHODS } from "../../api/Endpoints";
import SelectedModeOfPayment from "../../classes/ModeOfPaymentAgg/SelectedModeOfPayment";


const SelectPayment = ({amountToLoad,loadOption,onPaymentSelected, currentValue}) =>{

    const AmountToLoad = amountToLoad

    // const [selectedMOP, setSelectedMOP] = useState(null)
    const [modeOfPayments, setModeOfPayments] = useState([])
    

    useEffect(()=>{
        //From API
        let isMounted= true

        if(loadOption){
            axios.get(URL_GET_PAYMENT_METHODS + loadOption).then(response=> {
                // let _modeOfPayments = response.data.map(mop => {
                //     return new ModeOfPayment(mop.id,mop.name,mop.status,mop.minimumAmount,mop.daysValidity,mop.priority)
                // })
                if(isMounted)
                    setModeOfPayments(response.data)
    
            }).catch(error =>{
                console.log(error)
            })
        }

        return () =>{
            isMounted = false
        }
        
    },[])

    const handleSelectPayment = (e) =>{

        //console.log( e.target.value)
        //console.log(mop)
        
        const _modeOfPayment = new SelectedModeOfPayment(e.target.value, e.target.nextElementSibling.innerHTML)

        //setSelectedMOP(_modeOfPayment)
        onPaymentSelected(_modeOfPayment)
    }

    // useEffect(()=>{
    //     setSelectedMOP(currentValue)
    // },[currentValue])

    // console.log("current value : " + currentValue?.code)
    
    return <div>
        <div className="">
            <div className="mt-3 font-bold"> Payment Method </div>
            <div className="grid grid-cols-2 mt-2" onChange={handleSelectPayment}>
                    { modeOfPayments && modeOfPayments.map((paymentMode) => {
                        return  <div className="p-1 flex items-center" key={paymentMode.code}>
                                    <input checked={currentValue && currentValue.code === paymentMode.code ? true : false} 
                                    onChange={()=>{}}
                                    id={paymentMode.name} type='radio' name="paymentMode"  value={paymentMode.code} className='accent-primary'/> 
                                    <label htmlFor={paymentMode.name} className="p-2">{paymentMode.name}</label> 
                            </div>
                    })}
            </div>
        </div>
    </div>
}

export default SelectPayment