import React from "react";
import "./AlertModal.css"

const AlertModal = (props) =>{
    return (
        <div className="modalBackground">
            <div className="modalContainer rounded-lg bg-white px-4 pb-4">
                <div className="flex justify-end">
                    <button onClick={() => props.closeModal(false)} className="p-2"> X </button>
                </div>
                <div className="title">
                    <h1 className="text-xl font-bold">{props.title}</h1>
                </div>
                <div className="body">
                    {/* {props.content.map((_content) => {
                        return <p key={_content} className="my-2">{_content}</p>
                    })} */}

                    {props.content}
                    
                </div>
                <div className="footer mt-3 text-right p-3">
                    <button className="btn btn-primary" onClick={() => props.closeModal()}>OK</button>
                </div>
            </div>
        </div>
    )
}

export default AlertModal