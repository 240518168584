import { BASE_URL } from "../../AppConfiguration";

export default class BuyerDTO {
  constructor(firstName, lastName, phone, email) {
    this.firstName = firstName;
    this.lastName = lastName;
    this.contact = new ContactInfo(phone, email);
  }
}

class ContactInfo {
  constructor(phone, email) {
    this.phone = phone;
    this.email = email;
  }
}
