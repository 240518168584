import "./App.css";
import { BrowserRouter as Router } from "react-router-dom";
import React from "react";
import { CustomerProvider } from "./utils/CustomerContext";
import {
  NotificationProvider,
  useNotification,
} from "./utils/NotificationContext";
import AlertNotificationContainer from "./components/Common/AlertNotification/AlertNotificationContainer";
import RoutePaths from "./Routes";

function App() {
  const notification = useNotification();

  return (
    //
    <Router>
      <NotificationProvider>
        <CustomerProvider>
          <RoutePaths />
        </CustomerProvider>
      </NotificationProvider>
      <AlertNotificationContainer />
    </Router>
  );
}

export default App;
