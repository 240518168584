import React, { useState, useEffect } from "react";
import LoadingOption from "../../components/LoadingOption/LoadingOption";
import LoadOnBehalf from "../../components/LoadOnBehalf/LoadOnBehalf";
import LoadYourSelf from "../../components/LoadYourself/LoadYourself";
import { useCustomerUpdate } from "../../utils/CustomerContext";
import TopupMethodButton from "../../components/LoadingOption/TopupMethodButton";
import Cancel from "../../assets/topupCancel.svg";
import PaymayaPortal from "../../components/PaymayaPortal/PaymayaPortal";
import { APP_TITLE } from "../../AppConfiguration";
import TopupMethodHeader from "../../components/LoadingOption/TopupMethodHeader";
import { useParams } from "react-router-dom";
import { URL_GET_CUSTOMER_BY_QRCODE } from "../../api/Endpoints";
import { GET_REQUEST } from "../../api/APIManager";
import EWalletUser from "../../classes/EWalletUser";
import { removeCustomerToTopup, setCustomerTotopup } from "../../utils/Common";
import { LOAD_OPTION_LOAD_SELF } from "../../utils/Constants";

const Home = ({ onSetCustomerToTopup }) => {
  const [selectedTopupMode, setSelectedTopupMode] = useState(0);

  document.title = APP_TITLE + "-Home";

  const updateCustomer = useCustomerUpdate();
  const { qrCode } = useParams();

  useEffect(() => {
    updateCustomer(null);
    //removeCustomerToTopup();

    try {
      if(qrCode !== undefined)
      {
        console.log(qrCode);
        findCustomerByQRCode(qrCode);
      }
    } catch (ex) {
      console.log(ex);
    }
  }, []);

  const handleSelectLoadOption = (SelectedOption) => {
    setSelectedTopupMode(SelectedOption);
  };


  const findCustomerByQRCode = async (qrCode) => {
    try {
      const payload = { QrCode: qrCode };
      const customer = await GET_REQUEST(
        URL_GET_CUSTOMER_BY_QRCODE,
        payload
      );
      const _ewalletUser = new EWalletUser(
        customer.id,
        customer.firstname,
        customer.lastname,
        customer.mobileNo,
        LOAD_OPTION_LOAD_SELF,
        customer.tags,
        customer.email
      );
      console.log(_ewalletUser);
      setCustomerTotopup(_ewalletUser);
      //updateCustomer(_ewalletUser);

    } catch (error) {
      console.log(error);
    } finally {
    }
  };

  let window;

  switch (selectedTopupMode) {
    case 1: {
      window = <LoadYourSelf onSelectLoadOption={handleSelectLoadOption} />;
      break;
    }
    case 2: {
      window = <LoadOnBehalf onSelectLoadOption={handleSelectLoadOption} />;
      break;
    }
    default: {
      window = <LoadingOption onSelectLoadOption={handleSelectLoadOption} />;
    }
  }

  return (
    <>
      <div className="">
        <div style={{ marginTop: "24px" }}>
          {window}

          {selectedTopupMode === 0 && (
            <>
              {/* <div>Utilities</div>
              <div>
                <a href="/cancel-topup">
                  <TopupMethodButton
                    icon={Cancel}
                    title="Cancel Top-up"
                    description="Cancel requested top-up transaction"
                  />
                </a>
              </div> */}
            </>
          )}
        </div>
      </div>
    </>
  );
};

export default Home;
