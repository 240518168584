import React from 'react'
import success from '../../assets/successRoundv2.svg'

const CardPaymentSuccess = () => {
  return (
    <div className="text-center flex flex-col items-center">
      <div className="w-full  py-2 font-bold text-lg flex flex-col items-center rounded-md">
        <img className='py-5' src={success} width='100px'/>
        <label className='pb-1' style={{ fontSize: '18px', color: '#707070'}}>eWallet Top-up Successful</label>
      </div>  
      <a href='/'><button className='btn btn-primary mt-1'>Return  to Home</button></a>
    </div>
  )
}

export default CardPaymentSuccess