import React, { useEffect, useState } from 'react'
import loading from '../../../assets/loading2.gif'

const LoadingWindow = ({message}) => {
  const [height, setHeight] = useState(0);
  useEffect(() => {
    const element = document.getElementById("root");
    setHeight(element.scrollHeight);
}, []);
  return (
    <div className='top-0 left-0 absolute flex items-center justify-center w-screen h-screen' style={{backgroundColor: 'rgba(0,0,0,0.5)', height: height}}>
        <div className='bg-white shadow-lg flex flex-col rounded-lg' style={{minWidth:'150px', maxWidth:'250px'}}>
            <img src={loading} className='p-5'/>
            <div className='text-center py-5 text-base font-bold px-2' dangerouslySetInnerHTML={{__html:message }}></div>
        </div>
    </div>
  )
}

export default LoadingWindow