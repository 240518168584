import React, { useEffect, useState } from 'react'
import ConfirmationModal from '../../../components/ConfirmationModal/ConfirmationModal';
import ComponentModal from '../../../components/Common/ComponentModal/ComponentModal';
import { BsChevronLeft, BsChevronRight, BsPlusLg } from 'react-icons/bs';
import { useNotificationUpdate } from '../../../utils/NotificationContext';
import { FiEdit } from 'react-icons/fi';
import { GET_REQUEST } from '../../../api/APIManager';
import { URL_GET_ALL_DEPARTMENTS, URL_GET_ALL_DEPARTMENTS_COUNT } from '../../../api/Endpoints';
import DepartmentEntry from './DepartmentEntry';
import { IoAlertCircleOutline } from 'react-icons/io5';
import ViewEmployees from './ViewEmployees';

const Department = () => {

    const [departments, setDepartments] = useState(null);
    const [editDepartment, setEditDepartment] = useState(null);
    const updateNotif = useNotificationUpdate();
    const [showCreateNewEntry, setCreateNewEntry] = useState(false);
    const [showDetails, setShowDetails] = useState(null);
    const [totalEntryCount, setTotalEntryCount] = useState(0);
    const [pageSize, setPageSize] = useState(10);
    const [pageNumber, setPageNumber] = useState(1);

    useEffect(() => {
      getDepartments();
    }, [pageNumber, pageSize]);
  
    const getDepartments = async () => {
      try {

        var count = await GET_REQUEST(URL_GET_ALL_DEPARTMENTS_COUNT);
        setTotalEntryCount(count);

        const payload = {
          pageSize: pageSize, 
          page: pageNumber
        };

        var entries = await GET_REQUEST(URL_GET_ALL_DEPARTMENTS, payload);
        setDepartments(entries);
      } catch (e) {
        console.log(e);
      }
    };

    const handleEditDepartment = (department) => {
      setEditDepartment(department);
    };

    const handleSuccesfulEntry = () => {
      setCreateNewEntry(false);
      getDepartments();
      setEditDepartment(null);
      updateNotif("Department Successfully Saved");
    };

    const nextPage = () => {
      if (pageNumber * pageSize < totalEntryCount)
        setPageNumber(pageNumber + 1);
    };
    const prevPage = () => {
      if (pageNumber > 1) {
        setPageNumber(pageNumber - 1);
      }
    };

  return (
    <div>
      <div className="flex justify-between">
        <h1 className="font-bold text-lg">Department</h1>
        <div className="flex">
          <button
            className="btn btn-glow btn-primary flex items-center"
            onClick={() => setCreateNewEntry(true)}
          >
            <BsPlusLg color="white" className="mr-3" />
            Create New Department
          </button>
        </div>
      </div>

      <div className="border-t mt-3 pt-4">
      <div className="font-bold text-sm mt-2 pl-2">Total Department:
      <label style={{ color: "#25cc40", marginLeft: 2 }}>
           {totalEntryCount} {/* {departments?.length} */}
          </label>
      </div>
        <table
          id="tblCommons"
          className="w-full rounded-md overflow-hidden border-collapse bg-grey-custom mt-4"
        >
          <thead>
            <tr className="border">
              <th className="text-left">Department Name</th>
              <th className="text-left">Number of Employees</th>
              <th className="text-left">Status</th>
              <th></th>
            </tr>
          </thead>
          <tbody>
            {departments?.map((entry) => {
              return (
                <tr key={entry.id}>
                  <td>{entry.departmentName}</td>
                  <td>{entry.numberOfEmployees}</td>
                  <td style={{ color: (entry.status)? "#1f7517" : "red" }}>{entry.status ? "Active" : "Inactive"}</td>
                  <td className="flex">
                    <button
                      className="m-auto border px-5 py-2 rounded-md flex items-center font-bold"
                      onClick={() => {
                        handleEditDepartment(entry);
                      }}
                    >
                      <FiEdit className="mr-2" />
                      Edit
                    </button>
                    <button
                      className="btn btn-primary flex items-center border rounded-md"
                      onClick={() => setShowDetails(entry)}
                    >
                      <IoAlertCircleOutline className="mr-2" size="16" />
                      View Employee
                    </button>
                  </td>
                 
                </tr>
              );
            })}
          </tbody>
          <tfoot>
            <tr>
              <td colSpan={3}>
                Rows per page
                <select
                  className="px-2"
                  onChange={(e) => {
                    setPageSize(e.target.value);
                  }}
                >
                  <option>10</option>
                  <option>15</option>
                  <option>20</option>
                </select>
              </td>
              <td className="">
                <div className="flex items-center justify-end">
                  <span className="mr-5">
                    {pageSize * (pageNumber - 1) + 1} -{" "}
                    {pageSize * pageNumber < totalEntryCount
                      ? pageSize * pageNumber
                      : totalEntryCount}{" "}
                    of {totalEntryCount} items
                  </span>
                  <BsChevronLeft
                    size="15"
                    className="mr-2 cursor-pointer"
                    onClick={() => prevPage()}
                  />
                  <BsChevronRight
                    size="15"
                    className="cursor-pointer"
                    onClick={() => nextPage()}
                  />
                </div>
              </td>
            </tr>
          </tfoot>
        </table>
      </div>
       {showCreateNewEntry && (
        <ComponentModal>
          <DepartmentEntry
            onClose={() => setCreateNewEntry(false)}
            onConfirm={handleSuccesfulEntry}
            department={null}
          />
        </ComponentModal>
      )}
      {editDepartment && (
            <ComponentModal>
            <DepartmentEntry
              onClose={() => setEditDepartment(null)}
              onConfirm={handleSuccesfulEntry}
              department={editDepartment}
            />
          </ComponentModal>
      )}
      {showDetails && (
            <ComponentModal>
            <ViewEmployees
              onClose={() => setShowDetails(null)}
              department={showDetails}
            />
          </ComponentModal>
      )}
    </div>
  )
}

export default Department