import React from "react";
import TopupOptionLabel from "./TopupOptionLabel";

const DisplayTopupOptions = ({ rawTopupOptions }) => {
  const topupOptions = JSON.parse(rawTopupOptions);

  let loadSelfDisplayClass = "";
  let loadBehalfDisplayClass = "";

  topupOptions.forEach((opt) => {
    switch (opt) {
      case 1: {
        loadSelfDisplayClass = "opacity-100";
        break;
      }
      case 2: {
        loadBehalfDisplayClass = "opacity-100";
        break;
      }
    }
  });

  topupOptions?.map((opt) => {
    return (
      <span key={opt}>
        <TopupOptionLabel content={opt} />
      </span>
    );
  });

  return (
    <div className="flex">
      <TopupOptionLabel content={1} customClass={loadSelfDisplayClass} />
      <TopupOptionLabel content={2} customClass={loadBehalfDisplayClass} />
    </div>
  );
};

export default DisplayTopupOptions;
