import React from "react";
import "./ConfirmationModal.css"

const ConfirmationModal = ({title,content,closeModal,onConfirm,callToActionText}) =>{

    const formatContent = (text) =>{
        return {__html: text}
    }

    return (
        <div className="modalBackground">
            <div className="modalContainer rounded-lg bg-white">
                {/* <div className="bg-primary pt-1 rounded-t-lg" style={{height:'10px'}}></div> */}
                {/* <div className="flex justify-end">
                    <button onClick={() => props.closeModal(false)} className="p-2"> X </button>
                </div> */}

                <div id="modalContent" className="p-5 mt-2">
                    <div className="title">
                        <h1 className="text-xl font-bold">{title}</h1>
                    </div>
                    <div className="body">
                        {content.map((_content) => {
                            return <p key={_content} className="my-2" dangerouslySetInnerHTML={formatContent(_content)}></p>
                        })}
                        
                    </div>
                    <div className="footer mt-3 flex justify-end">
                        <button className="btnGoBack my-2 mr-2" style={{minWidth:'80px', minHeight:'32px', border: "1px solid #F43030"}} onClick={() => closeModal(false)}>Go Back</button>
                        <button className="btnCallToAction my-2" style={{minWidth:'80px'}} onClick={() => onConfirm()}>{callToActionText}</button>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default ConfirmationModal