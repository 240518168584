import React from "react";
import TopupMethodButton from "./TopupMethodButton";
import Self from "../../assets/self.svg";
import Behalf from "../../assets/behalf.svg";

const LoadingOption = ({ onSelectLoadOption }) => {
  return (
    <>
      <h1 className="font-medium">Choose the top-up method</h1>
      <div className="flex flex-col">
        <TopupMethodButton
          onClick={() => onSelectLoadOption(1)}
          icon={Self}
          title="Top-up my account"
          description="Top-up your personal/own eWallet account"
        />
        <TopupMethodButton
          onClick={() => onSelectLoadOption(2)}
          icon={Behalf}
          title="Top-up other account"
          description="Top-up for other eWallet account"
        />
      </div>
      {/* <div className="flex flex-col sm:flex-row">
             <a href="/load-self" ><button className="bg-gray-200 rounded-md p-5 m-2 hover:bg-blue-200">Load Own Account</button></a>
             <a href="/load-on-behalf"><button className="bg-gray-200 rounded-md p-5 m-2 hover:bg-blue-200">Load On Behalf</button></a>
        </div> */}
    </>
  );
};

export default LoadingOption;
