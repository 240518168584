import React, { useState } from "react";
import { useForm } from "react-hook-form";
import { GET_REQUEST, PUT_REQUEST } from "../../../api/APIManager";
import {
  URL_GET_CUSTOMER_BY_MOBILE_NUMBER,
  URL_PUT_CUSTOMER_USERS,
} from "../../../api/Endpoints";
import { AiFillCloseCircle, AiFillCheckCircle } from "react-icons/ai";
import { REGEX_MOBILE_NUMBER } from "../../../utils/Constants";
import { getBackOfficeUser } from "../../../utils/Common";
import LoadingWindow from "../../../components/Common/LoadingWindow/LoadingWindow";
import { hide } from "@popperjs/core";

const NewUser = ({ onClose, onConfirm }) => {
  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm();

  const boUser = getBackOfficeUser();

  const [userType, setUserType] = useState("0");

  const handleNewUserEntry = async () => {
    try {
      if (!selectedCustomer) {
        setMobileNumberError("Invalid Mobile Number");
      } else {


        const payload = {
          CustomerId: selectedCustomer?.id,
          Checked: (userType === "0"),
          IsTransmittalAdmin: (userType === "1"),
          IsTransmittalEncoder: (userType === "2"),
          InitiatedBy: boUser?.FirstName + " " + boUser?.LastName + " (" + boUser?.Id + ")",
        };
        //console.log(payload)
        setLoadingMessage("Processing User Entry");
       await PUT_REQUEST(URL_PUT_CUSTOMER_USERS, payload);
        onConfirm();
      }
    } catch (ex) {
      console.log(ex)
      setErrorMessage(ex);
    } finally {
      setLoadingMessage(null);
    }
  };

  const [selectedCustomer, setCustomer] = useState(null);
  const [mobileNumberError, setMobileNumberError] = useState(null);
  const [errorMessage, setErrorMessage] = useState(null);
  const [loadingMessage, setLoadingMessage] = useState(null);

  const findCustomerByMobileNumber = async (mobileNumber) => {
    try {
      setErrorMessage(null);
      setCustomer(null);
      var formatValidation = mobileNumber.trim().match(REGEX_MOBILE_NUMBER);
      if (!formatValidation) {
        throw "Invalid Mobile Number Format";
      }

      setMobileNumberError(null);
      const payload = { MobileNo: mobileNumber };
      var result = await GET_REQUEST(
        URL_GET_CUSTOMER_BY_MOBILE_NUMBER,
        payload
      );

      setCustomer(result);
    } catch (ex) {
      setMobileNumberError(ex);
    }
  };

  return (
    <div
      className="bg-white p-5 rounded-md shadow-md"
      style={{ width: "400px" }}
    >
      <h1 className="font-bold text-lg">Create New User</h1>
      <form className="mt-5" onSubmit={handleSubmit(handleNewUserEntry)}>
        <div>
          <label htmlFor="txtMobileNumber" className="font-bold">
            Mobile Number
          </label>
          <div className="relative">
            <input
              id="txtMobileNumber"
              type="text"
              placeholder="09XXXXXXXXX or +639XXXXXXXXX"
              className={`input input-text w-full ${
                mobileNumberError && "border-error"
              } ${selectedCustomer && "border-success"}`}
              onChange={(e) => findCustomerByMobileNumber(e.target.value)}
              maxLength="13"
            />

            {selectedCustomer && (
              <span
                className="absolute right-1"
                style={{ transform: "translateY(30%)" }}
              >
                <AiFillCheckCircle size="20" className="text-success" />
              </span>
            )}

            {mobileNumberError && (
              <div className="flex items-center text-error text-custom-sm ml-1">
                <AiFillCloseCircle size="12" />
                <div className="ml-1"> {mobileNumberError}</div>
              </div>
            )}

            <div className="flex flex-col ">
              <label className="font-bold  mt-3">Full Name</label>

              <input
                className="input input-text w-full"
                type={hide}
                {...register("ewalletAccountName")}
                value={selectedCustomer ? selectedCustomer.fullName : ""}
              />
            </div>

            <div className="flex mt-3">
              <input
                className="accent-primary mr-1"
                type="radio"
                name="userType"
                value="0"
                checked={userType === "0"}
                onChange={(e) => setUserType(e.target.value)}
              />
              Topup Admin
              <input
                className="accent-primary ml-2 mr-1"
                type="radio"
                name="userType"
                value="1"
                checked={userType === "1"}
                onChange={(e) => setUserType(e.target.value)}
              />
              Transmittal Admin
              <input
                className="accent-primary ml-2 mr-1"
                type="radio"
                name="userType"
                value="2"
                checked={userType === "2"}
                onChange={(e) => setUserType(e.target.value)}
              />
              Transmittal Encoder
            </div>
          </div>
        </div>
        {errorMessage && (
          <div className="bg-red-100 p-3 rounded-md border border-solid border-red-300 text-center mt-3">
            {errorMessage}
          </div>
        )}
        <div className="flex justify-end mt-4">
          <button className="btn btn-secondary text-neutral" onClick={onClose}>
            Cancel
          </button>
          <input
            type="submit"
            value="Confirm"
            className="btn-small btn-primary cursor-pointer"
          />
        </div>
      </form>
      {loadingMessage && <LoadingWindow message={loadingMessage} />}
    </div>
  );
};

export default NewUser;
