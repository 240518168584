import React, { useEffect, useState } from "react";
import { FiArrowRight } from "react-icons/fi";
import { formatDate_journal, formatNumber } from "../../../utils/Common";
import StatusIndicator from "./TopupRequestStatusIndicator";
import "./TopupRequestManager.css";
import { GET_REQUEST, POST_REQUEST } from "../../../api/APIManager";
import { URL_GET_PAYMENT_RESPONSE, URL_POST_NEW_TOPUP_REQUEST, URL_POST_REPOST_TOPUP } from "../../../api/Endpoints";
import ConfirmationModal from "../../../components/ConfirmationModal/OKConfirmationModal";

const TopupRequestDetailView = ({ topupRequest, onClose, onRefreshMain }) => {
  let topupOptionValue = "";

  const [slideIn, setSlideIn] = useState(false);
  const [isRepostDisable, setIsRepostDisable] = useState(true);
  const [showConfirmationModal, setConfirmationModal] = useState(false);

  useEffect(() => {
    setSlideIn(true);
    if (topupRequest.status == 0)
    getPaymentResponse();
  }, [topupRequest]);

  const handleCloseWindow = () => {
    setSlideIn(false);
    onClose();
  };

  //const [paymentResponse, setPaymentResponse] = useState(null);

  const getPaymentResponse = async () => {
    try {
      const payload = { ReferenceNumber: topupRequest.referenceNumber,
        Amount: topupRequest.topupRequestAmount };
      const response = await GET_REQUEST(URL_GET_PAYMENT_RESPONSE,
        payload
      );

      if ( response != undefined)
      {
        if (response.status == 'PAYMENT_SUCCESS' && topupRequest.datePosted == null)
        setIsRepostDisable(false);
      }
    } catch (err) {
      console.log(err);
    }
  };


  let confirmationModalContent = [];

  if (topupRequest)
    confirmationModalContent.push(
      "Successfully Posted! Transaction Number <strong> " +
      topupRequest.referenceNumber +
        ". </strong>"
    );

  const handleRepost = async () => {
    try {
      setIsRepostDisable(true);
      const payload = {
        TopupRequestId: topupRequest.id
      };
      const count = await POST_REQUEST(
        URL_POST_REPOST_TOPUP + topupRequest.id
      );
      setConfirmationModal(true);
      setIsRepostDisable(true);
      onRefreshMain();
    } catch (e) {
      setIsRepostDisable(false);
      console.log(e);
    }
  };

  switch (topupRequest.topupOption) {
    case 1: {
      topupOptionValue = "Load Self";
      break;
    }
    case 2: {
      topupOptionValue = "Load on Behalf";
      break;
    }
  }

  return (
    <div
      className="w-full top-0 left-0 h-screen bg-gray-100 fixed flex justify-end overflow-hidden"
      style={{ backgroundColor: "rgba(0,0,0,0.3)" }}
    >
      <div className="grow" onClick={handleCloseWindow}></div>
      <div
        className={`bg-white flex flex-col p-3 drop-shadow-lg detail-container ${
          slideIn ? "request-detail-animation" : ""
        }`}
        onClick={() => {}}
      >
        <FiArrowRight
          className="text-primary cursor-pointer my-3"
          size="20"
          onClick={handleCloseWindow}
        />
        <div className="w-full flex items-center justify-between">
          <div className="flex flex-col">
            <span>Transaction No.</span>
            <span className="font-bold" style={{ fontSize: "1rem" }}>
              {topupRequest.referenceNumber}
            </span>
          </div>
          <div>
            <StatusIndicator
              status={topupRequest.status}
              statusText={topupRequest.statusText}
            />
          </div>
        </div>
        <div className="bg-gray-200 p-2 rounded-md my-3 font-bold">Details</div>
        <div id="details" className="flex flex-col">
          <div className="flex justify-between">
            <span>Request Date</span>
            <span>{formatDate_journal(topupRequest.dateCreated)}</span>
          </div>
          <div className="flex justify-between">
            <span>Load Option</span>
            <span>{topupOptionValue}</span>
          </div>
          <div className="flex justify-between">
            <span>Load Amount</span>
            <span>₱{formatNumber(topupRequest.topupRequestAmount)}</span>
          </div>
          <div className="flex justify-between">
            <span>Ewallet Account</span>
            <span>{topupRequest.ewalletAccountName}</span>
          </div>
          <div className="flex justify-between">
            <span>Ewallet Mobile Number</span>
            <span>{topupRequest.contactNumber}</span>
          </div>
          <div className="flex justify-between">
            <span>Payment Method</span>
            <span>{topupRequest.paymentMethod.name}</span>
          </div>

          <div className="flex justify-between">
          <span/>
          <button className="btn btn-primary cursor-pointer" disabled={isRepostDisable} onClick={handleRepost}>
            Repost
          </button>
          </div>
        </div>
      </div>

      {showConfirmationModal && (
        <ConfirmationModal
          title=""
          content={confirmationModalContent}
          onConfirm={setConfirmationModal}
          callToActionText="OK"
        />
      )}
    </div>
  );
};

export default TopupRequestDetailView;
