import React from "react";

const AppTitle = () => {
  return (
    <h1
      className="text-Montserrat text-primary font-black italic"
      style={{ fontSize: "30px", lineHeight: "24px", margin: "10px 0px" }}
    >
      EWALLET
    </h1>
  );
};

export default AppTitle;
