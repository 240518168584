import React from "react";
import "./StatisticBox.css";

const StatisticItem = ({ icon, title, count }) => {
  return (
    <div className="flex p-2 m-1 statistic-item">
      {icon}
      <div>
        {title} <br />
        <span className="font-bold">
          {`${count} ${count > 0 ? "Transactions" : "Transaction"} `}
        </span>
      </div>
    </div>
  );
};

export default StatisticItem;
