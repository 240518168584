import axios from "axios";
import { PAYMAYA_API_CONFIG_MODE } from "../AppConfiguration";

//Default API Key is for Sandbox
let api_key = "d12eb78e-30e7-4d61-8716-9e4ebbc7cb2e";
let api_secret = "a1faa037-4bf4-457e-a0d2-e31143d2b9a8";

if (PAYMAYA_API_CONFIG_MODE === "LIVE") {
  api_key = "1b292a18-9385-4052-8b0c-bef8040f79a9";
  api_secret = "b143b950-5ab3-481a-bc1b-4ef9f08816fe";
}

axios.defaults.headers.common["EWTS_API_KEY"] = api_key;
axios.defaults.headers.common["EWTS_API_SECRET"] = api_secret;

export const POST_REQUEST = async (url, payload) => {
  try {
    // console.log(payload);
    let response = await axios.post(url, payload);

    if (response.status === 200) {
      return response.data;
    }
  } catch (error) {
    handleError(error);
  }
};

export const PUT_REQUEST = async (url, payload) => {
  try {
    // console.log(payload);
    let response = await axios.put(url, payload);

    if (response.status === 200) {
      return response.data;
    }
  } catch (error) {
    handleError(error);
  }
};

export const GET_REQUEST = async (url, params) => {
  try {
    // console.log(params);
    let urlParams = "";

    if (params) {
      for (const key in params) {
        urlParams = urlParams.concat(`${key}=${params[key]}&`);
      }
    }

    let response = await axios.get(`${url}?${urlParams}`);

    if (response.status === 200) return response.data;
  } catch (error) {
    handleError(error);
  }
};

export const DOWNLOAD_DATA = async (url) => {
  try {
    // let response = await axios.get(`${url}`);

    let response = await axios
      .get(url, { responseType: "arraybuffer" })
      .then((response) => {
        if (response.status === 204) {
          return null;
        }
        let blob = new Blob([response.data], {
          type: response.headers["content-type"],
        });
        let image = URL.createObjectURL(blob);
        return image;
      });
    return response;
  } catch (error) {
    handleError(error);
  }
};

const handleError = (error) => {
  if (error.response) {
    if (error.response.status === 400) {
      throw error.response.data.toString();
    } else if (error.response.status === 500)
      throw error.response.data.toString();
    else if (error.response.status === 401) {
      throw "Unauthorized Access to Service";
    } else throw "Unknown Error has occured";
  } else {
    const _error = error.toString();
    if (_error.toUpperCase().includes("NETWORK ERROR"))
      throw "Could not connect to server";
    else throw "Something went wrong";
  }
};
