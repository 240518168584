import React,{useContext, useState} from 'react'

const CustomerContext = React.createContext()
const CustomerUpdateContext = React.createContext()

export function useCustomer() {
    return useContext(CustomerContext)
}

export function useCustomerUpdate() {
    return useContext(CustomerUpdateContext)
}

export function CustomerProvider({children}) {
    const [CustomerToTopup, setCustomerToTopup] = useState(null)

    const UpdateCustomerToTopup = (customer) =>{
        setCustomerToTopup(customer)
    }

    return(
        <CustomerContext.Provider value={CustomerToTopup}>
            <CustomerUpdateContext.Provider value={UpdateCustomerToTopup}>
                {children}
            </CustomerUpdateContext.Provider>
        </CustomerContext.Provider>
    )
}