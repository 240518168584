import React from 'react'

const PopupModal = ({content}) => {
  return (
    <div className="modalBackground">
        <div className="modalContainer rounded-lg bg-white pb-4">
            {/* <div className="bg-primary pt-1 rounded-t-lg" style={{height:'10px'}}></div> */}
            <div className="body px-5 py-3">
                {content}
            </div>
        </div>
    </div>
)
}

export default PopupModal