import TopupMethodHeader from "../LoadingOption/TopupMethodHeader";
import Self from "../../assets/self.svg";
import LeftArrow from "../../assets/leftShortArrow.svg";
import Login from "../Login/Login2";
import { useCustomerUpdate } from "../../utils/CustomerContext";
import { useNavigate } from "react-router-dom";
import { APP_TITLE } from "../../AppConfiguration";
import { getCustomerTotopup } from "../../utils/Common";
import { useEffect } from "react";

const LoadYourSelf = ({ onSelectLoadOption }) => {
  const navigate = useNavigate();
  const updateCustomer = useCustomerUpdate();

  document.title = APP_TITLE + "-Top-up My Account (Login)";


  useEffect(() => {
    try {
      const currentQRCustomer = getCustomerTotopup();
      if (currentQRCustomer !== null)
      {
        updateCustomer(currentQRCustomer);
        navigate("/topup-customer");
      }
    } catch (ex) {
      console.log(ex);
    }
  }, []);

  const handleCustomerLogin = (customer) => {
    updateCustomer(customer);
    navigate("/topup-customer");
  };

  return (
    <div>
      <button
        className="flex font-bold text-sm"
        onClick={() => onSelectLoadOption(0)}
      >
        <img src={LeftArrow} width="20px" />
        <span>Back</span>
      </button>
      <TopupMethodHeader
        icon={Self}
        title="Top-up my account"
        description="Top-up your personal/own eWallet account"
      />
      <Login onLogin={handleCustomerLogin} />
    </div>
  );
};

export default LoadYourSelf;
