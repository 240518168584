import React, { useEffect, useState } from "react";
import Header from "../../components/Header/Header2";
import { APP_VERSION } from "../../AppConfiguration";
import { Outlet } from "react-router-dom";
import AlertNotificationContainer from "../../components/Common/AlertNotification/AlertNotificationContainer";
import { URL_GET_IS_DISABLE_WEBSITE } from "../../api/Endpoints";
import { GET_REQUEST } from "../../api/APIManager";

const PublicContainer = () => {
  const [disableWebsite, setDisableWebsite] = useState(null);

  useEffect(() => {

    try {

      getIsDisableWebsite();

    } catch (ex) {
      console.log(ex);
    }
  }, []);



  const getIsDisableWebsite = async () => {
    try {
      const response = await GET_REQUEST(URL_GET_IS_DISABLE_WEBSITE);
      setDisableWebsite(response);
    } catch (error) {
      console.log(error);
    } finally {
    }
  };


  return (
    <>
         {disableWebsite && (<div className="w-full top-0 left-0 h-screen bg-white fixed flex justify-center items-center">
        <h1
          className="text-Montserrat text-primary font-black italic"
          style={{ fontSize: "30px"}}>
          We'll be back soon
        </h1>
    </div>)}
     {disableWebsite == 0 && <div className="App flex flex-col h-screen justify-center items-center">
        <div
          id="container"
          className="box-container p-10 mx-auto flex flex-col items-center"
        >
          <div
            className="absolute"
            style={{
              left: "50%",
              transform: "translateX(-50%)",
              marginTop: "-100px",
            }}
          >
            <AlertNotificationContainer />
          </div>
          <Header title={"Top-up Portal"} />
          <main className="w-full">
              <Outlet />
          </main>
          <footer className="container mx-auto text-center mt-3 p-3 text-xs text-gray-400 border-t flex justify-between">
            <span>© Iloilo Supermart Inc | {new Date().getFullYear()}</span>
            <span>{APP_VERSION}</span>
          </footer>
        </div>
      </div>}
    </>
  );
};

export default PublicContainer;
