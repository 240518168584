import React, { useEffect, useState } from "react";
import { useForm } from "react-hook-form";
import LoadingWindow from "../../../components/Common/LoadingWindow/LoadingWindow";
import {
  GET_REQUEST,
  POST_REQUEST,
  PUT_REQUEST,
} from "../../../api/APIManager";
import {
  URL_GET_ACTIVE_DEPARTMENTS,
  URL_GET_VERIFY_EWALLET_ACCOUNT,
  URL_POST_EMPLOYEE,
  URL_POST_UPDATE_EMPLOYEE,
} from "../../../api/Endpoints";
import PopupModal from "../../../components/Common/PopupModal/PopupModal";

const EmployeeEntry = ({ onClose, onConfirm, employee }) => {
  const [errorMessage, setErrorMessage] = useState(null);
  const [loadingMessage, setLoadingMessage] = useState(null);
  const [isVerify, setIsVerify] = useState(false);
  const [ewalletAccountInfo, setEwalletAccountInfo] = useState("");
  const [departments, setDepartments] = useState(null);
  const [ewalletVerify, setewalletVerify] = useState(null);

  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm({ defaultValues: employee });

  useEffect(() => {
    getDepartments();
    if (employee !== null) {
      setEwalletAccountInfo(employee.ewalletAccountInfo);
      setIsVerify(true);
    } else {
      setEwalletAccountInfo("");
      setIsVerify(false);
    }
  }, []);

  const getDepartments = async () => {
    try {
      var departments = await GET_REQUEST(URL_GET_ACTIVE_DEPARTMENTS);
      setDepartments(departments);
    } catch (e) {
      console.log(e);
    }
  };

  const validateEwalletAccountInfo = async () => {
    try {
      setErrorMessage(null);
      const payload = { EwalletAccountInfo: ewalletAccountInfo };
      var result = await GET_REQUEST(URL_GET_VERIFY_EWALLET_ACCOUNT, payload);
      setIsVerify(result);
      if (result === false) {
        setErrorMessage("Ewallet account not found!");
        setewalletVerify("eWallet info not found!");
      } else {
        setewalletVerify("eWallet info successfully verified");
      }
    } catch (ex) {
      setErrorMessage(ex);
    }
  };

  const handleEmployeeEntry = async (payload) => {
    try {
      if (isVerify === false) {
        setErrorMessage("Ewallet account not found!");
      } else {
        let _payload = payload;
        _payload.id = employee !== null ? employee.id : 0;
        _payload.status = JSON.parse(_payload.status);
        setLoadingMessage("Processing Employee Entry");

        if (employee !== null) {
          await PUT_REQUEST(URL_POST_UPDATE_EMPLOYEE, _payload);
        } else {
          await POST_REQUEST(URL_POST_EMPLOYEE, _payload);
        }
        onConfirm();
      }
    } catch (ex) {
      console.log(ex);
      setErrorMessage(ex);
    } finally {
      setLoadingMessage(null);
    }
  };

  return (
    <div
      className="bg-white p-5 rounded-md shadow-md"
      style={{ width: "400px" }}
    >
      <h1 className="font-bold text-lg">Employee Entry</h1>
      <form className="mt-5" onSubmit={handleSubmit(handleEmployeeEntry)}>
        <div className="flex flex-col">
          <label className="font-bold">First Name</label>
          <input
            className="input input-text w-full"
            type="text"
            autoComplete="off"
            {...register("firstName", {
              required: "First Name is Required",
            })}
          />
          <label className="font-bold mt-3">Middle Name</label>
          <input
            className="input input-text w-full"
            type="text"
            autoComplete="off"
            {...register("middleName", {})}
          />
          <label className="font-bold mt-3">Last Name</label>
          <input
            className="input input-text w-full"
            type="text"
            autoComplete="off"
            {...register("lastName", {
              required: "Last Name is Required",
            })}
          />
          <label className="font-bold mt-3">Position</label>
          <input
            className="input input-text w-full"
            type="text"
            autoComplete="off"
            {...register("position", {
              required: "Position is Required",
            })}
          />
          <label className="font-bold mt-3">Department</label>
          <select
            className="input input-text w-full"
            {...register("departmentId", {
              required: "Department is Required",
            })}
            //value={selectedSize}
            defaultValue={0}
          >
            <option key={0} value={0}>
              NOT SET
            </option>
            {departments?.map((dep) => {
              if (employee?.departmentId == dep.id)
                return (
                  <option key={dep.id} value={dep.id} selected>
                    {dep.departmentName}
                  </option>
                );
              else
                return (
                  <option key={dep.id} value={dep.id}>
                    {dep.departmentName}
                  </option>
                );
            })}
          </select>

          <label className="font-bold mt-3">Ewallet Account Info</label>
          <input
            style={{ color: isVerify ? "green" : "red" }}
            className={`input input-text w-full ${
              isVerify === false && "border-error"
            } ${isVerify === true && "border-success"}`}
            type="text"
            autoComplete="off"
            {...register(
              "ewalletAccountInfo",
              { onChange: (e) => setEwalletAccountInfo(e.target.value) },
              {
                required: "Ewallet Account Info is Required",
              }
            )}
          />
          <button
            className="btn-small btn-primary cursor-pointer mt-3"
            style={{ width: "40%", height: "35px", alignSelf: "end" }}
            type="button"
            onClick={validateEwalletAccountInfo}
          >
            Verify Account
          </button>

          <label className="font-bold mt-3">Status</label>
          <select
            className="input input-text w-full"
            {...register("status", {
              required: "Status is Required",
            })}
          >
            <option value={true}>Active</option>
            <option value={false}>Inactive</option>
          </select>
        </div>
        {errorMessage && (
          <div className="bg-red-100 p-3 rounded-md border border-solid border-red-300 text-center mt-3">
            {errorMessage}
          </div>
        )}
        <div className="flex justify-end mt-4">
          <button className="btn btn-secondary text-neutral" onClick={onClose}>
            Cancel
          </button>
          <input
            type="submit"
            value="Confirm"
            className="btn-small btn-primary cursor-pointer"
          />
        </div>
      </form>
      {loadingMessage && <LoadingWindow message={loadingMessage} />}
      {ewalletVerify && (
        <PopupModal
          content={
            <div style={{ minWidth: "200px", padding: "4px" }}>
              <div className="text-lg">{ewalletVerify}</div>
              <div className="footer mt-3 flex justify-center">
                <button
                  className="btnGoBack mt-4 p-2"
                  style={{
                    minWidth: "80px",
                    minHeight: "3px",
                    border: "1px solid #F43030",
                  }}
                  onClick={() => setewalletVerify(null)}
                >
                  Go Back
                </button>
              </div>
            </div>
          }
        />
      )}
    </div>
  );
};

export default EmployeeEntry;
