import React, { useState, useEffect, useRef } from "react";
import { formatDateTimeOnly_journal, formatNumber } from "../../../utils/Common";
import "./JournalEntryLoading.css";
import { formatDate_journal } from "../../../utils/Common";
import { BsChevronLeft, BsChevronRight } from "react-icons/bs";
import { AiFillPrinter, AiOutlineReload, AiOutlineSearch } from "react-icons/ai";
import ComponentModal from "../../../components/Common/ComponentModal/ComponentModal";
import NewJournalEntry from "./NewJournalEntry";
import { GET_REQUEST } from "../../../api/APIManager";
import {
  URL_GET_ALL_JOURNAL_ENTRY,
  URL_GET_ALL_JOURNAL_ENTRY_COUNT,
} from "../../../api/Endpoints";
import { BsPlusLg } from "react-icons/bs";
import AlertNotificationContainer from "../../../components/Common/AlertNotification/AlertNotificationContainer";
import { useNotificationUpdate } from "../../../utils/NotificationContext";
import PrintJournalEntryLoading from "./PrintJournalEntryLoading";
import ConfirmationModal from "../../../components/ConfirmationModal/OKConfirmationModal";
import PopupModal from "../../../components/Common/PopupModal/PopupModal";
import SelectDates from "../../../components/SelectDates/SelectDates";
import { end } from "@popperjs/core";
import { IoAlertCircleOutline } from "react-icons/io5";
import { JournalEntryDetailView } from "./JournalEntryDetailView";

const JournalEntryLoading = () => {
  const [journalEntries, setJournalEntries] = useState(null);
  const [showCreateNewEntry, setCreateNewEntry] = useState(false);
  const [totalJournalEntryCount, setTotalJournalEntryCount] = useState(0);
  const [pageSize, setPageSize] = useState(10);
  const [pageNumber, setPageNumber] = useState(1);
  const [printPage, showPrintPage] = useState(false);

  const updateNotification = useNotificationUpdate();
  const [selectedSearch, setSelectedSearch] = useState(0);
  const [search, setSearch] = useState("");
  const isSeach = useRef(false);
  const [sortMode, setSortMode] = useState("None");
  const [isReload, setIsReload] = useState(false);
  const [currentPayload, setPayload] = useState(null);
  const [showDetails, setShowDetails] = useState(null);

  useEffect(() => {
    refresh();
  }, [pageSize, pageNumber, isReload, sortMode]);

  const getTotalJournalEntryCount = async () => {
    try {
      var payload = { searchBy: selectedSearch, searchName: (search === "" ? "0" : search) };
      var count = await GET_REQUEST(URL_GET_ALL_JOURNAL_ENTRY_COUNT, payload);
      setTotalJournalEntryCount(count);

      if (isSeach.current && count === 0)
      setConfirmationModal(true);

    } catch (e) {}
  };

  const getJournalEntries = async () => {
    try {
      var payload = { pageSize: pageSize, page: pageNumber, 
        searchBy: selectedSearch, searchName: (search === "" ? "0" : search),
        sortBy: "DateCreated", sortMode: sortMode };

      var entries = await GET_REQUEST(URL_GET_ALL_JOURNAL_ENTRY, payload);
      setJournalEntries(entries);
      setPayload(payload);
    } catch (e) {
      console.log(e);
    }
  };

  const refresh = () => {
    isSeach.current = false;
    setIsReload(false);
    getTotalJournalEntryCount();
    getJournalEntries();
  };
  const handleSearch = () => {
    isSeach.current = true;
    getTotalJournalEntryCount();
    getJournalEntries();
  };
  const reload = () => {
    isSeach.current = false;
    setSelectedSearch(0);
    setSearch("");
    setPageNumber(1);
    setIsReload(true);
  };

  const nextPage = () => {
    if (pageNumber * pageSize < totalJournalEntryCount)
      setPageNumber(pageNumber + 1);
  };
  const prevPage = () => {
    if (pageNumber > 1) {
      setPageNumber(pageNumber - 1);
    }
  };

  const handleSuccesfulEntry = () => {
    updateNotification("Journal entry created successfully");
    setCreateNewEntry(false);
    refresh();
  };


  const [showReportOption, setReportOption] = useState(false);
  const [startDate, setStartDate] = useState(new Date());
  const [endDate, setEndDate] = useState(new Date());
  const [isCheck, setIscheck] = useState(false);
  const [direction, setDirection] = React.useState('None')
  const arrows = { None: '↕︎', Asc: '↓', Desc: '↑' }
  const arrow = arrows[direction];

  const handleSortClick = event => {
    const sortDir = direction === 'Desc' ? 'Asc' : 'Desc'
    setDirection(sortDir)
    setSortMode(sortDir);

  }

  const handleGenerateReport = (isCheck, startDate, endDate) => {
    setIscheck(isCheck);
    setStartDate(startDate);
    setEndDate(endDate);
    
    showPrintPage(true);
  };


  const [showConfirmationModal, setConfirmationModal] = useState(false);
  let confirmationModalContent = [];

    confirmationModalContent.push(
      "No exact matches found!"
    );


    const handleCloseDetail = () => {
      setTimeout(() => {
        setShowDetails(null);
      }, 400);
    };

  return (
    <div>
      {printPage && (
        <PrintJournalEntryLoading 
        showPrintReport={showPrintPage}   
        journalPayload={currentPayload}
        isCheck={isCheck}
        startDate={startDate}
        endDate={endDate} />
      )}
      <div
        className="absolute"
        style={{
          left: "50%",
          transform: "translateX(-50%)",
          marginTop: "-40px",
        }}
      >
      </div>
      <div className="flex justify-between">
        <h1 className="font-bold text-lg">Journal Entry Data View</h1>
        <div className="flex">
          <button
            className="btn border font-bold flex items-center mr-4"
            onClick={() => setReportOption(true)}
          >
            <AiFillPrinter className="mr-2" size="15" />
            Generate Report
          </button>
          <button
            className="btn btn-glow btn-primary flex items-center"
            onClick={() => setCreateNewEntry(true)}
          >
            {" "}
            <BsPlusLg color="white" className="mr-3" />
            Create New Entry
          </button>
        </div>
      </div>
      <div className="border-t mt-3 pt-4">
        <div className="p-3 m-0 border rounded-md border-b-0 flex items-center">
                <div>
                <input className="ml-2" type="radio" value="1" name="searchOption" 
                  onChange={(e) => setSelectedSearch(e.target.value)} 
                  checked={selectedSearch === "1"}/> Transaction Number
                  <input className="ml-2" type="radio" value="2" name="searchOption" 
                  onChange={(e) => setSelectedSearch(e.target.value)} 
                  checked={selectedSearch === "2"}/> Ewallet Mobile Number
                  <input className="ml-2" type="radio" value="3" name="searchOption" 
                  onChange={(e) => setSelectedSearch(e.target.value)} 
                  checked={selectedSearch === "3"}/> Load Amount
                </div>

                <input className="input input-text ml-2" 
                onChange={(e) => setSearch(e.target.value)}
                disabled = {selectedSearch === 0}
                value={search}
                />

                <button
                className="border p-2 px-5 ml-3 rounded-md flex items-center"
                onClick={handleSearch}
                >
                <AiOutlineSearch size="16" className="mr-2" />
                  Search
                </button>
                <button
                className="border p-2 px-5 ml-3 rounded-md flex items-center"
                onClick={reload}>
                <AiOutlineReload size="16" className="mr-2" />
                Refresh
              </button>
        </div>

        <table
          id="journal-entry-table"
          className="w-full rounded-md overflow-hidden border-collapse bg-grey-custom"
        >
          <thead>
            <tr className="bg-grey-custom border">
            <th>Transaction No.</th>
              <th>Mobile No.</th>
              <th>Amount</th>
              <th>
                  <button className="font-bold" onClick={handleSortClick}>
                      Date {arrow}
                  </button>
              </th>
              <th>Date Posted</th>
              <th>Purpose/Remarks</th>
            </tr>
          </thead>

          <tbody>
            {journalEntries?.map((entry) => {
              return (
                <tr key={entry.id}>
                  <td>{entry.referenceNumber}</td>
                  <td>{entry.contactNumber}</td>
                  <td>₱{formatNumber(entry.topupRequestAmount)}</td>
                  <td>{formatDate_journal(entry.dateCreated)}
                  <span style={{color: 'red'}}>{ formatDateTimeOnly_journal(entry.dateCreated) }</span></td>
                  <td>{formatDate_journal(entry.datePosted)}
                  <span style={{color: 'red'}}>{ formatDateTimeOnly_journal(entry.datePosted) }</span></td>
                  <td>{entry.remarks}</td>
                  <td>
                    <button
                      className="flex items-center border p-2 m-2 rounded-md"
                      onClick={() => setShowDetails(entry)}
                    >
                      <IoAlertCircleOutline className="mr-2" size="16" /> View Invoice
                    </button>
                  </td>
                </tr>
              );
            })}
          </tbody>
          <tfoot>
            <tr>
              <td colSpan={6}>
                Rows per page
                <select
                  className="px-2"
                  onChange={(e) => {
                    setPageSize(e.target.value);
                  }}
                >
                  <option>10</option>
                  <option>15</option>
                  <option>20</option>
                </select>
              </td>
              {/* <td colSpan={1}>
                Sort Date By
                <select
                  className="px-2 ml-2"
                  onChange={(e) => {
                    setSortMode(e.target.value);
                  }}
                >
                  <option>None</option>
                  <option>Desc</option>
                  <option>Asc</option>
                </select>
              </td> */}
              <td className="">
                <div className="flex items-center justify-end">
                  <span className="mr-5">
                    {pageSize * (pageNumber - 1) + 1} -{" "}
                    {pageSize * pageNumber < totalJournalEntryCount
                      ? pageSize * pageNumber
                      : totalJournalEntryCount}{" "}
                    of {totalJournalEntryCount} items
                  </span>
                  <BsChevronLeft
                    size="15"
                    className="mr-2 cursor-pointer"
                    onClick={() => prevPage()}
                  />
                  <BsChevronRight
                    size="15"
                    className="cursor-pointer"
                    onClick={() => nextPage()}
                  />
                </div>
              </td>
            </tr>
          </tfoot>
        </table>
      </div>
      {showCreateNewEntry && (
        <ComponentModal>
          <NewJournalEntry
            onClose={() => setCreateNewEntry(false)}
            onConfirm={() => handleSuccesfulEntry()}
          />
        </ComponentModal>
      )}

      {showConfirmationModal && (
        <ConfirmationModal
          title=""
          content={confirmationModalContent}
          onConfirm={setConfirmationModal}
          callToActionText="OK"
          />
          )}
       {showReportOption && (
        <PopupModal
          content={
            <SelectDates
              onBack={() => setReportOption(false)}
              onConfirm={handleGenerateReport}
            />
          }
        />
      )}
        {showDetails && (
        <JournalEntryDetailView
          journalEntry={showDetails}
          onClose={handleCloseDetail}
        />
      )}
    </div>
  );
};

export default JournalEntryLoading;
