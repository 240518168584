import paymaya from "paymaya-js-sdk";
import { PAYMAYA_API_CONFIG_MODE } from "../AppConfiguration";

let apiKey = "";

if (PAYMAYA_API_CONFIG_MODE === "LIVE") {
  apiKey = "pk-Xtw6yT2S2XGI1mYpDw7XojIb1rYaAqwrCtyJPOVgmP3";
} else {
  apiKey = "pk-UGOrgqp80Gv19b4JD8wP0MF1ZUMQmPVQjbURqQqRvf1";
}

export const initialize = () => {
  if (PAYMAYA_API_CONFIG_MODE === "LIVE") paymaya.init(apiKey, false);
  else paymaya.init(apiKey, true);
};

export const createCheckout = (checkoutObject) => {
  let _checkoutObject = checkoutObject;
  let itemName = "eWallet Sandbox Top-up";

  if (PAYMAYA_API_CONFIG_MODE === "LIVE") {
    itemName = "eWallet Top-up";
  }

  const items = [{ name: itemName, totalAmount: checkoutObject.totalAmount }];

  _checkoutObject.items = items;

  paymaya.createCheckout(_checkoutObject);
};
