import React, { useState } from 'react'
import { useForm } from "react-hook-form";
import LoadingWindow from '../../../components/Common/LoadingWindow/LoadingWindow';
import { BsSquare, BsCheckSquareFill } from "react-icons/bs";
import { URL_POST_DEPARTMENT, URL_POST_UPDATE_DEPARTMENT } from '../../../api/Endpoints';
import { POST_REQUEST, PUT_REQUEST } from '../../../api/APIManager';

const DepartmentEntry = ({ onClose, onConfirm, department }) => {
    const {
        register,
        handleSubmit,
        formState: {errors},
      } = useForm({ defaultValues: department });


      const [errorMessage, setErrorMessage] = useState(null);
      const [loadingMessage, setLoadingMessage] = useState(null);

      const handleDepartmentEntry = async (payload) => {
        try {
            let _payload = payload;
            _payload.id = (department !== null) ? department.id : 0;
            _payload.status = JSON.parse(_payload.status);
            setLoadingMessage("Processing Department Entry");

          if (department !== null) {
            await PUT_REQUEST(URL_POST_UPDATE_DEPARTMENT, _payload);
          } else {
            await POST_REQUEST(URL_POST_DEPARTMENT, _payload);
          }
          onConfirm();
        } catch (ex) {
          console.log(ex)
          setErrorMessage(ex);
        } finally {
          setLoadingMessage(null);
        }
    };

  return (
    <div
    className="bg-white p-5 rounded-md shadow-md"
    style={{ width: "400px" }}
  >
    <h1 className="font-bold text-lg">Department Entry</h1>
    <form className="mt-5" onSubmit={handleSubmit(handleDepartmentEntry)}> 
      <div className="flex flex-col">
        <label className="font-bold">
          Department Name
        </label>
            <input
              className="input input-text w-full"
              type="text" autoComplete='off'
              {...register("departmentName", {
                required: "Department Name is Required",
              })}
            />

            <label className="font-bold mt-3">Status</label>
            <select
              className="input input-text w-full"
              {...register("status", {
                required: "Status is Required",
              })}
            >
              <option value={true}>Active</option>
              <option value={false}>Inactive</option>
            </select>
      </div>
      {errorMessage && (
        <div className="bg-red-100 p-3 rounded-md border border-solid border-red-300 text-center mt-3">
          {errorMessage}
        </div>
      )}
      <div className="flex justify-end mt-4">
        <button className="btn btn-secondary text-neutral" onClick={onClose}>
          Cancel
        </button>
        <input
          type="submit"
          value="Confirm"
          className="btn-small btn-primary cursor-pointer"
        />
      </div>
    </form>
    {loadingMessage && <LoadingWindow message={loadingMessage} />}
  </div>
  )
}

export default DepartmentEntry