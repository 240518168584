import React, { useState } from "react";
import Login2 from "../../../components/Login/Login2";
import {
  LOGIN_MODE_BACKOFFICE,
  STORAGE_ADMIN_USER,
} from "../../../utils/Constants";
import { useNavigate } from "react-router-dom";
import { setBackOfficeUser } from "../../../utils/Common";
import Header from "../../../components/Header/Header2";
import { APP_TITLE, APP_VERSION } from "../../../AppConfiguration";

const BackOfficeLogin = () => {
  const navigate = useNavigate();

  document.title = APP_TITLE + "-Backoffice Login";

  const handleManagementLogin = (customer) => {
    // console.log(customer);
    if (customer && customer.Tags) {
      // console.log(customer)

      setBackOfficeUser(customer);
      navigate("/BackOffice/Dashboard");
    }
  };

  return (
    <>
      <div className="App flex flex-col h-screen justify-center items-center">
        <div
          id="container"
          className="box-container p-10 mx-auto flex flex-col items-center"
        >
          <Header title={"Back Office"} />
          <main className="w-full">
            <div>
              <h2 className="text-sm font-bold mt-3">Login Account</h2>
              <p className="mb-3 text-gray-400">
                Enter your details to continue
              </p>
              <Login2
                onLogin={handleManagementLogin}
                loginMode={LOGIN_MODE_BACKOFFICE}
              />
            </div>
          </main>
          <footer className="container mx-auto text-center mt-3 p-3 text-xs text-gray-400 border-t flex justify-between">
            <span>© Iloilo Supermart Inc | {new Date().getFullYear()}</span>
            <span>{APP_VERSION}</span>
          </footer>
        </div>
      </div>
    </>
  );
};

export default BackOfficeLogin;
