import React from "react";
import "./AlertNotification.css";
import SuccessRound from "../../../assets/successRound.svg";
import { useNotificationUpdate } from "../../../utils/NotificationContext";
import Notification from "../../../classes/NotificationAgg/Notification";

const AlertNotification = ({ Message }) => {
  const updateNotification = useNotificationUpdate();

  const closeNotif = () => {
    updateNotification(null);
  };

  return (
    <div
      className="bg-success text-success flex rounded-sm drop-shadow items-center"
      style={{ width: "350px" }}
    >
      <span className="p-2">
        <img src={SuccessRound} />
      </span>
      <span className="grow ml-1">{Message}</span>
      <span className="p-2 cursor-pointer" onClick={closeNotif}>
        X
      </span>
    </div>
  );
};

export default AlertNotification;
