import React, { useState } from "react";
import ReactDatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import { BsSquare, BsCheckSquareFill } from "react-icons/bs";

const SelectDates = ({ onConfirm, onBack }) => {
  
    const [startDate, setStartDate] = useState(new Date());
    const [endDate, setEndDate] = useState(new Date());
    const [isCheck, setIscheck] = useState(false);
    
    const handleChange = () => {
        setIscheck(!isCheck);
      };

    const handleConfirm = () => {

        onConfirm(isCheck, startDate, endDate);
      };

  return (

    
<div style={{ minWidth: "350px" }} >
 <div className="font-bold text-lg">Report Option</div>




    <label className="mt-5 font-bold flex">
        <input  type="checkbox"
              checked={isCheck}
              onChange={handleChange}
              style={{ opacity: 0}}
            />
            {!isCheck && <BsSquare className="mr-1" fill={"grey"} size={15} />}
            {isCheck && <BsCheckSquareFill className="mr-1" fill={"red"} size={15} />}
        Use date range
    </label>

      <div className="p-3 m-0 flex">   
        <label className="font-bold" style={{ minWidth: 70 }}>
          Date From
          </label>
        <ReactDatePicker className="date-picker" disabled = {!isCheck} selected={startDate} onChange={(date) => setStartDate(date)} />
    
        <label className="font-bold" style={{ minWidth: 70 }}>
            Date To
        </label>
        <ReactDatePicker className="date-picker" disabled = {!isCheck} selected={endDate} onChange={(date) => setEndDate(date)} />
      </div>
      
       <div className="footer mt-3 flex justify-end">
        <button className="btnGoBack mr-2" style={{minWidth:'80px', minHeight:'3px', border: "1px solid #F43030"}}
         onClick={onBack}>Go Back</button>
        <button
          className="btn btn-primary"
          onClick={handleConfirm}
        >
          Confirm
        </button>
      </div>
    </div>
  );
};

export default SelectDates;