import React from "react";
import LeftArrow from "../../assets/leftShortArrow.svg";
import behalf from "../../assets/behalf.svg";
import { formatNumber } from "../../utils/Common";
import LoadingWindow from "../Common/LoadingWindow/LoadingWindow";

const CancelTopupRequest = ({ onBack, selectedTopup, onClickCancelTopup }) => {
  let statusClass = "";

  switch (selectedTopup.status) {
    case 1: {
      statusClass = "status-bg-green";
      break;
    }

    case 2: {
      statusClass = "status-bg-blue";
      break;
    }

    case 3: {
      statusClass = "status-bg-red";
      break;
    }

    case 4: {
      statusClass = "status-bg-red";
      break;
    }

    case 5: {
      statusClass = "status-bg-orange";
      break;
    }
  }

  return (
    <div>
      <button className="flex font-bold text-sm my-5" onClick={() => onBack()}>
        <img src={LeftArrow} width="20px" />
        <span>Back</span>
      </button>
      <div className="flex items-center">
        <div
          className="bg-primary rounded-full py-3 px-2 mx-3 mt-2 self-start"
          style={{ height: "fit-content" }}
        >
          <img src={behalf} width="30px" height="30px" />
        </div>
        <div className="flex flex-col flex-grow">
          <span className="font-bold">{selectedTopup?.referenceNumber}</span>
          <span>{selectedTopup?.ewalletAccountName}</span>
          <span>
            {selectedTopup?.contactNumber} <span className="mx-2">&#9679;</span>{" "}
            ₱{formatNumber(selectedTopup?.topupRequestAmount)}
          </span>
          <div className="py-2">
            Status:{" "}
            <span className={`status ${statusClass}`}>
              {selectedTopup.statusText}
            </span>
          </div>
        </div>
        <div className="ml-20">
          <button
            className="rounded-md px-2 py-1 btn-primary"
            onClick={onClickCancelTopup}
          >
            Cancel Top-up
          </button>
        </div>
      </div>
    </div>
  );
};

export default CancelTopupRequest;
