import React, { useRef, useEffect, useState } from "react";
import NewWindow from "react-new-window";
import { GET_REQUEST, POST_REQUEST } from "../../../api/APIManager";
import { URL_GET_TOPUP_REQUESTS_BY_DATEFROM_DATETO, URL_POST_TOPUP_REQUESTS_RETRIEVE } from "../../../api/Endpoints";
import { useReactToPrint } from "react-to-print";
import {
  formatDateTimeCommon,
  formatDate_journal,
  formatNumber,
  formatShortDate,
  formatWholeNumber,
  getTopupOptionValue,
} from "../../../utils/Common";
import "./PrintTopupRequest.css";
import StatusIndicator from "./TopupRequestStatusIndicator";

const PrintTopupRequest = ({ showPrintReport, topupRequestPayload, isCheck, startDate, endDate  }) => {
  const [topupRequests, setTopupRequests] = useState(null);
  const [loadingMessage, setLoadingMessage] = useState(null);
  let total = 0;
  let totalTransCount = 0;

  const componentRef = useRef();
  const handlePrint = useReactToPrint({
    content: () => componentRef.current,
    documentTitle:'TopupReport_Date_' + formatShortDate(new Date()),
  });

  // const handlePrint = () => {
  //   // let printWindow = window.open();
  //   console.log(componentRef.current);
  //   var printWindow = window.open();
  //   printWindow.document.write(componentRef.current);
  //   printWindow.document.close();
  //   printWindow.print();
  // };

  useEffect(() => {
    getTopupRequests();
  }, []);

  const getTopupRequests = async () => {
    try {
      setLoadingMessage("Generating Report... Please Wait");
      let payload = topupRequestPayload;
      // console.log(payload);
      payload.pageSize = 0;

      if (isCheck)
      {
        payload = { dateFrom: formatDate_journal(startDate) , dateTo : formatDate_journal(endDate) };
        const _requestsDateFromDateTo = await GET_REQUEST(URL_GET_TOPUP_REQUESTS_BY_DATEFROM_DATETO, payload);
        setTopupRequests(_requestsDateFromDateTo);
      }
      else
      {
      const _requests = await POST_REQUEST(
        URL_POST_TOPUP_REQUESTS_RETRIEVE,
        payload
      );
      setTopupRequests(_requests);

      }

      setLoadingMessage(null);
      handlePrint();
      showPrintReport(false);
    } catch (e) {
      console.log(e);
    } finally {
    }
  };

  return (
    <NewWindow onUnload={() => showPrintReport(false)} center="parent">
      <button
        className="font-cold border rounded-md px-4 py-2"
        onClick={handlePrint}
      >
        Print Report
      </button>
      <div ref={componentRef} className="m-5 p-2">
        <table
          id="tblPrintTopupRequests"
          className="w-full rounded-md overflow-hidden border-collapse"
        >
          <thead>
            <tr>
              <th colSpan={8}>
                <h1 className="font-bold text-lg">Ewallet Topup System</h1>
                <h2 className="text-sm">Top-up Requests Report</h2>
                {!isCheck && (<h2 className="text-sm">All Time</h2>)}
                {isCheck && (<h2 className="text-sm">
                   {formatShortDate(startDate)} - {formatShortDate(endDate)}
                </h2>)}
              </th>
            </tr>
            <tr className="border">
              <th className="text-left">Transaction No.</th>
              <th className="text-left">Payment Method</th>
              <th className="text-left">Request Date</th>
              <th className="text-left">Fulfilled Date</th>
              <th className="text-right">Load Amount</th>
              <th className="text-center">Status</th>
              <th className="text-left">Load Option</th>
              <th className="text-left">Ewallet Mobile Number</th>
            </tr>
          </thead>
          <tbody>
            {topupRequests?.map((topup) => {
               total = (total + topup.topupRequestAmount);
               totalTransCount = (totalTransCount + 1);
              return (
                <tr key={topup.id}>
                  <td>{topup.referenceNumber}</td>
                  <td>{topup.paymentMethod.name}</td>
                  <td>{formatDate_journal(topup.dateCreated)}</td>
                  <td>
                    {topup.datePosted
                      ? formatDate_journal(topup.datePosted)
                      : ""}
                  </td>
                  <td className="text-right">
                    ₱{formatNumber(topup.topupRequestAmount)}
                  </td>
                  <td className="text-center">
                    <StatusIndicator
                      status={topup.status}
                      statusText={topup.statusText}
                    />
                  </td>
                  <td>{getTopupOptionValue(topup.topupOption)}</td>
                  <td>{topup.contactNumber}</td>
                </tr>
              );
            })}
          </tbody>
          <tfoot>
            <tr>
              {/* <td className="pl-3" colSpan={8}>
                Date/Time Printed: {formatDateTimeCommon(new Date())}
              </td> */}
            </tr>
          </tfoot>
        </table>

<div className="p-3 flex justify-between">
<div className="rtp-amount">Total Transaction Count:  {totalTransCount} </div>
        <div className="rtp-amount ml-2">Total Amount:  ₱{formatNumber(total)}</div>
</div>

        <div className="px-3 flex justify-between">
        <div className=""> Date/Time Printed: {formatDateTimeCommon(new Date())}</div>
        </div>

        <div className="text-center mt-5">*** Nothing Follows ***</div>
        {loadingMessage && <div>{loadingMessage}</div>}
      </div>
    </NewWindow>
  );
};

export default PrintTopupRequest;
