import React, { useRef, useEffect, useState } from "react";
import NewWindow from "react-new-window";
import "./PrintJournalEntryLoading.css";
import { useReactToPrint } from "react-to-print";
import { URL_GET_ALL_JOURNAL_ENTRY, URL_GET_JOURNAL_ENTRY_BY_DATEFROM_DATETO } from "../../../api/Endpoints";
import { GET_REQUEST } from "../../../api/APIManager";
import {
  formatDate_journal,
  formatNumber,
  formatDateTimeCommon,
  formatWholeNumber,
  formatShortDate,
} from "../../../utils/Common";

const PrintJournalEntryLoading = ({ showPrintReport, journalPayload, isCheck, startDate, endDate }) => {
  const [journalEntries, setJournalEntries] = useState(null);
  const [loadingMessage, setLoadingMessage] = useState(null);
  let total = 0;
  let totalTransCount = 0;

  const componentRef = useRef();
  const handlePrint = useReactToPrint({
    content: () => componentRef.current,
    documentTitle:'JournalReport_Date_' + formatShortDate(new Date()),
    // pageStyle: "@page { size: auto;  margin: 10mm; } @media print { body { -webkit-print-color-adjust: exact; } }", // remove date/time from top
  });

  useEffect(() => {
    getJournalEntries();
  }, []);

  const getJournalEntries = async () => {
    try {
      //const payload = { pageSize: 0 };
      let payload = journalPayload;
      payload.pageSize = 0;
      setLoadingMessage("Generating Report... Please Wait");

      if (isCheck)
      {
        payload = { dateFrom: formatDate_journal(startDate) , dateTo : formatDate_journal(endDate) };
        const _requestsDateFromDateTo = await GET_REQUEST(URL_GET_JOURNAL_ENTRY_BY_DATEFROM_DATETO, payload);
        setJournalEntries(_requestsDateFromDateTo);
      }
      else
      {
        const _requests = await GET_REQUEST(URL_GET_ALL_JOURNAL_ENTRY, payload);
        setJournalEntries(_requests);
      }
  
      setLoadingMessage(null);
      handlePrint();
      showPrintReport(false);
    } catch (e) {
      setLoadingMessage(null);
      console.log(e);
    } finally {
    }
  };

  return (
    <NewWindow onUnload={() => showPrintReport(false)} center="parent">
      <button
        className="font-cold border rounded-md px-4 py-2"
        onClick={handlePrint}
      >
        Print Report
      </button>
      <div ref={componentRef} className="m-5 p-2">
        <table
          id="tblJournalEntries"
          className="w-full rounded-md overflow-hidden border-collapse"
        >
          <thead>
            <tr>
              <th colSpan={8}>
                <h1 className="font-bold text-lg">Ewallet Topup System</h1>
                <h2 className="text-sm">Journal Entries Report</h2>
                {!isCheck && (<h2 className="text-sm">All Time</h2>)}
                {isCheck && (<h2 className="text-sm">
                   {formatShortDate(startDate)} - {formatShortDate(endDate)}
                </h2>)}
              </th>
            </tr>
            <tr className="border">
              <th className="text-left">Mobile Number</th>
              <th className="text-right">Amount</th>
              <th className="text-left">Date Initiated</th>
              <th className="text-left">Purpose/Remarks</th>
              <th className="text-left">Initiated By</th>
            </tr>
          </thead>
          <tbody>
            {journalEntries?.map((journal) => { 
              total = (total + journal.topupRequestAmount);
              totalTransCount = (totalTransCount + 1);
              return (
                <tr key={journal.id}>
                  <td>{journal.contactNumber}</td>
                  <td className="text-right">
                    ₱{formatNumber(journal.topupRequestAmount)}
                  </td>
                  <td>{formatDate_journal(journal.datePosted)}</td>
                  <td>{journal.remarks}</td>
                  <td>
                    {journal.initiatedByName ? journal.initiatedByName : "-"}
                  </td>
                </tr>
              );
            })}
          </tbody>
          <tfoot>
            <tr>
              {/* <td colSpan={6}>
                Date/Time Printed: {formatDateTimeCommon(new Date())}
              </td> */}
              {/* <td className="rtp-amount" colSpan={2}>
                Total Amount:  ₱{formatWholeNumber(total)}
              </td> */}
            </tr>
          </tfoot>
        </table>

        <div className="p-3 flex justify-between">
            <div className="rtp-amount">Total Transaction Count:  {totalTransCount} </div>
            <div className="rtp-amount ml-2">Total Amount:  ₱{formatNumber(total)}</div>
            
        </div>

        {/* <div className="rtp-amount text-right p-5">Total Amount:  ₱{formatWholeNumber(total)}</div> */}
        <div className="px-3 flex justify-between">
        <div className=""> Date/Time Printed: {formatDateTimeCommon(new Date())}</div>
        </div>
        <div className="text-center mt-5">*** Nothing Follows ***</div>
        {loadingMessage && <div>{loadingMessage}</div>}
      </div>
    </NewWindow>
  );
};

export default PrintJournalEntryLoading;
