import React, { useEffect, useRef, useState } from 'react'
import "./PrintTransmittal.css";
import { formatDateTimeCommon, formatNumber, formatShortDate } from '../../../utils/Common';
import { useReactToPrint } from 'react-to-print';
import { URL_GET_TRANSMITTAL_DETAILS_BY_HEADER_ID } from '../../../api/Endpoints';
import { GET_REQUEST } from '../../../api/APIManager';
import NewWindow from "react-new-window";

const PrintTransmittal = ({ showPrintReport, transmittalHeader}) => {

    const [transmittalDetails, setTransmittalDetails] = useState(null);
    const [loadingMessage, setLoadingMessage] = useState(null);
    let total = 0;
    let totalTransCount = 0;

    const componentRef = useRef();
    const handlePrint = useReactToPrint({
      content: () => componentRef.current,
      documentTitle:'TransmittalReport_Date_' + formatShortDate(new Date()),
    });
  
  
    useEffect(() => {
        getTransmittalDetails();
    }, []);
  
    const getTransmittalDetails = async () => {
      try {
        setLoadingMessage("Generating Report... Please Wait");
        const payload = { transmittalHeaderId: transmittalHeader.id };
        var entries = await GET_REQUEST(
          URL_GET_TRANSMITTAL_DETAILS_BY_HEADER_ID,
          payload
        );
        setTransmittalDetails(entries);
       
        setLoadingMessage(null);
        handlePrint();
        showPrintReport(false);
      } catch (e) {
        console.log(e);
      } finally {
      }
    };

  return (
    <NewWindow onUnload={() => showPrintReport(false)} center="parent">
      <button
        className="font-cold border rounded-md px-4 py-2"
        onClick={handlePrint}
      >
        Print Report
      </button>
      <div ref={componentRef} className="m-5 p-2">
        <table
          id="tblPrintTransmittals"
          className="w-full rounded-md overflow-hidden border-collapse"
        >
          <thead>
            <tr>
              <th colSpan={8}>
                <h1 className="font-bold text-lg">Ewallet Topup System</h1>
                <h2 className="text-sm">Transmittal Report</h2>
                <h2 className="text-sm">
                  {formatShortDate(transmittalHeader.dateStart)} -{" "}
                  {formatShortDate(transmittalHeader.dateEnd)}
                </h2>

                <div className="p-3 flex justify-between">
                  <div>Department: {transmittalHeader.departmentName} </div>
                  <div> Remarks: {transmittalHeader.remarks} </div>
                </div>
                <div className="px-3 flex justify-between">
                  <div>Approve Date: {transmittalHeader.approvedDate === null ? "" : formatShortDate(transmittalHeader.approvedDate)} </div>
                  <div>Transmittal Date: {formatShortDate(transmittalHeader.transmittalDate)} </div>
                </div>
              </th>
            </tr>
            <tr className="border">
              <th className="text-left">Employee Name</th>
              <th className="text-left">Amount</th>
            </tr>
          </thead>
          <tbody>
            {transmittalDetails?.map((transmittalDetail) => {
              total = total + transmittalDetail.amount;
              totalTransCount = (totalTransCount + 1);
              return (
                <tr key={transmittalDetail.id}>
                  <td>{transmittalDetail.employeeName}</td>
                  <td className="text-right">
                    {formatNumber(transmittalDetail.amount)}
                  </td>
                </tr>
              );
            })}
          </tbody>
          <tfoot>
            <tr></tr>
          </tfoot>
        </table>

        <div className="p-3 flex justify-between">
        <div className="rtp-amount">Total Number of Employees:  {totalTransCount} </div>
          <div className="rtp-amount ml-2">
            Total Amount: {formatNumber(total)}
          </div>
        </div>

        <div className="px-3 flex justify-between">
        <div className="ml-1">
            Approved By: {transmittalHeader.approveByName}
          </div>
        </div>
        <div className="px-3 flex justify-between">
          <div className="ml-1">
            Date/Time Printed: {formatDateTimeCommon(new Date())}
          </div>
        </div>
        
        <div className="text-center mt-5">*** Nothing Follows ***</div>
        {loadingMessage && <div>{loadingMessage}</div>}
      </div>
    </NewWindow>
  );
}

export default PrintTransmittal