import { ReactComponent as DashboardIcon } from "./DashboardMenuIcons/Dashboard.svg";
import { ReactComponent as TopupRequestIcon } from "./DashboardMenuIcons/TopupRequests.svg";
import { ReactComponent as JournalEntryIcon } from "./DashboardMenuIcons/JournalEntryLoading.svg";
import { ReactComponent as LogoutIcon } from "./logout.svg";
import { ReactComponent as GearIcon } from "./DashboardMenuIcons/gear3.svg";

export default {
  DashboardIcon,
  TopupRequestIcon,
  JournalEntryIcon,
  LogoutIcon,
  GearIcon,
};
