import React, { useEffect, useState } from 'react'
import { FiArrowRight } from 'react-icons/fi';
import { formatDate_journal, formatNumber } from '../../../utils/Common';
import PrintInvoice from './PrintInvoice';

export const JournalEntryDetailView = ({ journalEntry, onClose }) => {

    const [slideIn, setSlideIn] = useState(false);
    const [printInvoice, showPrintInvoice] = useState(false);

    useEffect(() => {
        setSlideIn(true);
      }, [journalEntry]);
    
      const handleCloseWindow = () => {
        setSlideIn(false);
        onClose();
      };

  const handlePrint = () => {
    showPrintInvoice(true);
  };


  return (
      <div
      className="w-full top-0 left-0 h-screen bg-gray-100 fixed flex justify-end overflow-hidden"
      style={{ backgroundColor: "rgba(0,0,0,0.3)" }}>
        
        <div className="grow" onClick={handleCloseWindow}></div>
      <div
        className={`bg-white flex flex-col p-3 drop-shadow-lg detail-container ${
          slideIn ? "request-detail-animation" : ""
        }`}
        onClick={() => {}}
      >
        <FiArrowRight
          className="text-primary cursor-pointer my-3"
          size="20"
          onClick={handleCloseWindow}
        />
        <div className="w-full flex items-center justify-between">
          <div className="flex flex-col">
            <span>Transaction No.</span>
            <span className="font-bold" style={{ fontSize: "1rem" }}>
              {journalEntry.referenceNumber}
            </span>
          </div>
        </div>
        <div className="bg-gray-200 p-2 rounded-md my-3 font-bold">Details</div>
        <div id="details" className="flex flex-col">
        <div className="flex justify-between">
            <span>Ewallet Account</span>
            <span>{journalEntry.ewalletAccountName}</span>
          </div>
          <div className="flex justify-between">
            <span>Ewallet Mobile Number</span>
            <span>{journalEntry.contactNumber}</span>
          </div>
          <div className="flex justify-between">
            <span>Request Date</span>
            <span>{formatDate_journal(journalEntry.dateCreated)}</span>
          </div>
          <div className="flex justify-between">
            <span>Load Amount</span>
            <span>₱{formatNumber(journalEntry.topupRequestAmount)}</span>
          </div>
      

          <div className="flex justify-between">
          <span/>
          <button className="btn btn-primary cursor-pointer" onClick={handlePrint}>
            Preview
          </button>
          </div>
        </div>
      </div>

      {printInvoice && (
        <PrintInvoice
        showPrintReport={showPrintInvoice}
        invoiceDetail = {journalEntry}
        />
      )}
    </div>
  )
}
