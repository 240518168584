import React, { useState } from "react";

const SelectCancelReason = ({ onConfirm, onBack }) => {
  const reasons = [
    "Incorrect eWallet Account",
    "Incorrect Amount",
    "Incorrect payment method selected",
    "Other",
  ];

  const [customReason, setCustomReason] = useState("");
  const [reason, setReason] = useState(null);

  const handleSelectReason = (e) => {
    setReason(e.target.nextElementSibling.innerHTML);
  };

  const handleConfirmReason = () => {
    let _reason = "";
    if (reason === "Other") {
      _reason = customReason;
    } else {
      _reason = reason;
    }

    onConfirm(_reason);
  };

  let customReasonDisable = true;
  if (reason === "Other") customReasonDisable = false;

  let confirmButtonDisable = true;
  if (reason === "Other" && customReason?.trim()) confirmButtonDisable = false;
  else if (reason != "Other" && reason) confirmButtonDisable = false;

  return (
    <div>
      <div className="font-bold text-lg">Cancel Top-up</div>
      <div className="my-3">Choose reason</div>
      <div className="flex flex-col" onChange={handleSelectReason}>
        {reasons.map((reason) => {
          return (
            <div key={reason} className="py-2 flex items-center">
              {" "}
              <input
                type="radio"
                id={reason}
                name="cancelReason"
                className="accent-primary"
              />{" "}
              <label htmlFor={reason} className="pl-2">
                {reason}
              </label>
            </div>
          );
        })}
      </div>
      <div className="ml-4" style={{ minWidth: "340px" }}>
        <textarea
          placeholder="Please state reason for cancellation"
          disabled={customReasonDisable}
          className="input-text w-full"
          rows="3"
          style={{ maxHeight: "100px", minHeight: "50px" }}
          onChange={(e) => setCustomReason(e.target.value)}
          maxLength="1000"
        />
      </div>
      <div className="flex justify-end">
        <button className="btn btn-secondary" onClick={onBack}>
          Go Back
        </button>
        <button
          disabled={confirmButtonDisable}
          className="btn btn-primary"
          onClick={handleConfirmReason}
        >
          Confirm
        </button>
      </div>
    </div>
  );
};

export default SelectCancelReason;
