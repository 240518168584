import { BASE_URL } from "../../AppConfiguration";
import BuyerDTO from "./BuyerDTO";

export default class CheckoutDTO {
  constructor(referenceNumber, amount, buyer) {
    this.requestReferenceNumber = referenceNumber;
    this.totalAmount = new TotalAmount(amount);
    this.buyer = buyer;
    this.redirectUrl = new RedirectURL(
      "/card/success",
      "/card/failure",
      "/card/cancel"
    );
  }
}

class TotalAmount {
  constructor(amount) {
    this.value = amount;
    this.currency = "PHP";
  }
}

class RedirectURL {
  constructor(successURL, failureURL, cancelURL) {
    this.success = BASE_URL + successURL;
    this.failure = BASE_URL + failureURL;
    this.cancel = BASE_URL + cancelURL;
  }
}
