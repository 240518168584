//Version Formula
//1 - Major Version
//2 - Feature
//3 - Iteration Count by commit
export const APP_VERSION = "V 1.15.50";
export const APP_TITLE = "EWallet Top-up System";

/* -------------------------------------------------------------------------- */
/*                              Connection Strings                            */
/* -------------------------------------------------------------------------- */

//LIVE
export const PAYMAYA_API_CONFIG_MODE = "LIVE";
export const EWALLET_CONNECTION =
  "https://ewallettopupwebservice.azurewebsites.net";
export const BASE_URL = "https://isewallet.com";


//EUS
// export const PAYMAYA_API_CONFIG_MODE="SANDBOX";
// export const EWALLET_CONNECTION =
//   "http://eus.iloilosupermart.com/ewallettopupwebservice";
// export const BASE_URL = "http://eus.iloilosupermart.com:3001";

//Dev
//  export const PAYMAYA_API_CONFIG_MODE="SANDBOX";
//  export const EWALLET_CONNECTION =
//    "http://dev.iloilosupermart.com/ewallettopupwebservice";
//  export const BASE_URL = "http://dev.iloilosupermart.com:3001";

//Local Dev Machine
// export const PAYMAYA_API_CONFIG_MODE = "SANDBOX";
// export const EWALLET_CONNECTION = "http://localhost/ewallettopupwebservice";
// export const BASE_URL = "http://localhost:3001";

//Local Dev
// export const PAYMAYA_API_CONFIG_MODE = "SANDBOX";
// export const EWALLET_CONNECTION = "http://localhost/ewallettopupwebservice";
// export const BASE_URL = "http://localhost:3000";
