import React from "react";
import { useNotification } from "../../../utils/NotificationContext";
import AlertNotification from "./AlertNotification";

const AlertNotificationContainer = () => {
  const notification = useNotification();
  return <>{notification && <AlertNotification Message={notification} />}</>;
};

export default AlertNotificationContainer;
