import axios from "axios";
import React, { useState, useEffect } from "react";
import { URL_GET_TOPUP_AMOUNTS } from "../../api/Endpoints";
import ChevronRight from "../../assets/chevronRight.svg";
import { formatNumber, formatWholeNumber } from "../../utils/Common";

const SelectAmount2 = ({ onAmountSelected, currentValue }) => {
  // From API
  // let PredefinedAmounts = [100,200,300,500,1000,5000,10000]
  const [PredefinedAmounts, setPredefinedAmounts] = useState([]);
  const [isLoading, setLoading] = useState(true);
  const [isMouseOverAmounts, setMouseOverAmounts] = useState(false);
  const [isShowPredefinedAmounts, setShowPredefinedAmounts] = useState(false);
  const [amountValue, setAmount] = useState(0);

  useEffect(() => {
    let isMounted = true;
    axios
      .get(URL_GET_TOPUP_AMOUNTS)
      .then((response) => {
        if (isMounted) {
          setLoading(false);
          setPredefinedAmounts(response.data);
        }
      })
      .catch((error) => console.log(error));

    return () => {
      isMounted = false;
    };
  }, []);

  useEffect(() => {
    onAmountSelected(amountValue);
  }, [amountValue]);

  useEffect(() => {
    setAmount(currentValue);
  }, [currentValue]);

  const handleAmountClick = (e) => {
    setAmount(formatNumber(e.target.value));
    setShowPredefinedAmounts(false);
    setMouseOverAmounts(false);
  };

  const handleOnchangeAmount = (e) => {

    const re = /^\d{1,}(\.\d{0,4})?$/;///^[0-9,.\b]+$/;
    // if value is not blank, then test the regex

    let number = e.target.value.toString().replaceAll(",", "");
    if (number === '' || re.test(number)) {
      setAmount(e.target.value);
    }

  };

  const handleOnAmountLostFocus = () => {
    //console.log(isMouseOverAmounts)

    var numbers = amountValue.split(".");
    var amt = numbers[0] + (numbers[1] ? "." + numbers[1] : "");

    setAmount(formatNumber(amt));
    if (!isMouseOverAmounts) setShowPredefinedAmounts(false);
  };

 
  const handleFocus = (e) => {
    setShowPredefinedAmounts(true)
    e.target.select();
  };

  // const amountValueDisplay = amountValue.replace('/\D/g', "").replace(/\B(?=(\d{3})+(?!\d))/g, ",")

  return (
    <div>
      <div>
        <p className="font-bold">Amount</p>
        <div className="relative">
          <input
            type="text"
            className="input-text w-full"
            placeholder="0.00"
            onChange={handleOnchangeAmount}
            onFocus={handleFocus}
            onBlur={handleOnAmountLostFocus}
            value={amountValue}

          />
          <span className="absolute h-full  top-0 right-0 flex p-2">
            <img
              src={ChevronRight}
              className={isShowPredefinedAmounts ? "-rotate-90" : "rotate-90"}
              width="20px"
              onClick={() => setShowPredefinedAmounts(!isShowPredefinedAmounts)}
            />
          </span>
          {isShowPredefinedAmounts && (
            <div
              className="absolute bg-white w-full rounded-md drop-shadow-md mt-1"
              onMouseEnter={() => setMouseOverAmounts(true)}
              onMouseLeave={() => setMouseOverAmounts(false)}
            >
              <ul className="">
                {PredefinedAmounts.map((amount) => {
                  return (
                    <li
                      className="px-3 py-2 hover:bg-red-50"
                      value={amount}
                      key={amount}
                      onClick={handleAmountClick}
                    >
                      {formatWholeNumber(amount)}
                    </li>
                  );
                })}
              </ul>
            </div>
          )}
        </div>
      </div>
    </div>
  );
};

export default SelectAmount2;
