class EWalletUser {
  constructor(Id, FirstName, LastName, MobileNumber, TopUpOption, Tags, Email) {
    this.Id = Id;
    this.FirstName = FirstName;
    this.LastName = LastName;
    this.FullName = this.LastName + ", " + this.FirstName;
    this.MobileNumber = MobileNumber;
    this.Email = Email;
    this.TopUpOption = TopUpOption;
    this.Tags = Tags;
  }
}

export default EWalletUser;
