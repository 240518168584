import React, { useState } from "react";
import { getMonths } from "../../../utils/Common";

const MonthYearPicker = ({ onDateSelected, defaultDate, onCancel }) => {
  const currentDate = new Date(defaultDate);
  const months = getMonths();
  const currentYear = currentDate.getFullYear();
  const _currentYear = new Date(Date.now()).getFullYear();
  const years = [_currentYear, _currentYear - 1];

  const [selectedMonth, setMonth] = useState(currentDate.getMonth() + 1);
  const [selectedYear, setYear] = useState(currentYear);

  const handleSelectDate = () => {
    const selectedDate = `${selectedMonth}-01-${selectedYear}`;
    onDateSelected(selectedDate);
  };

  // console.log(selectedMonth);
  return (
    <div className="flex flex-col border p-3 rounded-md w-fit bg-white shadow-lg relative">
      <span
        className="absolute top-0 left-0 p-2 cursor-pointer"
        onClick={onCancel}
      >
        X
      </span>
      <div className="self-center font-bold">Select Date</div>
      <div className="flex">
        <select
          className="border m-2"
          value={selectedMonth}
          onChange={(e) => setMonth(e.target.value)}
        >
          {months.map((month, index) => {
            return (
              <option key={month} value={index + 1}>
                {month}
              </option>
            );
          })}
        </select>
        <select
          className="border m-2 rounded-sm"
          value={selectedYear}
          onChange={(e) => setYear(e.target.value)}
        >
          {years.map((year) => {
            return <option key={year}>{year}</option>;
          })}
        </select>
      </div>
      <button
        className="btn-small btn-primary self-center"
        onClick={handleSelectDate}
      >
        OK
      </button>
    </div>
  );
};

export default MonthYearPicker;
