export const SESSION_CUSTOMER_TO_TOPUP = "CustomerToTopup";
export const STORAGE_ADMIN_USER = "AdminUser";

export const LOGIN_MODE_BACKOFFICE = "BackOfficeLogin";
export const LOGIN_MODE_CUSTOMER = "CustomerLogin";

export const LOAD_OPTION_LOAD_SELF = 1;
export const LOAD_OPTION_LOAD_ON_BEHALF = 2;

export const TOPUP_REQUEST_STATUS_PENDING = 0;
export const TOPUP_REQUEST_STATUS_DONE = 1;
export const TOPUP_REQUEST_STATUS_PROCESSING = 2;
export const TOPUP_REQUEST_STATUS_EXPIRED = 3;
export const TOPUP_REQUEST_STATUS_CANCELLED = 4;

export const REGEX_MOBILE_NUMBER = "(^09\\d{9}$)|(^\\+63\\d{10}$)";
