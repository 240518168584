export default class NewTopupRequest {
  constructor(
    topupOption,
    ewalletAccountId,
    ewalletAccountName,
    topupRequestAmount,
    paymentMethodCode,
    contactNumber,
    billingAddress
  ) {
    this.topupOption = topupOption;
    this.ewalletAccountId = ewalletAccountId;
    this.ewalletAccountName = ewalletAccountName;
    this.topupRequestAmount = topupRequestAmount;
    this.paymentMethodCode = paymentMethodCode;
    this.contactNumber = contactNumber;
    this.billingAddress = billingAddress;
  }
}
