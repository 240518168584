import React from 'react'

const ErrorContainer = ({ErrorMessage}) => {
  return (
    <div className='bg-red-100 p-3 rounded-md border border-solid border-red-300 text-center mt-3'>
        {ErrorMessage}
    </div>
  )
}

export default ErrorContainer