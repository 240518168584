import React, { useRef, useEffect, useState } from "react";
import NewWindow from "react-new-window";
import { useReactToPrint } from "react-to-print";
import { formatDateWithTime_journal, formatNumber, formatShortDate } from '../../../utils/Common';
import islogo from "../../../assets/islogo.svg";
import receiptframe from "../../../assets/receiptframe.png";

const PrintInvoice = ({showPrintReport, invoiceDetail }) => {

    const componentRef = useRef();

useEffect(() => {
  setTimeout(() => {
    if (componentRef.current) {
      handlePrint();
      showPrintReport(false)
    }
  }, 100);
}, []);

const handlePrint = useReactToPrint({
  content: () => componentRef.current,
  documentTitle:'Invoice_Date_' + formatShortDate(new Date()),
});


  return (
    <NewWindow onUnload={() => showPrintReport(false)} center="parent">
      <button
        className="font-cold border rounded-md px-4 py-2"
        onClick={handlePrint}
      >
        Print Report
      </button>

      <div ref={componentRef}    className="m-5 p-2 mt-20 flex justify-center">
        <img src={receiptframe} width="320px" className="" />
        <div
          className="flex flex-col items-center"
          style={{ width: 280, position: "absolute", top: 150 }}
        >
          <img src={islogo} width="150px" className="ml-2" />
          <h1
            className="text-Montserrat text-primary font-black italic"
            style={{ fontSize: "30px", lineHeight: "24px", margin: "10px 0px" }}
          >
            EWALLET
          </h1>

          <p
            className="font-extrabold"
            style={{ lineHeight: "30px", fontSize: "16px", color: "#707070" }}
          >
            Top-up Portal
          </p>
          <div className="container p-3 mt-1 text-xs border-t flex justify-between">
            <span className=" text-gray-400">Customer Name</span>
            <span style={{ color: "#707F8E" }}>
              {invoiceDetail.ewalletAccountName}
            </span>
          </div>
          <div className="container p-3 text-xs flex justify-between">
            <span className=" text-gray-400">Mobile Number</span>
            <span style={{ color: "#707F8E" }}>
              {invoiceDetail.contactNumber}
            </span>
          </div>
          <div className="container p-3 text-xs flex justify-between">
            <span className=" text-gray-400">Transaction No.</span>
            <span style={{ color: "#707F8E" }}>
              {invoiceDetail.referenceNumber}
            </span>
          </div>
          <div className="container p-3 text-xs border-t border-b flex justify-between">
            <span className=" text-gray-400">Total Amount</span>
            <span style={{ color: "#707F8E" }}>
              ₱{formatNumber(invoiceDetail.topupRequestAmount)}
            </span>
          </div>

          <div className="text-gray-400 mt-20">
            <span>{formatDateWithTime_journal(invoiceDetail.dateCreated)}</span>
          </div>
        </div>
      </div>
    </NewWindow>
  );
};

export default PrintInvoice