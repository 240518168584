import React, { useContext, useState } from "react";
import Notification from "../classes/NotificationAgg/Notification";

const NotificationContext = React.createContext();
const NotificationUpdateContext = React.createContext();

export function useNotification() {
  return useContext(NotificationContext);
}

export function useNotificationUpdate() {
  return useContext(NotificationUpdateContext);
}

export function NotificationProvider({ children }) {
  const [notification, setNotification] = useState(null);

  const updateNotification = (notification) => {
    // console.log(notification);
    setNotification(notification);
    if (notification) {
      setTimeout(() => {
        updateNotification(null);
      }, 5000);
    }
  };

  return (
    <NotificationContext.Provider value={notification}>
      <NotificationUpdateContext.Provider value={updateNotification}>
        {children}
      </NotificationUpdateContext.Provider>
    </NotificationContext.Provider>
  );
}
