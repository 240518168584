import React, { useState } from "react";
import { useForm } from "react-hook-form";
import { PUT_REQUEST } from "../../../api/APIManager";
import { URL_PUT_UPDATE_PAYMENT_METHOD } from "../../../api/Endpoints";
import EditTopupOptionsControl from "./TopupOptions/EditTopupOptionsControl";
import "./EditPaymentMethod.css";
import { formatDate_journal } from "../../../utils/Common";

const EditPaymentMethod = ({ paymentMethod, onCancel, onSuccess }) => {
  const pmCode = paymentMethod.code;
  const [pmName, setPMName] = useState(paymentMethod.name);
  const [selectedOptions, setSelectedOptions] = useState();

  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm({ defaultValues: paymentMethod });

  const handleEditPaymentMethod = async (payload) => {
    try {
      let _payload = payload;
      _payload.topupOptions = selectedOptions;

      const response = await PUT_REQUEST(
        `${URL_PUT_UPDATE_PAYMENT_METHOD}/${payload.code}`,
        _payload
      );

      onSuccess();
    } catch (ex) {
      console.log(ex.message);
    }
  };

  const handleOptionsChange = (newOptions) => {
    // console.log(newOptions);
    setSelectedOptions(newOptions);
  };

  return (
    <div>
      <h1 className="text-lg font-bold text-center">Edit Payment Method</h1>
      <form onSubmit={handleSubmit(handleEditPaymentMethod)}>
        <div id="frmEditPaymentMethod" className="flex flex-col items-center">
          <div>
            <label>Code</label>
            <label className="">{paymentMethod.code}</label>
          </div>

          <div>
            <label>Name</label>
            <input
              className="input-text"
              type="text"
              {...register("name", {
                required: "Payment Method Name is Required",
              })}
            />
          </div>
          {errors.name && <span>{errors.name.message}</span>}

          <div>
            <label>Status</label>
            <select
              className="input-text"
              {...register("status", {
                required: "Status is Required",
                valueAsNumber: true,
              })}
            >
              <option value={0}>Hidden</option>
              <option value={1}>Active</option>
              <option value={2}>Inactive</option>
            </select>
          </div>

          <div>
            <label>Minimum Amount</label>
            <input
              className="input-text"
              type="number"
              min={0}
              step="0.01"
              {...register("minimumAmount", {
                required: "Payment Method Name is Required",
              })}
            />
          </div>
          <div>
            <label>Validity (Days)</label>
            <input
              className="input-text"
              type="number"
              min={0}
              {...register("daysValidity", {
                required: "Payment Method Name is Required",
              })}
            />
          </div>
          <div>
            <label>Priority</label>
            <input
              className="input-text"
              type="number"
              min="0"
              {...register("priority", {
                required: "Payment Method Name is Required",
              })}
            />
          </div>
          <div>
            <label>Options</label>
            <EditTopupOptionsControl
              topUpOptions={paymentMethod.topupOptions}
              onOptionsChanged={handleOptionsChange}
            />
          </div>
          <div>
            <label>Remarks</label>
            <textarea
              className="input-text"
              type="text"
              {...register("remarks", { required: false })}
            />
          </div>
          <div>
            <label>Date Created</label>
            <label>{formatDate_journal(paymentMethod.dateCreated)}</label>
          </div>
          <div>
            <label>Date Updated</label>
            <label>{formatDate_journal(paymentMethod.dateUpdated)}</label>
          </div>
        </div>
        <div className="text-right mt-5">
          <button className="btn btn-secondary" onClick={onCancel}>
            Cancel
          </button>
          <input type="submit" className="btn btn-primary" value="Save" />
        </div>
      </form>
    </div>
  );
};

export default EditPaymentMethod;
