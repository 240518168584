import React, { useEffect, useState } from "react";
import { URL_GET_TRANSMITTAL_DETAILS_BY_HEADER_ID } from "../../../api/Endpoints";
import { GET_REQUEST } from "../../../api/APIManager";
import { formatNumber } from "../../../utils/Common";
import loading from '../../../assets/loading2.gif'
const TransmittalView = ({ onClose, transmittal }) => {
  const [transmittalDetailDTOs, setTransmittalDetailDTOs] = useState(null);
  const [loadingMessage, setLoadingMessage] = useState(null);

  useEffect(() => {
    getTransmittalDetails();
  }, []);

  const getTransmittalDetails = async () => {
    try {
      setLoadingMessage("Loading View Transmittal");
      const payload = { transmittalHeaderId: transmittal.id };
      var entries = await GET_REQUEST(
        URL_GET_TRANSMITTAL_DETAILS_BY_HEADER_ID,
        payload
      );
      setTransmittalDetailDTOs(entries);
    } catch (e) {
      console.log(e);
    } finally {
      setLoadingMessage(null);
    }
  };

  return (
    <div
      className="bg-white p-5 rounded-md shadow-md"
      style={{
        width: "800px",
        position: "absolute",
        top: "50%",
        left: "50%",
        transform: "translate(-50%, -50%)",
      }}
    >
      <h1 className="font-bold text-lg">Transmittal View</h1>
      <div style={{ maxHeight: "60vh", overflowY: "scroll" }}>
        <table
          id="tblCommons"
          className="w-full rounded-md overflow-hidden border-collapse bg-grey-custom mt-4"
        >
          <thead>
            <tr className="border">
              <th className="text-left">Employee Name</th>
              <th className="text-left">Position</th>
              <th className="text-left">eWallet Account Info.</th>
              <th className="text-left">Amount</th>
              <th></th>
            </tr>
          </thead>
          <tbody>
            {transmittalDetailDTOs?.map((entry) => {
              return (
                <tr key={entry.employeeId}>
                  <td>{entry.employeeName}</td>
                  <td>{entry.position}</td>
                  <td>{entry.ewalletAccountInfo}</td>
                  <td>{formatNumber(entry.amount)}</td>
                </tr>
              );
            })}
          </tbody>
        </table>
      </div>
      <div className="flex justify-end mt-4">
        <button
          className="btn btn-primary flex items-center justify-center border p-2 m-2 rounded-md"
          onClick={onClose}
        >
          close
        </button>
      </div>
      {loadingMessage && (
        <div
          style={{
            position: "absolute",
            left: "37%",
            top: "10%",
          }}
        >
          <div
            className="bg-white shadow-lg flex flex-col rounded-lg"
            style={{ minWidth: "150px", maxWidth: "250px" }}
          >
            <img src={loading} className="p-5" />
            <div
              className="text-center py-5 text-base font-bold px-2"
              dangerouslySetInnerHTML={{ __html: loadingMessage }}
            ></div>
          </div>
        </div>
      )}
    </div>
  );
};

export default TransmittalView;
