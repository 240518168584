import Cookies from "universal-cookie";
import { SESSION_CUSTOMER_TO_TOPUP, STORAGE_ADMIN_USER } from "./Constants";

export const getUser = () => {
  const cookies = new Cookies();
  let user = cookies.get("user");

  if (user) return user;

  if (!user) {
    user = sessionStorage.getItem("user");
    // console.log('usersession ' + user)
  }

  // console.log(user)

  if (user) return JSON.parse(user);
  else return null;
};

export const getCustomerTotopup = () => {
  let customer = sessionStorage.getItem(SESSION_CUSTOMER_TO_TOPUP);

  if (customer) return JSON.parse(customer);
  else return null;
};

export const setCustomerTotopup = (customer) => {
  sessionStorage.setItem(SESSION_CUSTOMER_TO_TOPUP, JSON.stringify(customer));
};

export const removeCustomerToTopup = () => {
  sessionStorage.removeItem(SESSION_CUSTOMER_TO_TOPUP);
};

export const removeUserSession = () => {
  sessionStorage.removeItem("user");
};

export const setUserSession = (user) => {
  sessionStorage.setItem("user", user);
};

export const setUserCookie = (user) => {
  const cookies = new Cookies();

  let expiryDate = new Date();
  expiryDate.setDate(expiryDate.getDate() + 1);

  cookies.set("user", user, { path: "/", expires: expiryDate });
};

export const removeUserCookie = () => {
  const cookies = new Cookies();

  cookies.remove("user");
};

export const setBackOfficeUser = (backOfficeUser) => {
  sessionStorage.setItem(STORAGE_ADMIN_USER, JSON.stringify(backOfficeUser));
};

export const getBackOfficeUser = () => {
  const boUser = sessionStorage.getItem(STORAGE_ADMIN_USER);
  if (boUser) {
    return JSON.parse(boUser);
  } else {
    return null;
  }
};

export const removeBackOfficeUser = () => {
  sessionStorage.removeItem(STORAGE_ADMIN_USER);
};

export const formatNumber = (number) => {
  let _number = number.toString().replaceAll(",", "");
  var parts = Number(_number).toFixed(2).split(".");
  var num =
    parts[0].replace(/(\d)(?=(\d{3})+(?!\d))/g, "$1,") +
    (parts[1] ? "." + parts[1] : "");
  return num;
};

export const formatWholeNumber = (number) => {
  let _number = number.toString().replaceAll(",", "");
  // console.log(_number);
  var parts = Number(_number).toString();
  //  console.log(parts);
  var num = parts.replace(/(\d)(?=(\d{3})+(?!\d))/g, "$1,"); 
  return num;
};

export const getMonths = () => {
  return [
    "January",
    "February",
    "March",
    "April",
    "May",
    "June",
    "July",
    "August",
    "September",
    "October",
    "November",
    "December",
  ];
};

export const formatShortDate = (date) => {
  const _date = new Date(date);
  const _month = _date.getMonth() + 1;
  const _year = _date.getFullYear();

  return `${_month}/${_date.getDate()}/${_year}`;
};

export const formatDate = (date) => {
  const _date = new Date(date);
  const _month = _date.getMonth();
  const _year = _date.getFullYear();

  const months = [
    "Jan",
    "Feb",
    "Mar",
    "Apr",
    "May",
    "Jun",
    "Jul",
    "Aug",
    "Sep",
    "Oct",
    "Nov",
    "Dec",
  ];

  return `${months[_month]} ${_year}`;
};

export const formatDateTimeCommon = (date) => {
  const _date = new Date(date);

  const AMPM = _date.getHours() < 12 ? "AM" : "PM";
  const hour =
    _date.getHours() <= 12 ? _date.getHours() : _date.getHours() - 12;

  return `${_date.getMonth() + 1}/${_date.getDate()}/${_date.getFullYear()} ${hour}:${_date.getMinutes()}:${_date.getSeconds()} ${AMPM}`;
};

export const formatDate_journal = (date) => {
  const _date = new Date(date);
  const _month = _date.getMonth();
  const _year = _date.getFullYear();

  const months = [
    "Jan",
    "Feb",
    "Mar",
    "Apr",
    "May",
    "Jun",
    "Jul",
    "Aug",
    "Sep",
    "Oct",
    "Nov",
    "Dec",
  ];

  return date == null ? '' : `${_date.getDate()} ${months[_month]} ${_year}`;
};

export const formatDateWithTime_journal = (date) => {
  const _date = new Date(date);
  const _month = _date.getMonth();
  const _year = _date.getFullYear();

  const months = [
    "Jan",
    "Feb",
    "Mar",
    "Apr",
    "May",
    "Jun",
    "Jul",
    "Aug",
    "Sep",
    "Oct",
    "Nov",
    "Dec",
  ];

  return date == null ? '' : `${_date.getDate()} ${months[_month]} ${_year} ${('0' + _date.getHours()).slice(-2)}:${('0' +_date.getMinutes()).slice(-2)}`;
};


export const formatDateTimeOnly_journal = (date) => {
  const _date = new Date(date);

  return date == null ? '' : ` ${('0' + _date.getHours()).slice(-2)}:${('0' +_date.getMinutes()).slice(-2)}`;
};

export const getTopupOptionValue = (topupOption) => {
  switch (topupOption) {
    case 1:
      return "Load Self";

    case 2:
      return "Load in Behalf";
    default:
      return "";
  }
};
