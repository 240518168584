import React, { useEffect, useState } from "react";
import { BsPlusLg } from "react-icons/bs";
import { GET_REQUEST, PUT_REQUEST } from "../../../api/APIManager";
import {
  URL_GET_CUSTOMER_USERS,
  URL_PUT_CUSTOMER_USERS,
} from "../../../api/Endpoints";
import ComponentModal from "../../../components/Common/ComponentModal/ComponentModal";
import ConfirmationModal from "../../../components/ConfirmationModal/ConfirmationModal";
import { getBackOfficeUser } from "../../../utils/Common";
import { useNotificationUpdate } from "../../../utils/NotificationContext";
import NewUser from "./NewUser";

const UserManager = () => {
  const [users, setUsers] = useState(null);
  const [removeUser, setRemoveUser] = useState(null);
  const updateNotif = useNotificationUpdate();
  const [showCreateNewEntry, setCreateNewEntry] = useState(false);

  const boUser = getBackOfficeUser();

  useEffect(() => {
    getUsers();
  }, []);

  const getUsers = async () => {
    try {
      var entries = await GET_REQUEST(URL_GET_CUSTOMER_USERS);
      setUsers(entries);
    } catch (e) {
      console.log(e);
    }
  };

  let confirmationModalContent = [];

  confirmationModalContent.push("Do you want to remove this user?");

  const handleCancelRemove = () => {
    setRemoveUser(null);
  };

  const handleOnConfirmUserToRemove = async () => {
    try {
      const payload = {
        CustomerId: removeUser.id,
        Checked: false,
        IsTransmittalAdmin: false,
        IsTransmittalEncoder: false,
        InitiatedBy: boUser?.FirstName + " " + boUser?.LastName + " (" + boUser?.Id + ")",
      };

      const response = await PUT_REQUEST(URL_PUT_CUSTOMER_USERS, payload);

      getUsers();
      setRemoveUser(null);
      updateNotif("User Successfully Removed");
    } catch (ex) {
      console.log(ex.message);
    }
  };

  const handleSuccesfulEntry = () => {
    setCreateNewEntry(false);
    getUsers();
    setRemoveUser(null);
    updateNotif("User Successfully Added");
  };

  const handleRemoveUser = (user) => {
    setRemoveUser(user);
  };

  return (
    <div>
      <div className="flex justify-between">
        <h1 className="font-bold text-lg">User Manager</h1>
        <div className="flex">
          <button
            className="btn btn-glow btn-primary flex items-center"
            onClick={() => setCreateNewEntry(true)}
          >
            <BsPlusLg color="white" className="mr-3" />
            Create New User
          </button>
        </div>
      </div>

      <div className="border-t mt-3 pt-4">
        <table
          id="tblPaymentMethods"
          className="w-full rounded-md overflow-hidden border-collapse bg-grey-custom mt-4"
        >
          <thead>
            <tr className="border">
              <th className="text-left">Full Name</th>
              <th className="text-left">Mobile No.</th>
              <th className="text-left"></th>
              <th></th>
            </tr>
          </thead>
          <tbody>
            {users?.map((entry) => {
              return (
                <tr key={entry.id}>
                  <td>{entry.fullName}</td>
                  <td>{entry.mobileNo}</td>
                  <td>
                    <button
                      className="btn btn-primary flex items-center border p-2 m-2 rounded-md"
                      onClick={() => handleRemoveUser(entry)}
                    >
                      Remove User
                    </button>
                  </td>
                </tr>
              );
            })}
          </tbody>
        </table>
      </div>

      {removeUser !== null && (
        <ConfirmationModal
          title="Confirm Remove"
          content={confirmationModalContent}
          closeModal={handleCancelRemove}
          onConfirm={handleOnConfirmUserToRemove}
          callToActionText="Confirm"
        />
      )}
       {showCreateNewEntry && (
        <ComponentModal>
          <NewUser
            onClose={() => setCreateNewEntry(false)}
            onConfirm={handleSuccesfulEntry}
          />
        </ComponentModal>
      )}
    </div>
  );
};

export default UserManager;
