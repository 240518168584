export default class TopupStatisticsDTO {
  constructor(paymentMethodCode, paymentMethodName) {
    this.paymentMethodCode = paymentMethodCode;
    this.paymentMethodName = paymentMethodName;
    this.processingCount = 0;
    this.pendingCount = 0;
    this.onHoldCount = 0;
    this.doneCount = 0;
    this.cancelledCount = 0;
  }

  setProcessingCount(count) {
    this.processingCount = count;
  }
  setPendingCount(count) {
    this.pendingCount += count;
  }
  setOnHoldCount(count) {
    this.onHoldCount += count;
  }
  setDoneCount(count) {
    this.doneCount += count;
  }
  setCancelledCount(count) {
    this.cancelledCount += count;
  }
}
