import React, { useState } from "react";
import Behalf from "../../assets/behalf.svg";
import LeftArrow from "../../assets/leftShortArrow.svg";
import CustomerFinder from "../CustomerFinder/CustomerFinder";
import TopupMethodHeader from "../LoadingOption/TopupMethodHeader";
import ConfirmationModal from "../ConfirmationModal/ConfirmationModal";
import { useCustomerUpdate } from "../../utils/CustomerContext";
import { useNavigate } from "react-router-dom";
import { APP_TITLE } from "../../AppConfiguration";

const LoadOnBehalf = ({ onSelectLoadOption }) => {
  document.title = APP_TITLE + "-Top-up Other Account (Search Customer)";
  //Context
  const updateCustomer = useCustomerUpdate();
  const navigate = useNavigate();

  const [showConfirmationModal, setConfirmationModal] = useState(false);
  const [customerToTopup, setCustomerToTopup] = useState(null);

  //Events
  const handleCustomerFound = (customerFound) => {
    setCustomerToTopup(customerFound);
    setConfirmationModal(true);
  };

  const handleConfirmCustomer = () => {
    updateCustomer(customerToTopup);
    navigate("/topup-customer");
  };

  let confirmationModalContent = [];

  if (customerToTopup)
    confirmationModalContent.push(
      "Are you sure you want to top-up for <strong> " +
        customerToTopup.FirstName +
        " " +
        customerToTopup.LastName.substring(0, 1) +
        ". </strong>"
    );

  return (
    <div>
      <button
        className="flex font-bold text-sm"
        onClick={() => onSelectLoadOption(0)}
      >
        <img src={LeftArrow} width="20px" />
        <span>Back</span>
      </button>
      <TopupMethodHeader
        icon={Behalf}
        title="Top-up other account"
        description="Top-up for other eWallet account"
      />
      <CustomerFinder onCustomerFound={handleCustomerFound} />
      {showConfirmationModal && (
        <ConfirmationModal
          title="Confirm Top-up Recipient"
          content={confirmationModalContent}
          closeModal={setConfirmationModal}
          onConfirm={handleConfirmCustomer}
          callToActionText="Confirm"
        />
      )}
    </div>
  );
};

export default LoadOnBehalf;
