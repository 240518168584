import React, { useEffect, useState } from "react";
import { IoEllipsisHorizontalCircleSharp } from "react-icons/io5";
import {
  AiFillClockCircle,
  AiFillCheckCircle,
  AiFillMinusCircle,
  AiFillCloseCircle,
} from "react-icons/ai";
import StatisticItem from "./StatisticItem";
import "./StatisticBox.css";
import { GET_REQUEST } from "../../../api/APIManager";
import { URL_GET_TOPUP_STATISTICS } from "../../../api/Endpoints";
import TopupStatisticsDTO from "../../../classes/TopupStatisticsAgg/TopupStatisticsDTO";
import { useNavigate } from "react-router-dom";

const StatisticsBox = ({ dateRange, paymentCode }) => {
  let mounted = true;
  const [topupStatistics, setTopupStatistics] = useState(
    new TopupStatisticsDTO()
  );
  const [requestStatDTO, setrequestStatDTO] = useState({
    DateFrom: dateRange,
  });

  useEffect(() => {
    return () => {
      mounted = false;
    };
  }, []);

  // console.log(requestStatDTO);

  useEffect(() => {
    const _requestStatDTO = { DateFrom: dateRange };
    setrequestStatDTO(_requestStatDTO);
  }, [dateRange]);

  useEffect(async () => {
    const result = await GET_REQUEST(URL_GET_TOPUP_STATISTICS, requestStatDTO);
    // console.log(result);

    if (mounted) {
      if (result) {
        let _statistic = new TopupStatisticsDTO(0, "General Statistics");

        for (var index in result) {
          const currentPMethod = result[index];

          if (paymentCode === "0") {
            if (currentPMethod.details) {
              for (var index2 in currentPMethod.details) {
                const status = currentPMethod.details[index2];
                switch (status.status) {
                  case 0: {
                    _statistic.setPendingCount(status.transactionCount);
                    break;
                  }
                  case 1: {
                    _statistic.setDoneCount(status.transactionCount);
                    break;
                  }
                  case 2: {
                    _statistic.setProcessingCount(status.transactionCount);
                    break;
                  }
                  case 4: {
                    _statistic.setCancelledCount(status.transactionCount);
                    break;
                  }
                  case 5: {
                    _statistic.setOnHoldCount(status.transactionCount);
                    break;
                  }
                  default:
                }
              }
            }
          } else if (paymentCode === currentPMethod.paymentMethodCode) {
            _statistic = new TopupStatisticsDTO(
              currentPMethod.paymentMethodCode,
              currentPMethod.paymentMethodName
            );

            if (currentPMethod.details) {
              for (var index2 in currentPMethod.details) {
                const status = currentPMethod.details[index2];
                switch (status.status) {
                  case 0: {
                    _statistic.setPendingCount(status.transactionCount);
                    break;
                  }
                  case 1: {
                    _statistic.setDoneCount(status.transactionCount);
                    break;
                  }
                  case 2: {
                    _statistic.setProcessingCount(status.transactionCount);
                    break;
                  }
                  case 4: {
                    _statistic.setCancelledCount(status.transactionCount);
                    break;
                  }
                  case 5: {
                    _statistic.setOnHoldCount(status.transactionCount);
                    break;
                  }
                  default:
                }
              }
            }

            // setTopupStatistics(_statistic);
          }
        }
        setTopupStatistics(_statistic);
      }
    }
  }, [paymentCode, requestStatDTO]);

  // console.log(topupStatistics);

  let navigate = useNavigate();

  const handleOnClickDone =()=> {
    try {
      navigate("/BackOffice/Topup-Requests/" + dateRange + "/" + paymentCode + "/1");
    } catch (e) {
      console.log(e)
    }
  };

  const handleOnClickPending =()=> {
    try {
      navigate("/BackOffice/Topup-Requests/" + dateRange + "/" + paymentCode + "/0");
    } catch (e) {
      console.log(e)
    }
  };

  return (
    <div className="flex flex-col">
      <div className="border-b m-2">
        <StatisticItem
          icon={
            <IoEllipsisHorizontalCircleSharp
              size={"20"}
              style={{ marginRight: "5px", color: "#3B82F6" }}
            />
          }
          title="Processing"
          count={topupStatistics?.processingCount}
        />
      </div>

      <div id="" className="grid grid-cols-2"> {/*  statistic-grid */}
      <div className="cursor-pointer" onClick={handleOnClickPending}>
      <StatisticItem
          icon={
            <AiFillClockCircle
              size={"20"}
              style={{ margin: "5px", color: "#4B5563" }}
            />
          }
          title="Pending"
          count={topupStatistics?.pendingCount}
        />
      </div>
        <StatisticItem
          icon={
            <AiFillMinusCircle
              size={"20"}
              style={{ margin: "5px", color: "#F59E0B" }}
            />
          }
          title="On-hold"
          count={topupStatistics?.onHoldCount}
        />
        <div className="cursor-pointer" onClick={handleOnClickDone}>
          <StatisticItem
            icon={
              <AiFillCheckCircle
                size={"20"}
                style={{ margin: "5px", color: "#10B981" }}
              />
            }
            title="Done"
            count={topupStatistics?.doneCount}
          />
        </div>
        <StatisticItem
          icon={
            <AiFillCloseCircle
              size={"20"}
              style={{ margin: "5px", color: "#DC2626" }}
            />
          }
          title="Cancelled"
          count={topupStatistics?.cancelledCount}
        />
      </div>
    </div>
  );
};

export default StatisticsBox;
