import React from "react";

const PaymentMethodStatusIndicator = ({ status }) => {
  let statusText = "";

  switch (status) {
    case 0: {
      statusText = "Hidden";
      break;
    }

    case 1: {
      statusText = "Active";
      break;
    }

    case 2: {
      statusText = "Inactive";
      break;
    }
    default: {
      statusText = "Unknown";
      break;
    }
  }

  return <span>{statusText}</span>;
};

export default PaymentMethodStatusIndicator;
