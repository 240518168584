import React, { useState } from "react";
import ErrorRound from "../../assets/errorRound.svg";
import { useForm } from "react-hook-form";
import { GET_REQUEST } from "../../api/APIManager";
import { URL_GET_CUSTOMER_BY_MOBILE_NUMBER } from "../../api/Endpoints";

import ewalletUser from "../../classes/EWalletUser";
import { useNavigate } from "react-router-dom";
import { LOAD_OPTION_LOAD_ON_BEHALF } from "../../utils/Constants";

const CustomerFinder = ({ onCustomerFound }) => {
  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm();
  const [isLoading, setLoading] = useState(false);
  const [errorMessage, setErrorMessage] = useState(null);

  const findCustomerByMobileNumber = async (findCustomerDTO) => {
    try {
      setLoading(true);
      setErrorMessage(null);

      const customer = await GET_REQUEST(
        URL_GET_CUSTOMER_BY_MOBILE_NUMBER,
        findCustomerDTO
      );

      const _ewalletUser = new ewalletUser(
        customer.id,
        customer.firstname,
        customer.lastname,
        customer.mobileNo,
        LOAD_OPTION_LOAD_ON_BEHALF,
        customer.tags,
        customer.email
      );
      //updateCustomer(customer)
      onCustomerFound(_ewalletUser);
    } catch (error) {
      setErrorMessage(error);
    } finally {
      setLoading(false);
    }
  };

  let callToActionText = "Continue";

  if (isLoading) callToActionText = "Verifying Customer...";

  return (
    <div>
      <form onSubmit={handleSubmit(findCustomerByMobileNumber)}>
        <div>
          <label className="font-bold">Mobile Number</label>
          <input
            {...register("mobileNo", { required: true })}
            disabled={isLoading}
            type="text"
            className="input-text w-full"
            placeholder="09XXXXXXXXX"
          />
        </div>
        {errors.mobileNo && errors.mobileNo.type === "required" && (
          <div className="text-error text-xs font-bold flex mt-2">
            <img src={ErrorRound} />
            <span className="ml-2">Mobile Number is required</span>
          </div>
        )}

        {errorMessage && (
          <div className="bg-red-100 p-3 rounded-md border border-solid border-red-300 text-center mt-3">
            {errorMessage}
          </div>
        )}

        <div className="text-right mt-5">
          <input
            type="submit"
            disabled={isLoading}
            value={callToActionText}
            className="btn btn-primary cursor-pointer"
          />
        </div>
      </form>
    </div>
  );
};

export default CustomerFinder;
