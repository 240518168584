import React, { useRef, useEffect, useState } from "react";
import NewWindow from "react-new-window";
import { GET_REQUEST, POST_REQUEST } from "../../../api/APIManager";
import { URL_GET_TOPUP_REQUESTS_SUMMARY_REPORT_BY_DATEFROM_DATETO } from "../../../api/Endpoints";
import { useReactToPrint } from "react-to-print";
import {
  formatDateTimeCommon,
  formatDate_journal,
  formatNumber,
  formatShortDate,
} from "../../../utils/Common";
import "./PrintTopupRequest.css";

const PrintTopupSummary = ({ showPrintReport, startDate, endDate }) => {
  const [topupRequests, setTopupRequests] = useState(null);
  const [loadingMessage, setLoadingMessage] = useState(null);
  let total = 0;
  let totalTransCount = 0;

  const componentRef = useRef();
  const handlePrint = useReactToPrint({
    content: () => componentRef.current,
    documentTitle:'TopupSummary_Date_' + formatShortDate(new Date()),
  });

  useEffect(() => {
    getTopupRequests();
  }, []);

  const getTopupRequests = async () => {
    try {
      setLoadingMessage("Generating Report... Please Wait");
      let payload = {
        dateFrom: formatDate_journal(startDate),
        dateTo: formatDate_journal(endDate),
      };
      const _requestsDateFromDateTo = await GET_REQUEST(
        URL_GET_TOPUP_REQUESTS_SUMMARY_REPORT_BY_DATEFROM_DATETO,
        payload
      );
      setTopupRequests(_requestsDateFromDateTo);
      setLoadingMessage(null);
      handlePrint();
      showPrintReport(false);
    } catch (e) {
      console.log(e);
    } finally {
    }
  };

  return (
    <NewWindow onUnload={() => showPrintReport(false)} center="parent">
      <button
        className="font-cold border rounded-md px-4 py-2"
        onClick={handlePrint}
      >
        Print Report
      </button>
      <div ref={componentRef} className="m-5 p-2">
        <table
          id="tblPrintTopupRequests"
          className="w-full rounded-md overflow-hidden border-collapse"
        >
          <thead>
            <tr>
              <th colSpan={8}>
                <h1 className="font-bold text-lg">Ewallet Topup System</h1>
                <h2 className="text-sm">Top-up Summary Report</h2>
                <h2 className="text-sm">
                   {formatShortDate(startDate)} - {formatShortDate(endDate)}
                </h2>
              </th>
            </tr>
            <tr className="border">
              <th className="text-left">Date</th>
              <th className="text-left">Transaction Count</th>
              <th className="text-right">Total Amount</th>
            </tr>
          </thead>
          <tbody>
            {topupRequests?.map((topup) => {
              total = total + topup.totalAmount;
              totalTransCount = totalTransCount + topup.transactionCount;
              return (
                <tr key={topup.date}>
                  <td>{formatDate_journal(topup.date)}</td>
                  <td>{topup.transactionCount}</td>
                  <td className="text-right">
                    {formatNumber(topup.totalAmount)}
                  </td>
                </tr>
              );
            })}
          </tbody>
          <tfoot>
            <tr>
              {/* <td className="pl-3" colSpan={8}>
                Date/Time Printed: {formatDateTimeCommon(new Date())}
              </td> */}
            </tr>
          </tfoot>
        </table>
        <div className="p-3 flex justify-between">
          <div className="rtp-amount">
            Total Transaction Count: {totalTransCount}{" "}
          </div>
          <div className="rtp-amount ml-2">
            Total Amount: ₱{formatNumber(total)}
          </div>
        </div>

        <div className="px-3 flex justify-between">
        <div className=""> Date/Time Printed: {formatDateTimeCommon(new Date())}</div>
        </div>

        <div className="text-center mt-5">*** Nothing Follows ***</div>
        {loadingMessage && <div>{loadingMessage}</div>}
      </div>
    </NewWindow>
  );
};

export default PrintTopupSummary;
