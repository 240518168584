import React from "react";

import ChevronRight from "../../assets/chevronRight.svg";

const TopupMethodHeader = ({ icon, title, description }) => {
  return (
    <div className={`flex rounded-xl py-2 items-center my-1`}>
      <div className="bg-primary rounded-full p-3">
        <img src={icon} width="15px" height="15px" />
      </div>
      <div className="ml-2 grow ">
        <p className="font-black" style={{ fontSize: "14px" }}>
          {title}
        </p>
        <p className="text-sm" style={{ fontSize: "12px" }}>
          {description}
        </p>
      </div>
    </div>
  );
};

export default TopupMethodHeader;
